import _ from 'lodash/fp'
import React from 'react'
import { ContainerData } from '../types'
import { getContainerRequiredMark, isContType40REEFERCONTAINER } from '../utils/container'
import { isGenericFocus, onChangeGenericData } from '../utils/data'
import { getContainersAsSelectValueList } from '../utils/masterData'
import Card from './Card'
import CardHeader from './CardHeader'
import AsyncSelect from './DataInput/AsyncSelect'
import Boolean from './DataInput/Boolean'
import Number from './DataInput/Number'
import Text from './DataInput/Text'
import Wrapper from './DataInput/Wrapper'
import HoverRemoveIcon from './HoverRemoveIcon'
import ScrollableList from './ScrollableList'

type ItemProps = {
  data: ContainerData,
  onChangeData: (data: ContainerData) => void
  focus?: keyof ContainerData,
  onFocusField?: (field: keyof ContainerData) => void
  onRemove?: () => void
}

const Item: React.FunctionComponent<ItemProps> = ({ data, focus, onChangeData, onFocusField, onRemove }) => {
  const onChange = onChangeGenericData(data, onChangeData)

  const isFocused = isGenericFocus(focus)

  return (
    <Card className="tw-group">
      <CardHeader>
        {data.cont_id || 'Sin número'}
        {_.isFunction(onRemove) && <HoverRemoveIcon onClick={onRemove} content="Eliminar contenedor" />}
      </CardHeader>
      <Wrapper isFocused={isFocused('cont_id')}>
        <Text
          label={`Contenedor ${getContainerRequiredMark('cont_id')}`}
          value={data.cont_id}
          onChange={onChange('cont_id')}
          onFocusInput={() => onFocusField?.('cont_id')}
        />
      </Wrapper>
      <Wrapper isFocused={isFocused('cont_type')}>
        <AsyncSelect
          label={`Tipo ${getContainerRequiredMark('cont_type')}`}
          value={data.cont_type}
          onChange={onChange('cont_type')}
          onFocusInput={() => onFocusField?.('cont_type')}
          onQueryOptions={getContainersAsSelectValueList}
        />
      </Wrapper>
      {isContType40REEFERCONTAINER(data.cont_type) &&
        <Wrapper isFocused={isFocused('non_operational')}>
          <Boolean
            label={`No Conectado ${getContainerRequiredMark('non_operational')}`}
            value={data.non_operational}
            onChange={onChange('non_operational')}
            onFocusInput={() => onFocusField?.('non_operational')}
          />
        </Wrapper>
      }
      <Wrapper isFocused={isFocused('seal_no')}>
        <Text
          label={`Precinto ${getContainerRequiredMark('seal_no')}`}
          value={data.seal_no}
          onChange={onChange('seal_no')}
          onFocusInput={() => onFocusField?.('seal_no')}
        />
      </Wrapper>
      <Wrapper isFocused={isFocused('packages')}>
        <Number
          label={`Bultos ${getContainerRequiredMark('packages')}`}
          value={data.packages}
          isInteger
          onChange={onChange('packages')}
          onFocusInput={() => onFocusField?.('packages')}
        />
      </Wrapper>
      <Wrapper isFocused={isFocused('weight')}>
        <Number
          label={`Kilos ${getContainerRequiredMark('weight')}`}
          value={data.weight}
          onChange={onChange('weight')}
          onFocusInput={() => onFocusField?.('weight')}
        />
      </Wrapper>
      <Wrapper isFocused={isFocused('volume')}>
        <Number
          label={`Volumen ${getContainerRequiredMark('volume')}`}
          value={data.volume}
          onChange={onChange('volume')}
          onFocusInput={() => onFocusField?.('volume')}
        />
      </Wrapper>
    </Card>
  )
}

export type Props = {
  data: ContainerData[],
  onChangeDataAt: (index: number, data: ContainerData) => void
  onRemoveDataAt: (index: number) => void
  focus?: [number, keyof ContainerData],
  onFocusField?: (focus: [number, keyof ContainerData]) => void
}


const ExpedienteContainerData: React.FunctionComponent<Props> = ({ data, onChangeDataAt, onRemoveDataAt, onFocusField, focus }) => {
  const isItemFocused = (index: number) => _.get(0, focus) === index

  return (
    <ScrollableList className="tw-p-2">
      {data.map((item, index) => (
        <Item
          key={index}
          data={item}
          onChangeData={(data) => onChangeDataAt(index, data)}
          focus={isItemFocused(index) ? _.get(1, focus) : undefined}
          onRemove={() => onRemoveDataAt(index)}
          onFocusField={(field) => onFocusField?.([index, field])}
        />
      ))}
    </ScrollableList>
  )
}

export default ExpedienteContainerData
