import _ from "lodash/fp";
import { getTenants } from "../services/tenant.service";
import store from "../store";
import { SelectValue } from "../types";

const handleError =
  (callback: () => Promise<SelectValue[]>) =>
  async (): Promise<SelectValue[]> => {
    try {
      return await callback();
    } catch (err) {
      return Promise.resolve([] as SelectValue[]);
    }
  };

const toSelectValue = (data: string): SelectValue => ({
  id: data,
  label: data,
});

const toSelectValueList = (data: string[]) => _.map(toSelectValue, data);

export const getTenantsAsSelectValueList = handleError(async () => {
  const data = await getTenants();
  return toSelectValueList(data);
});

export const getTenant = () => _.toString(store.getState().session.tenant?.id);
