import { Spinner, SpinnerSize } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import _ from 'lodash/fp';
import React, { useCallback, useState } from 'react';
import { useRedirect } from '../hooks/redirect';
import { useSessionActions } from '../hooks/session';
import { showInfoAlert } from '../utils/alert';
import routes from '../utils/constants/routes';
import BlueButton from './BlueButton';
import NavigationPanel from './NavigationPanel';

type Props = {
  onClickNext?: () => Promise<void> | void;
  isNextDisabled?: boolean;
};

const Header: React.FunctionComponent<Props> = ({
  onClickNext,
  isNextDisabled,
}) => {
  const logout = useSessionActions(actions => actions.logout)
  const [loading, setLoading] = useState(false)
  const redirect = useRedirect()

  const clickNext = useCallback(async () => {
    setLoading(true)
    await onClickNext?.()
    setLoading(false)
  }, [onClickNext])


  const clickLogout = () => {
    logout()
    redirect(routes.login);
    showInfoAlert('Se cerró la sessión correctamente');
  }

  return (
    <header className="tw-p-4 tw-flex tw-flex-row tw-justify-between tw-shadow-md tw-w-full">
      <BlueButton
        onClick={clickLogout}
        icon={IconNames.LOG_OUT}
      >
        Logout
      </BlueButton>
      <div className='tw-flex tw-flex-row tw-space-x-4 tw-items-center tw-justify-center'>
        <NavigationPanel/>
        {_.isFunction(onClickNext) && (
          <BlueButton
            onClick={clickNext}
            disabled={isNextDisabled || loading}
            rightIcon={IconNames.ARROW_RIGHT}
          >
            <div className="tw-flex tw-flex-row tw-items-center tw-space-x-2">
              {loading && <Spinner size={SpinnerSize.SMALL} />}
              <span>Siguiente</span>
            </div>
          </BlueButton>
        )}
      </div>
    </header>
  );
};

export default Header;
