import React from 'react';

type Props = {
  label: string,
  value: string
}

const SummaryField: React.FunctionComponent<Props> = ({label, value}) => {
  return (
    <div className="tw-flex tw-justify-between tw-break-words">
      <div className="tw-font-medium tw-whitespace-nowrap">{label}:</div>
      <div className="tw-ml-2">{value}</div>
    </div>
  )
}


export default SummaryField
