import { Button } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import React from 'react'

type Props = {
  onClick: () => void,
}

const ResetButton: React.FunctionComponent<Props> = ({onClick}) => {
  return (
    <Button
      tabIndex={-1}
      minimal
      icon={IconNames.CROSS}
      onClick={onClick}
    />
  )
}

export default ResetButton
