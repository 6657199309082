import _ from "lodash/fp";
import { Document, SelectValue } from "../types";
import { isHBI, isHBL, isMBI, isMBL, isSHB, isSMB } from "./docTypes";
import { findByLabel } from "./select";

export const fileToDocument = (documents: SelectValue[]) => {
  const filterFn = findByLabel(documents)
  return (file: File): Document => ({
    file,
    type: filterFn(file.name)
  })
}

export const isMBIDocument = (document: Document) => isMBI(document.type)
export const isSMBDocument = (document: Document) => isSMB(document.type)
export const isMBLDocument = (document: Document) => isMBL(document.type)

export const isHBIDocument = (document: Document) => isHBI(document.type)
export const isSHBDocument = (document: Document) => isSHB(document.type)
export const isHBLDocument = (document: Document) => isHBL(document.type)

export const getMBLDocument = (documents: Document[]) => _.find(isSMBDocument, documents) || _.find(isMBIDocument, documents) 
export const getHBLDocument = (documents: Document[]) => _.find(isSHBDocument, documents) || _.find(isHBIDocument, documents)

export const sortDocumentsByMBL = (documents: Document[]) => {
  const mbl = getMBLDocument(documents)
  const rest = _.reject({type: mbl?.type}, documents)
  return _.compact(_.concat(mbl, rest))
}

export const sortDocumentsByHBL = (documents: Document[]) => {
  const hbl = getHBLDocument(documents)
  const rest = _.reject({type: hbl?.type}, documents)
  return _.compact(_.concat(hbl, rest))
}
