import { Position, Tree, TreeNodeInfo } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { Classes as Popover2Classes, ContextMenu2, Tooltip2 } from '@blueprintjs/popover2'
import _ from 'lodash/fp'
import React from 'react'
import { useClassifyStatus } from '../hooks/classify'
import { getDocDataType } from '../utils/docTypes'
import Card from './Card'
import ScrollableList from './ScrollableList'

type Expediente = ReturnType<typeof useClassifyStatus>['expediente']
type Bookings = ReturnType<typeof useClassifyStatus>['bookings']
type Document = Expediente[0]

const dataTypes = getDocDataType()

const formatNumPages = (n: number) => `${n} ${n === 1 ? 'página' : 'páginas'}`

const formatPagesList = (pages: number[]) => {
  const incPages = _.map((page) => page + 1, pages)

  if (pages.length < 2) return `Página ${_.first(incPages)}`

  const firstN = _.join(', ', _.initial(incPages))
  return `Páginas ${firstN + ' y ' + _.last(incPages)}`
}

const formatLabel = (doc: Document) => (
  <div className="tw-flex tw-flex-row tw-space-x-2 tw-justify-between tw-w-full">
    <span>{doc.label}</span>
    <span>{`(${formatNumPages(doc.pages.length)})`}</span>
  </div>
)

const contentSizing = { popoverProps: { popoverClassName: Popover2Classes.POPOVER2_CONTENT_SIZING } };

const generateDocNode = (doc: Document): TreeNodeInfo => ({
  id: _.toString(doc.id),
  icon: IconNames.DOCUMENT,
  label: (
    <ContextMenu2 {...contentSizing} content={<div>Hello there!</div>}>
      <Tooltip2 content={formatPagesList(doc.pages)} placement={Position.TOP} className="tw-w-full">
        {formatLabel(doc)}
      </Tooltip2>
    </ContextMenu2>
  ),
})

const generateExpedienteNodes = (expediente: Expediente): TreeNodeInfo[] => _.map(generateDocNode, expediente)

const generateBookingNodes = (bookings: Bookings): TreeNodeInfo[] => {
  return bookings.map((booking, index) => ({
    id: index,
    label: `Booking ${index + 1}`,
    isExpanded: true,
    icon: IconNames.FOLDER_CLOSE,
    hasCaret: false,
    childNodes: _.map(generateDocNode, booking)
  }))
}

const generateNodes = (expediente: Expediente, bookings: Bookings): TreeNodeInfo[] => {
  const expNodes = generateExpedienteNodes(expediente)
  const bknNodes = generateBookingNodes(bookings)

  const defaultProps: Partial<TreeNodeInfo> = {
    isExpanded: true,
    icon: IconNames.FOLDER_NEW,
    hasCaret: false,
  }

  return [
    {
      id: _.toString(dataTypes.EXP?.id),
      label: <strong>{_.toString(dataTypes.EXP?.label)}</strong>,
      childNodes: expNodes,
      ...defaultProps
    },
    {
      id: _.toString(dataTypes.BKN?.id),
      label: <strong>{_.toString(dataTypes.BKN?.label)}</strong>,
      childNodes: bknNodes,
      ...defaultProps
    }
  ]
}

const ClassifyStatusTree: React.FunctionComponent = () => {
  const { expediente, bookings } = useClassifyStatus()

  return (
    <Card className=' tw-min-h-[16rem]'>
      <ScrollableList>
        <Tree
          className=" tw--ml-5"
          contents={generateNodes(expediente, bookings)}
        />
      </ScrollableList>
    </Card>
  )
}

export default ClassifyStatusTree
