import { action, thunk } from 'easy-peasy';
import _ from 'lodash/fp';
import { createBillOfLadingDocument, createBillOfLadingHBL, createBillOfLadingMBL } from '../../utils/bill_of_lading';
import { Actions, Model, State } from '../types';
import bookingModel from './booking';
import expedienteModel from './expediente';
import pageModel from './page';
import sessionModel from './session';
import documentsModel from './documentTypes';


const state: State = {
  isSaved: false,
  session: sessionModel,
  page: pageModel,
  expediente: expedienteModel,
  bookings: bookingModel,
  documentType: documentsModel
};

const actions: Actions = {
  clear: thunk((actions) => {
    actions.setIsSaved(false)
    actions.page.clear()
    actions.expediente.clear()
    actions.bookings.clear()
    actions.documentType.clear()
  }),
  setIsSaved: action((state, payload) => {
    state.isSaved = payload
  }),
  sendTheBox: thunk(async (actions) => {
    await actions.expediente.sendTheBox()
    await actions.bookings.sendTheBox()
  }),
  sendBilloflading: thunk(async (actions, p, { injections, getState }) => {
    const state = getState()
    const { billofladingService } = injections
    
    const expediente = state.expediente
    const bookings = state.bookings.list
    const session = state.session

    const bl = {
      user: {
        user: session.grmUser,
        tenant_name: session.tenant?.label
      },
      MBL: {
        data: createBillOfLadingMBL({
          mbl: expediente.state.mbl.data, 
          containers: _.map('data', expediente.state.containers.list)
        }),
        documents: await Promise.all(_.map(createBillOfLadingDocument, expediente.docs))
      },
      HBL: await Promise.all(_.map(async (booking) => ({
        data: createBillOfLadingHBL({
          hbl: booking.state.hbl.data, 
          packages: _.map('data', booking.state.packages)
        }),
        documents: await Promise.all(_.map(createBillOfLadingDocument, booking.docs))
      }), bookings))
    }
    
    await billofladingService.saveBillOfLading(bl)
  })
};

const model: Model = {
  ...state,
  ...actions,
};

export default model;
