import axios, {
  AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse,
} from 'axios';
import _ from 'lodash/fp';

const theBoxURL = "https://thebox.solverml.com";
const theBoxUserName = "ClopyBL";
const theBoxKey = "456eecde26eeefc6a1b3f04a43355705";

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  if (_.isNil(theBoxUserName)) {
    return config;
  }
  config.params = _.set(theBoxUserName, theBoxKey, config.params ?? {});
  return config;
};
const onRequestError = (error: AxiosError): Promise<AxiosError> => Promise.reject(error);
const onResponse = (response: AxiosResponse): AxiosResponse => response;
const onResponseError = async (error: AxiosError): Promise<AxiosError> => {
  const errorResp = error.response;

  if (!_.isNil(errorResp)) {
    return Promise.reject(new Error(errorResp.data as string));
  }
  // NO response so no internet connection or server not reachable
  return Promise.reject(new Error('CONNECTION_REFUSED'));
};
export const setupInterceptorsTo = (axiosInstance: AxiosInstance): AxiosInstance => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
};

const client = setupInterceptorsTo(axios.create({
  baseURL: theBoxURL,
}));

export default client;
