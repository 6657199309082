import _ from 'lodash/fp';
import React from 'react';
import Select from 'react-select';
import { SelectValue } from '../../types';
import { customStyles, DEFAULT_MIN_CHARS, DEFAULT_PROPS } from './SelectInput';

export type Props = {
  value: SelectValue[];
  options: SelectValue[];
  onChange: (value: SelectValue[]) => void;
  onFocusInput?: () => void;
  minChars?: number;
};

const customStylesWithColor = {
  ...customStyles,
  multiValue: (base: any) => ({
    ...base,
    backgroundColor: "#137cbd",
    borderRadius: "3px"
  }),
  multiValueLabel: (base: any) => ({
    ...base,
    color: "white",
    marginLeft: "2px"
  }),
  multiValueRemove: (base: any) => ({
    ...base,
    color: "white",
    ':hover': {
      backgroundColor: "#137cbd"
    }
  })
}

const MultiSelectInput: React.FunctionComponent<Props> = ({
  options,
  value,
  onChange,
  onFocusInput = _.noop,
  minChars = DEFAULT_MIN_CHARS,
}: Props) => {
  const [showList, setShowList] = React.useState(minChars === 0);

  const onInputChange = (query: string) => {
    setShowList(_.trim(query).length >= minChars);
  };

  return (
    <Select
      {...DEFAULT_PROPS}
      styles={customStylesWithColor}
      onFocus={onFocusInput}
      value={value}
      isMulti
      onChange={(values) => onChange(values as SelectValue[])}
      getOptionValue={(option) => _.toString(option?.id)}
      getOptionLabel={(option) => _.toString(option?.label)}
      options={showList ? (options as []) : []}
      onInputChange={onInputChange}
      placeholder={minChars > 0 ? `Buscar (min ${minChars} caracteres)`: 'Selecciona...'}
    />
  );
};

export default MultiSelectInput;
