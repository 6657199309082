import _ from "lodash/fp";
import { SelectValue } from "../types";
import { MasterData } from "../types/api";
import { docDataType, docDataTypesList } from "./constants/docTypes";

export const getDocDataType = () => docDataType
export const getDocDataTypeList = () => docDataTypesList


export const isExpediente = (option: SelectValue) => docDataType.EXP?.id === option?.id
export const isBooking = (option: SelectValue) => docDataType.BKN?.id === option?.id

export const isMBI = (option: SelectValue) => "MBI" === option?.id
export const isSMB = (option: SelectValue) => "SMB" === option?.id
export const isHBI = (option: SelectValue) => "HBI" === option?.id
export const isSHB = (option: SelectValue) => "SHB" === option?.id


export const isMBL = (option: SelectValue) => isSMB(option) || isMBI(option)
export const isHBL = (option: SelectValue) => isSHB(option) || isHBI(option)

export const toSelectValue = (option: MasterData) => ({
  id: _.toString(option.code),
  label: option.description
})

export const toSelectValueList = (options: MasterData[]) => _.map(toSelectValue, options)

export const filterMainDocuments = (options: MasterData[]) => _.pipe(_.filter({category: 'Principal'}), toSelectValueList)(options)
export const filterOtherDocuments = (options: MasterData[]) => _.pipe(_.filter({category: 'Other'}), toSelectValueList)(options)
