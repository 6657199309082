import React from 'react';

type Props = React.PropsWithChildren<{}>

const ScrollWrapper: React.FunctionComponent<Props> = ({ children }) => {
  return (
    <div className="tw-relative tw-h-full tw-w-full">
      <div className="tw-absolute tw-h-full tw-w-full">
        {children}
      </div>
    </div>
  );
};

export default ScrollWrapper;
