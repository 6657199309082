import { useStoreRehydrated } from 'easy-peasy';
import React from 'react';

type Props = React.PropsWithChildren<{
  loading?: React.ReactNode
}>

const WaitForStateRehydration: React.FunctionComponent<Props> = ({ children, loading }) => {
  const isRehydrated = useStoreRehydrated();
  if (isRehydrated) {
    return <>{children}</>;
  }
  return <>{loading}</>;
};

export default WaitForStateRehydration;
