import { Label } from '@blueprintjs/core';
import _ from 'lodash/fp';
import React from 'react'
import { SelectValue } from '../../types';
import ButtonInput from './ButtonInput';
import ResetButton from './ResetButton';

type Props = {
  label: React.ReactNode;
  value: SelectValue;
  options: SelectValue[];
  onChange: (value: SelectValue) => void;
  onFocusInput?: () => void;
};

const Button: React.FunctionComponent<Props> = ({
  label,
  value,
  onChange,
  options,
  onFocusInput = _.noop,
}: Props) => {
  return (
    <Label className="tw-font-medium" style={{ marginBottom: 0 }}>
      {label}
      <input className="tw-opacity-0 tw-h-0 tw-w-0" onFocus={onFocusInput} />
      <div style={{ marginTop: "5px" }} className="tw-flex tw-flex-row">
        <ButtonInput options={options} value={value} onChange={onChange} onFocus={onFocusInput}/>
        {!_.isEmpty(value) && (
          <div className="tw-ml-2">
            <ResetButton onClick={() => onChange(null)} />
          </div>
        )}
      </div>
    </Label>
  );
};

export default Button
