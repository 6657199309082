import classNames from 'classnames';
import _ from 'lodash/fp';
import React from 'react';
import BookingHBLDataFiller from '../components/BookingHBLDataFiller';
import Card from '../components/Card';
import FileList from '../components/FileList';
import Layout from '../components/Layout';
import { useBookingState } from '../hooks/booking';
import { useSelect } from '../hooks/list';
import { useRedirect } from '../hooks/redirect';
import routes from '../utils/constants/routes';
import { handleError } from '../utils/errors';


const Booking: React.FunctionComponent = () => {
  const files = useBookingState(state => state.files)
  const { current, index, changeIndex } = useSelect(files)
  const isComplete = useBookingState(state => state.isCurrentHBLComplete)
  const redirect = useRedirect()

  const hasFiles = !_.isEmpty(files)
  const layoutClass = classNames(
    "tw-w-full tw-gap-2 tw-grid tw-p-2",
    {
      "tw-grid-cols-[1fr,4fr,2fr]": hasFiles,
      "tw-grid-cols-[4fr,2fr]": !hasFiles
    }
  )

  const handleNext = () => {
    redirect(routes.booking_packages)
  }

  return (
    <Layout onClickNext={handleError(handleNext)} isNextDisabled={!isComplete}>
      <div className={layoutClass}>
        {hasFiles && (
          <Card>
            <FileList files={files} onClick={changeIndex} selected={index} />
          </Card>
        )}
        <BookingHBLDataFiller
          file={current}
          index={index}
        />
      </div>
    </Layout >
  );
};

export default Booking;
