import classNames from 'classnames';
import React from 'react';

type Props = React.PropsWithChildren<{
  isFocused?: boolean;
  className?: string;
}>;

const FocusContainer: React.FunctionComponent<Props> = ({
  isFocused,
  className = '',
  children,
}: Props) => {
  const focusClass = classNames(
    {
      "tw-bg-gray-200": isFocused
    }
  )

  return (
    <div className={`${className} ${focusClass}`}>
      {children}
    </div>
  )
};

export default FocusContainer;
