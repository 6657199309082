import { Button, Intent } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import _ from 'lodash/fp'
import React from 'react'

type Props = {
  next?: () => void,
  prev?: () => void,
  total: number,
  current: number,
  disableAfter?: number
  goto?: (index: number) => void,
}

const CELL_COUNT = 7
const CELL_MID_LEN = _.floor(CELL_COUNT / 2)

const generateLayout = (current: number, total: number) => {
  let pages: { nr: number, ellipsis?: boolean }[] = []

  if (total > CELL_COUNT) {
    pages[0] = { nr: 1 }
    pages[1] = { nr: 2 }
    pages[CELL_COUNT - 2] = { nr: total - 1 }
    pages[CELL_COUNT - 1] = { nr: total }

    if (current <= CELL_MID_LEN) {
      pages[CELL_COUNT - 2].ellipsis = true
      for (let i = 2; i < CELL_COUNT - 2; i++) {
        pages[i] = { nr: i + 1 }
      }
    } else if ((total - current) < CELL_MID_LEN) {
      pages[1].ellipsis = true
      for (let i = 2; i < CELL_COUNT - 2; i++) {
        pages[i] = { nr: total - CELL_COUNT + i + 1 }
      }
    } else {
      pages[1].ellipsis = true
      pages[CELL_COUNT - 2].ellipsis = true

      pages[CELL_MID_LEN] = { nr: current }
      for (let i = 1; i < CELL_COUNT - 5; i++) {
        pages[CELL_MID_LEN + i] = { nr: current + i }
        pages[CELL_MID_LEN - i] = { nr: current - i }
      }
    }
  }
  else {
    for (let i = 0; i < total; i++) {
      pages[i] = { nr: i + 1, ellipsis: false }
    }
  }

  return pages
}

const Pagination: React.FunctionComponent<Props> = ({ next, prev, total, current, disableAfter, goto }) => {

  const isNumberDisabled = (n: number) => (!_.isNil(disableAfter) && n > disableAfter)

  return (
    <div className='tw-flex tw-flex-row tw-space-x-1'>
      <Button
        icon={IconNames.CHEVRON_LEFT}
        disabled={_.isNil(prev)}
        onClick={prev}
      />
      {generateLayout(current, total).map(p => (
        <Button
          key={p.nr}
          disabled={p.ellipsis || isNumberDisabled(p.nr) || _.isNil(goto)}
          intent={current === p.nr ? Intent.PRIMARY : Intent.NONE}
          onClick={() => goto?.(p.nr)} >
          {p.ellipsis ? '...' : p.nr}
        </Button>
      ))}
      <Button
        icon={IconNames.CHEVRON_RIGHT}
        disabled={_.isNil(next)}
        onClick={next}
      />
    </div>
  )
}

export default Pagination
