import _ from "lodash/fp";
import {
  getContainers,
  getDocumentTypes,
  getLocodes,
  getPackages,
} from "../services/masterData.service";
import { SelectValue } from "../types";
import { MasterData } from "../types/api";
import { getTenant } from "./tenant";

const handleError =
  (callback: (query: string) => Promise<SelectValue[]>) =>
  async (query: string): Promise<SelectValue[]> => {
    try {
      return await callback(query);
    } catch (err) {
      return Promise.resolve([] as SelectValue[]);
    }
  };

const toSelectValue = (data: MasterData): SelectValue => ({
  id: _.toString(data.code),
  label: data.description,
});

const toSelectValueList = (data: MasterData[]) => _.map(toSelectValue, data);

export const getContainersAsSelectValueList = handleError(
  async (query: string) => {
    const data = await getContainers(query);
    return toSelectValueList(data);
  }
);

export const getLocodesAsSelectValueList = handleError(
  async (query: string) => {
    const data = await getLocodes(query, getTenant());
    return toSelectValueList(data);
  }
);

export const getPackagesAsSelectValueList = handleError(
  async (query: string) => {
    const data = await getPackages(query);
    return toSelectValueList(data);
  }
);

export const getLocodeAsSelectValue = async (query: string) => {
  const options = await getLocodesAsSelectValueList(query);
  return _.first(options);
};

export const getContainerAsSelectValue = async (query: string) => {
  const options = await getContainersAsSelectValueList(query);
  return _.first(options);
};

export const getPackageAsSelectValue = async (query: string) => {
  const options = await getPackagesAsSelectValueList(query);
  return _.first(options);
};

export const getDocumentTypesList = async () => {
  const data = await getDocumentTypes();

  return _.sortBy("order", data);
};
