import Images from '../../images'

export const types = {
  PDF: 'application/pdf',
} as const

export const extensions = {
  PDF: '.pdf',
} as const

export const icons = {
  PDF: Images.PDF,
} as const
