import _ from 'lodash/fp';
import { useCallback, useMemo, useState } from 'react';

const PAGE_CHANGE = 1;
const FIRST_PAGE = 1;

const calculateStart = (page: number, perPage: number): number => (page - FIRST_PAGE) * perPage;

const calculateEnd = (start: number, carsLength: number, perPage: number): number => (
  _.min([start + perPage, carsLength]) as number
);

const getPage = <T>(list: T[], page: number, perPage: number): T[] => {
  if (_.isEqual(page, FIRST_PAGE)) {
    return _.take(perPage)(list);
  }
  return _.slice(
    calculateStart(page, perPage),
    calculateEnd(calculateStart(page, perPage), list.length, perPage),
  )(list);
}
const maxPages = <T>(list: T[], perPage: number): number => _.ceil(_.size(list) / perPage)


export const usePagination = <T>(list: T[], perPage: number) => {
  const [page, setPage] = useState(FIRST_PAGE);

  const total = useMemo(() => maxPages(list, perPage), [list, perPage])
  const firstPage = page > FIRST_PAGE;
  const lastPage = total <= page;

  const next = useCallback(() => {
    setPage(p => p + PAGE_CHANGE)
  }, [])

  const prev = useCallback(() => {
    setPage(p => p - PAGE_CHANGE)
  }, [])

  const pageList = useMemo(() => (
    _.lt(list.length, perPage) ? list : getPage(list, page, perPage)
  ), [list, perPage, page])

  const actions = {
    next: lastPage ? undefined : next,
    prev: !firstPage ? undefined : prev,
    goto: setPage
  }

  const data = {
    page,
    total
  }

  return [
    pageList,
    data,
    actions
  ] as [typeof pageList, typeof data, typeof actions]
}
