import classNames from 'classnames';
import _ from 'lodash/fp';
import React from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { showWarningAlert } from '../utils/alert';
import { getAllAcceptedMap } from '../utils/file';
import Card from './Card';

type Props = React.PropsWithChildren<{
  onDrop?: (files: File[]) => void;
  acceptedExtensions: string[],
  multiple?: boolean
}>

const Dropzone: React.FunctionComponent<Props> = ({ onDrop, acceptedExtensions, multiple = true, children }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: getAllAcceptedMap(acceptedExtensions),
    multiple: multiple,
    onDropAccepted: onDrop,
    onDropRejected: (rejections: FileRejection[]) => {
      _.forEach((rejection: FileRejection) => {
        showWarningAlert(`El fichero ${rejection.file.name} no es aceptado.`)
      }, rejections)
      showWarningAlert(`Extensiones permitidas: ${_.join(', ', acceptedExtensions)}`);
    },
  });

  const activeClass = classNames(
    {
      "tw-border-blue-400 tw-text-blue-400": isDragActive
    }
  )

  return (
    <Card className="tw-cursor-pointer tw-w-full tw-h-full">
      <div
        {...getRootProps({
          className: `tw-h-full tw-w-full tw-transition-colors tw-duration-300 tw-border-2 tw-border-transparent ${activeClass}`,
        })}
      >
        <input {...getInputProps()} />
        {children}
      </div>
    </Card>
  );
};

export default Dropzone;
