import _ from "lodash/fp"
import { useCallback, useMemo, useState } from "react"
import { BoxValue, StringValue } from "../types"
import { onChangeGenericData } from "../utils/data"
import { getHBLMappings } from "../utils/hbl"
import { useBookingActions, useBookingState } from "./booking"

export const useHBLController = (idx: number) => {
  const data = useBookingState(state => state.current.state.hbl.data)
  const boxes = useBookingState(state => state.current.state.hbl.boxes)
  const setData = useBookingActions(actions => actions.setCurrentHBLData)
  const setBoxes = useBookingActions(actions => actions.setCurrentHBLBoxes)
  const [loading, setLoading] = useState(false)
  const [focus, setFocus] = useState<keyof typeof data>('bl_num')

  const mapFocusData = useCallback(async (value: StringValue) => {
    const text = _.trim(_.toString(value))
    if (_.isEmpty(text)) return

    const updateFn = getHBLMappings(focus)
    const newValue = await updateFn(value)
    if (_.isNil(newValue)) return
    
    onChangeGenericData(data, setData)(focus)(newValue)
  }, [focus, data, setData])

  const mapFocusBoxes = useCallback((value: BoxValue) => {
    if(_.isNil(value)) return

    setBoxes(_.set(focus, _.set('fileIndex', idx, value), boxes))
  }, [focus, boxes, setBoxes, idx])
  
  const updateFocusState = useCallback(async (text: string, box: BoxValue) => {
    setLoading(true)
    await mapFocusData(text)
    mapFocusBoxes(box)
    setLoading(false)
  }, [mapFocusData, mapFocusBoxes])

  const boxesList = useMemo(() => {
    const box = _.get(focus, boxes)
    if(box?.fileIndex !== idx) return []

    return [box]
  }, [focus, boxes, idx])

  return {
    loading,
    focus: {
      value: focus,
      set: setFocus,
      updateState: updateFocusState
    },
    boxes: {
      state: boxes,
      current: boxesList
    },
    data: {
      state: data,
      set: setData
    }
  }
}
