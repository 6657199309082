import * as authService from '../services/auth.service';
import * as fileService from '../services/file.service';
import * as theboxService from '../services/thebox.service';
import * as billofladingService from '../services/bill_of_lading.service';

export type Injections = {
  authService: typeof authService,
  fileService: typeof fileService,
  theboxService: typeof theboxService,
  billofladingService: typeof billofladingService
}

const injections: Injections = {
  authService,
  fileService,
  theboxService,
  billofladingService
};

export default injections;
