import _ from "lodash/fp";
import { MapFnOf, ContainerData, RequiredOf } from "../../types";
import { getContainerAsSelectValue } from "../masterData";
import { stringToInteger, stringToNumber } from "../number";


export const mappings: MapFnOf<ContainerData> = {
  cont_id: _.identity,
  cont_type: _.flow(_.toString, getContainerAsSelectValue),
  seal_no: _.identity,
  packages: stringToInteger,
  weight: stringToNumber,
  volume: stringToNumber,
  non_operational: _.constant(true)
}

export const required: RequiredOf<ContainerData> = new Set([
  'cont_id', 
  'cont_type', 
  'seal_no',
] as (keyof ContainerData)[]) 
