import React from 'react'

type Props = React.PropsWithChildren<{
  className?: string
}>

const Card: React.FunctionComponent<Props> = ({children, className=''}) => {
  return (
    <div className={`tw-shadow-md tw-border tw-rounded tw-overflow-hidden tw-relative ${className}`}>
      {children}
    </div>
  )
}

export default Card
