export const handleStringChange = (handler: (value: string) => void) => {
  return (event: React.FormEvent<HTMLElement>): void => {
    handler((event.target as HTMLInputElement).value);
  };
}

export const preventParentTrigger = (handler?: () => void) => {
  return (event?: React.SyntheticEvent): void => {
    if (handler) {
      handler();
    }
    if (event) {
      event.stopPropagation();
    }
  };
}

export const preventDefaultTrigger = (handler?: () => void) => {
  return (event?: React.SyntheticEvent): void => {
    if (handler) {
      handler();
    }
    if (event) {
      event.preventDefault();
    }
  };
}
