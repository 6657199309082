import { IconNames } from '@blueprintjs/icons'
import React from 'react'
import { useContainerController } from '../hooks/container'
import BlueButton from './BlueButton'
import Card from './Card'
import ExpedienteContainerData from './ExpedienteContainerData'
import PDFViewer from './PDFViewer'

type Props = {
  file: File
  index: number
}


const ExpedienteContainerDataFiller: React.FunctionComponent<Props> = ({ file, index }) => {
  const { data, focus, boxes } = useContainerController(index)

  return (
    <>
      <Card>
        <PDFViewer 
          src={file} 
          onSelection={focus.updateState}
          boxes={boxes.current}
        />
      </Card>
      <Card className="tw-grid tw-grid-rows-[1fr,auto]">
        <ExpedienteContainerData
          data={data.list}
          onChangeDataAt={data.setAt}
          onRemoveDataAt={data.removeAt}
          focus={focus.value}
          onFocusField={focus.set}
        />
        <div className="tw-p-2">
          <BlueButton
            fill
            icon={IconNames.ADD}
            onClick={data.add}
          >
            Añadir contenedor
          </BlueButton>
        </div>
      </Card>
    </>
  )
}

export default ExpedienteContainerDataFiller
