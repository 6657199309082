import { SelectValue } from "../../types";

const createSeawaybill = (name: string): SelectValue => ({
  id: name, label: name
})

const seawaybillTypes = {
  ORIGINAL: createSeawaybill('ORIGINAL'),
  EXPRESS: createSeawaybill('EXPRESS'),
} as const

export const seawaybillList = [
  seawaybillTypes.ORIGINAL, 
  seawaybillTypes.EXPRESS
]

export default seawaybillTypes;
