import { useNavigation } from "../hooks/navigation"

const NavigationPanel = () => {
  const { mbl, hbl } = useNavigation()

  if(!mbl.active && !hbl.active){
    return null
  }

  return (
    <div className="tw-bg-gray-200 tw-font-bold tw-h-full tw-flex tw-items-center tw-justify-center tw-px-2 tw-rounded">
      {mbl.active && (
        <span>
          {`${mbl.current} de ${mbl.total} ${mbl.total > 1 ? 'MBLs' : 'MBL'}`}
        </span>
      )}
      {hbl.active && (
        <span>
          {`${hbl.current} de ${hbl.total} ${hbl.total > 1 ? 'HBLs' : 'HBL'}`}
        </span>
      )}
    </div>
  )
}

export default NavigationPanel
