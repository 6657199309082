import React from 'react'
import FocusContainer from '../FocusContainer'

type Props = React.PropsWithChildren<{
  isFocused?: boolean
}>

const Wrapper: React.FunctionComponent<Props> = ({ children, isFocused }) => {
  return (
    <FocusContainer isFocused={isFocused} className="tw-p-2">
      {children}
    </FocusContainer>
  )
}

export default Wrapper
