import _ from 'lodash/fp';
import React from 'react';
import { Rect } from '../types';

type Props = {
  rect?: Rect,
  className?: string,
  color?: string
};


const PDFRect: React.FunctionComponent<Props> = ({
  rect,
  className = '',
  color = 'rgba(0,0,255,0.2)'
}: Props) => {
  if (_.isNil(rect)) return null

  return (
    <div
      className={`tw-absolute tw-z-10 ${className}`}
      style={{ ...rect, backgroundColor: color }}
    />
  );
};

export default PDFRect;
