import _ from "lodash/fp";
import { ISO6346Chars, unknownCRC } from "./constants/crc";

const isChar = (c: string): boolean => /[A-Z]/g.test(c);
const isNumber = (c: string): boolean => /[0-9]/g.test(c);
const charToNumber = (c: string): number => {
  if (isChar(c)) return _.get(c, ISO6346Chars);

  if (isNumber(c)) return _.toInteger(c);

  return 0;
};
const powPosition = (n: number, pos: number) => n * 2 ** pos;

export const isISO6346 = (crc: string): boolean => {
  const s = _.replace(/\s/g, "", _.upperCase(crc));
  if (s.length !== 11) return false;

  const stringSplit = _.split("", s);

  const ownerCode = _.slice(0, 3, stringSplit);
  const categoryIdentifier = _.slice(3, 4, stringSplit);
  const serialNumber = _.slice(4, 10, stringSplit);
  const checkDigit = _.slice(10, 11, stringSplit);

  const ownerCodeAreLetters = _.every(isChar, ownerCode);
  const categoryIdentifierIsLetter = _.every(isChar, categoryIdentifier);
  const serialNumberAreNumbers = _.every(isNumber, serialNumber);
  const checkDigitIsNumber = _.every(isNumber, checkDigit);

  const validStringFormat =
    ownerCodeAreLetters &&
    categoryIdentifierIsLetter &&
    serialNumberAreNumbers &&
    checkDigitIsNumber;

  if (!validStringFormat) return false;

  const code = _.slice(0, 10, stringSplit);
  const check = _.toInteger(checkDigit[0]);

  // step 1: string to numbers
  const step1 = _.map(charToNumber, code);

  // step 2: number to number * 2^position
  //Using javascript map since _.map does not pass index
  const step2 = step1.map(powPosition);

  // step 3: sum mod 11 equals check digit
  const step3 = _.sum(step2) % 11;

  return step3 % 10 === check;
};

export const isUnknown = (crc: string) => {
  if (_.isEmpty(crc)) return false;

  return _.pipe(_.lowerCase, _.isEqual(unknownCRC))(crc);
};

export const isHSCODE = (hscode: string): boolean => {
  if (!hscode) return true;

  const s = _.replace(/\s/g, "", hscode);
  const pattern = /^\d{4}[\.\s]\d{2}|\d{6,10}$/;

  return pattern.test(s);
};
