import _ from "lodash/fp";
import { BoxValue, MBLState, Rect } from "../types";
import { TheBoxExtractionData } from "../types/api";
import { mappings, required, requiredSimple } from "./constants/mbl";
import { scaleFrom } from "./coords";
import { getGenericMappedValue, getGenericRequired, getGenericRequiredMark, isGenericDataComplete, scaleAndRoundAllGenericBoxes } from "./data";
import { dateToISO } from "./date";
import { getSeawaybillTypes, isExpress } from "./seawaybill";
import { theboxToIdentityValue, theboxToValue, updateTheBoxIdentityField, updateTheBoxField } from "./thebox";

export const createDefaultMBLData = (): MBLState['data'] => ({
  bl_num: null,
  carrier: null,
  shipper: null,
  port_loading: null,
  port_discharge: null,
  onboard: null,
  seawaybill: null,
  service_num: null,
  freight: null,
  vessel: null,
  voyage_no: null,
  num_containers: null,
  cont_type: null,
  non_operational: true,
  file_notes: null
})

export const createDefaultMBLBoxes = (): MBLState['boxes'] => ({
  bl_num: null,
  carrier: null,
  shipper: null,
  port_loading: null,
  port_discharge: null,
  onboard: null,
  seawaybill: null,
  service_num: null,
  freight: null,
  vessel: null,
  voyage_no: null,
  num_containers: null,
  cont_type: null,
  non_operational: null,
  file_notes: null
})

export const createDefaultMBL = (): MBLState => ({
  data: createDefaultMBLData(),
  boxes: createDefaultMBLBoxes()
})

export const getMappings = () => mappings
export const getRequired = () => required
export const getRequiredSimple = () => requiredSimple

export const getMBLMappings = getGenericMappedValue(getMappings())
export const getMBLRequired = getGenericRequired(getRequired())
export const getMBLRequiredMark = getGenericRequiredMark(getRequired())
export const getMBLRequiredSimple = getGenericRequired(getRequiredSimple())
export const getMBLRequiredSimpleMark = getGenericRequiredMark(getRequiredSimple())

export const isMBLDataComplete = isGenericDataComplete<Partial<MBLState['data']>>(getRequired())
export const isMBLSimpleDataComplete = isGenericDataComplete<Partial<MBLState['data']>>(getRequiredSimple())

export const generateMBLDataFromTheBox = async (extraction: TheBoxExtractionData) => {
  const data = createDefaultMBLData()
  const thebox = extraction?.data
  if (_.isNil(thebox)) return data

  const localTheboxToValue = theboxToValue(thebox)
  const localTheboxToIdentityValue = theboxToIdentityValue(thebox)
  const seawaybills = getSeawaybillTypes()

  data.bl_num = await getMBLMappings('bl_num')(localTheboxToValue('bl_num'))
  data.carrier = await getMBLMappings('carrier')(localTheboxToValue('carrier'))
  data.shipper = await getMBLMappings('shipper')(localTheboxToIdentityValue('shipper'))
  data.port_loading = await getMBLMappings('port_loading')(localTheboxToIdentityValue('port_loading'))
  data.port_discharge = await getMBLMappings('port_discharge')(localTheboxToIdentityValue('port_discharge'))
  data.onboard = await getMBLMappings('onboard')(localTheboxToValue('onboard'))
  data.freight = await getMBLMappings('freight')(localTheboxToValue('freight'))
  data.vessel = await getMBLMappings('vessel')(localTheboxToValue('vessel'))
  data.voyage_no = await getMBLMappings('voyage_no')(localTheboxToValue('voyage_no'))

  data.num_containers = _.size(thebox.containers)
  data.seawaybill = extraction?.seawaybill ? seawaybills.EXPRESS : seawaybills.ORIGINAL
  return data
}

export const generateMBLBoxesFromTheBox = (extraction: TheBoxExtractionData) => {
  const boxes = createDefaultMBLBoxes()
  if (_.isNil(extraction)) return boxes

  const canvas = extraction.canvas;
  const theboxData = extraction.data;

  const transform = (box: BoxValue) => {
    if (_.isNil(box)) return null

    return _.merge(scaleFrom(box as Rect, canvas), { page: box.page, fileIndex: 0 })
  }

  boxes.bl_num = transform(theboxData.bl_num);
  boxes.carrier = transform(theboxData.carrier);
  boxes.shipper = transform(theboxData.shipper);
  boxes.port_loading = transform(theboxData.port_loading);
  boxes.port_discharge = transform(theboxData.port_discharge);
  boxes.onboard = transform(theboxData.onboard);
  boxes.freight = transform(theboxData.freight);
  boxes.vessel = transform(theboxData.vessel);
  boxes.voyage_no = transform(theboxData.voyage_no);

  return boxes
}

export const generateMBLFromTheBox = async (extraction: TheBoxExtractionData): Promise<MBLState> => ({
  data: await generateMBLDataFromTheBox(extraction),
  boxes: generateMBLBoxesFromTheBox(extraction)
})

export const generateTheBoxFromMBL = _.curry((extraction: TheBoxExtractionData, mbl: MBLState) => {
  if (_.isNil(extraction)) return

  const thebox = _.cloneDeep(extraction)
  const theboxData = thebox.data
  const scaledBoxes = scaleAndRoundAllGenericBoxes(thebox.canvas)(mbl.boxes)

  theboxData.bl_num = updateTheBoxField(theboxData.bl_num, {value: mbl.data.bl_num, box: scaledBoxes.bl_num})
  theboxData.carrier = updateTheBoxField(theboxData.carrier, {value: mbl.data.carrier?.label, box: scaledBoxes.carrier})
  theboxData.shipper = updateTheBoxIdentityField(theboxData.shipper, {value: mbl.data.shipper, box: scaledBoxes.shipper})
  theboxData.port_loading = updateTheBoxIdentityField(theboxData.port_loading, {value: mbl.data.port_loading, box: scaledBoxes.port_loading})
  theboxData.port_discharge = updateTheBoxIdentityField(theboxData.port_discharge, {value: mbl.data.port_discharge, box: scaledBoxes.port_discharge})
  theboxData.onboard = updateTheBoxField(theboxData.onboard, {value: dateToISO(mbl.data.onboard), box: scaledBoxes.onboard})
  theboxData.freight = updateTheBoxField(theboxData.freight, {value: mbl.data.freight, box: scaledBoxes.freight})
  theboxData.vessel = updateTheBoxField(theboxData.vessel, {value: mbl.data.vessel, box: scaledBoxes.vessel})
  theboxData.voyage_no = updateTheBoxField(theboxData.voyage_no, {value: mbl.data.voyage_no, box: scaledBoxes.voyage_no})

  thebox.data = theboxData
  thebox.seawaybill = isExpress(mbl.data.seawaybill)

  return thebox
})
