import React from 'react';

const NotFound: React.FunctionComponent = () => (
  <div className="tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center">
    <div className="tw-font-bold tw-text-5xl">
      404 NOT FOUND
    </div>
  </div>
);

export default NotFound;
