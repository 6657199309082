import { Icon, Position } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { Tooltip2 } from '@blueprintjs/popover2'
import React from 'react'
import { preventParentTrigger } from '../utils/events'

type Props = {
  onClick: () => void
  content: string | JSX.Element
}

const HoverRemoveIcon: React.FunctionComponent<Props> = ({ onClick, content }) => {
  return (
    <div className="tw-cursor-pointer tw-transition-opacity tw-duration-300 tw-opacity-0 group-hover:tw-opacity-100">
      <Tooltip2 content={content} placement={Position.RIGHT}>
        <div
          className="tw-outline-none focus:tw-outline-none"
          onClick={preventParentTrigger(onClick)}
        >
          <Icon icon={IconNames.CROSS} />
        </div>
      </Tooltip2>
    </div>
  )
}

export default HoverRemoveIcon
