export type Route = {
  name: string
  link: string
}

const createRoute = (name: string, link: string): Route => ({
  name, link,
});

const routes = {
  login: createRoute('Login', '/login'),
  classify: createRoute('Classificador', '/classify'),
  expediente: createRoute('Expediente', '/expediente'),
  expediente_containers: createRoute('Expediente - Contenedores', '/expediente/containers'),
  booking: createRoute('Booking', '/booking'),
  booking_packages: createRoute('Booking - Bultos', '/booking/packages'),
  summary: createRoute('Resumen', '/summary'),
  root: createRoute('Subida ficheros', '/'),
} as const

export default routes;
