import _ from "lodash/fp";
import { HBLData, MapFnOf, RequiredOf, StringValue } from "../../types";
import { stringToDate } from "../date";
import { getLocodeAsSelectValue } from "../masterData";
import { getConsigneeAsSelectValue, getNotifyAsSelectValue, getShipperAsSelectValue } from "../players";
import { findSeawaybillByLabel } from "../seawaybill";

export const mappings: MapFnOf<HBLData> = {
  bl_num: _.identity,
  place_receipt: _.flow(_.toString, getLocodeAsSelectValue),
  place_delivery: _.flow(_.toString, getLocodeAsSelectValue),
  shipper: _.flow(_.toString, getShipperAsSelectValue),
  consignee: _.flow(_.toString, getConsigneeAsSelectValue),
  notify: _.flow(_.toString, getNotifyAsSelectValue),
  freight: _.identity,
  seawaybill: findSeawaybillByLabel,
  onboard: stringToDate,
  client_reference: _.identity,
  on_hold: _.constant(true),
  internal_comments: _.identity,
  containers: (data: StringValue) => []
}

export const required: RequiredOf<HBLData> = new Set([
  'bl_num',
  'place_receipt',
  'place_delivery',
  'consignee',
  'freight',
  'seawaybill',
  'onboard',
] as (keyof HBLData)[])

export const maxClientReferenceLength = 100
export const maxBlNumLength = 50
export const maxInternalCommentsLength = 5000