import _ from "lodash/fp";
import { Point, Polar, Rect, Scale } from "../types";
import { DEFAULT_SCALE } from "./constants/coords";

const sort = (rects: Rect[]): Rect[] => _.sortBy(['top', 'left'], rects)

const overlaps = (A: Rect, B: Rect): boolean => (
  A.left <= B.left &&
  B.left <= A.left + A.width
);

const sameLine = (A: Rect, B: Rect, yMargin = 5): boolean => (
  Math.abs(A.top - B.top) < yMargin &&
  Math.abs(A.height - B.height) < yMargin
)

const inside = (A: Rect, B: Rect): boolean => (
  A.top > B.top &&
  A.left > B.left &&
  A.top + A.height < B.top + B.height &&
  A.left + A.width < B.left + B.width
);

const nextTo = (A: Rect, B: Rect, xMargin = 10): boolean => {
  const Aright = A.left + A.width;
  const Bright = B.left + B.width;
  return (
    A.left <= B.left &&
    Aright <= Bright &&
    B.left - Aright <= xMargin
  );
};

const extendWidth = (A: Rect, B: Rect): void => {
  // extend width of A to cover B
  A.width = Math.max(B.width - A.left + B.left, A.width);
};

export const optimizeRects = (rects: Rect[]): Rect[] => {
  const rs = sort(rects);

  const toRemove = new Set();

  const firstPass = _.filter((rect) => _.every((otherRect) => !inside(rect, otherRect), rs), rs);

  let passCount = 0;

  while (passCount <= 2) {
    _.forEach((A) => {
      _.forEach((B) => {
        if (A === B || toRemove.has(A) || toRemove.has(B)) return;

        if (!sameLine(A, B)) return;

        if (overlaps(A, B)) {
          extendWidth(A, B);
          A.height = Math.max(A.height, B.height);

          toRemove.add(B);
        }

        if (nextTo(A, B)) {
          extendWidth(A, B);

          toRemove.add(B);
        }
      }, firstPass);
    }, firstPass);
    passCount += 1;
  }

  return _.filter((rect) => !toRemove.has(rect), firstPass);
};

export const getRectFromPoints = (startPoint: Point, endPoint: Point): Rect => ({
  left: Math.min(endPoint.x, startPoint.x),
  top: Math.min(endPoint.y, startPoint.y),

  width: Math.abs(endPoint.x - startPoint.x),
  height: Math.abs(endPoint.y - startPoint.y),
});

export const getBoundingRect = (rects: Rect[]): Rect => {
  const rs = _.map((rect) => {
    const { left, top, width, height } = rect;

    const X0 = left;
    const X1 = left + width;

    const Y0 = top;
    const Y1 = top + height;

    return {
      X0,
      X1,
      Y0,
      Y1,
    };
  }, rects);

  const optimal = _.reduce(
    (res, rect) => ({
      X0: Math.min(res.X0, rect.X0),
      X1: Math.max(res.X1, rect.X1),

      Y0: Math.min(res.Y0, rect.Y0),
      Y1: Math.max(res.Y1, rect.Y1),
    }),
    rs[0],
    rs
  );

  const { X0, X1, Y0, Y1 } = optimal;

  return {
    left: X0,
    top: Y0,
    width: X1 - X0,
    height: Y1 - Y0,
  };
};

const scale = (rect: Rect, scale: Scale): Rect => ({
  height: rect!.height * scale.height,
  width: rect!.width * scale.width,
  top: rect!.top * scale.height,
  left: rect!.left * scale.width,
});

const scaleFromTo = (rect: Rect, from: Scale, to: Scale) => (
  scale(rect, {
    width: to.width / from.width,
    height: to.height / from.height
  })
);

export const scaleFrom = (rect: Rect, from: Scale) => (
  scaleFromTo(rect, from, DEFAULT_SCALE)
);

export const scaleTo = (rect: Rect, to: Scale) => (
  scaleFromTo(rect, DEFAULT_SCALE, to)
);

const cartesianToPolar = (point: Point): Polar => ({
  radians: Math.atan2(point.y, point.x),
  distance: Math.sqrt(point.x * point.x + point.y * point.y)
})

const polarToCartesian = (polar: Polar): Point => ({
  x: polar.distance * Math.cos(polar.radians),
  y: polar.distance * Math.sin(polar.radians),
})

const rotatePolar = (angle: number, polar: Polar): Polar => ({
  radians: polar.radians + ((Math.PI / 180) * (angle % 360)),
  distance: polar.distance
})

const translatePoint = (translation: Point, point: Point): Point => ({
  x: point.x + translation.x,
  y: point.y + translation.y,
})

export const rotate = (angle: number, rect: Rect): Rect => {
  if (angle % 360 === 0 || angle % 90 !== 0) return rect;

  //Create translation original canvas
  const canvasPoint = { x: DEFAULT_SCALE.width, y: DEFAULT_SCALE.height };
  const center1 = { x: canvasPoint.x / 2, y: canvasPoint.y / 2 };
  const tCenter1 = { x: -center1.x, y: -center1.y };

  //Rotate canvas
  const canvasPolar = cartesianToPolar(canvasPoint);
  const rotateCanvasPolar = rotatePolar(angle, canvasPolar);
  const canvasCartesian = polarToCartesian(rotateCanvasPolar);
  const canvas = {
    width: Math.abs(canvasCartesian.x),
    height: Math.abs(canvasCartesian.y),
  };

  //Create translation rotated canvas
  const center2 = { x: canvas.width / 2, y: canvas.height / 2 };
  const tCenter2 = { x: center2.x, y: center2.y };

  //Create 4 points for our rectangle
  const p1 = { x: rect.left, y: rect.top };
  const p2 = { x: rect.left + rect.width, y: rect.top };
  const p3 = { x: rect.left, y: rect.top + rect.height };
  const p4 = { x: rect.left + rect.width, y: rect.top + rect.height };

  //Translate to coord 0 0
  const tp1 = translatePoint(tCenter1, p1);
  const tp2 = translatePoint(tCenter1, p2);
  const tp3 = translatePoint(tCenter1, p3);
  const tp4 = translatePoint(tCenter1, p4);

  //Get polar coord
  const pp1 = cartesianToPolar(tp1);
  const pp2 = cartesianToPolar(tp2);
  const pp3 = cartesianToPolar(tp3);
  const pp4 = cartesianToPolar(tp4);

  //Rotate polar coord
  const rp1 = rotatePolar(angle, pp1);
  const rp2 = rotatePolar(angle, pp2);
  const rp3 = rotatePolar(angle, pp3);
  const rp4 = rotatePolar(angle, pp4);

  //Get cartesian coord
  const cp1 = polarToCartesian(rp1);
  const cp2 = polarToCartesian(rp2);
  const cp3 = polarToCartesian(rp3);
  const cp4 = polarToCartesian(rp4);

  //Translate into second canvas
  const scp1 = translatePoint(tCenter2, cp1);
  const scp2 = translatePoint(tCenter2, cp2);
  const scp3 = translatePoint(tCenter2, cp3);
  const scp4 = translatePoint(tCenter2, cp4);

  //Calculate heatmap props
  const top = Math.min(scp1.y, scp2.y, scp3.y, scp4.y);
  const left = Math.min(scp1.x, scp2.x, scp3.x, scp4.x);
  const height = Math.max(scp1.y, scp2.y, scp3.y, scp4.y) - top;
  const width = Math.max(scp1.x, scp2.x, scp3.x, scp4.x) - left;

  return {
    top,
    height,
    width,
    left,
  };
};

export const round = (rect: Rect) => _.mapValues(_.round, rect) as Rect
