import React from 'react';
import Card from '../components/Card';
import LoginForm from '../components/LoginForm';
import MainLogo from '../components/MainLogo';
import { useRedirect } from '../hooks/redirect';
import { useSessionActions } from '../hooks/session';
import { LoginData } from '../types';
import { showSuccessAlert } from '../utils/alert';
import { handleError } from '../utils/errors';

const Login: React.FunctionComponent = () => {
  const login = useSessionActions(actions => actions.login);
  const redirect = useRedirect();
  
  const handleLogin = async (data: LoginData) => {
    await login(data)
    showSuccessAlert("Login con éxito")
    redirect()
  }

  return (
    <div className="tw-w-full tw-h-full tw-flex tw-flex-col tw-items-center tw-justify-center">
      <MainLogo className="tw-w-64" />
      <Card className="tw-mt-10 tw-p-4">
        <LoginForm onSubmit={handleError(handleLogin)} />
      </Card>
    </div>
  );
};

export default Login;
