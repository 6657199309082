import { ToastProps } from '@blueprintjs/core';

import Alert, {
  defaultErrorProps, defaultInfoProps, defaultSuccessProps, defaultWarningProps,
} from './constants/alert';

const showAlert = (props: ToastProps): string => Alert.show(props);

export const showErrorAlert = (message: string): string => (
  showAlert({ message, ...defaultErrorProps } as ToastProps)
);
export const showSuccessAlert = (message: string): string => (
  showAlert({ message, ...defaultSuccessProps } as ToastProps)
);
export const showWarningAlert = (message: string): string => (
  showAlert({ message, ...defaultWarningProps } as ToastProps)
);
export const showInfoAlert = (message: string): string => (
  showAlert({ message, ...defaultInfoProps } as ToastProps)
);
