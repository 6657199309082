import React from 'react';
import _ from 'lodash/fp';
import FileListItem from './FileListItem';
import ScrollableList from './ScrollableList';

type Props = {
  files: File[];
  selected?: number;
  onClick?: (i: number) => void;
  onRemove?: (i: number) => void;
};

const FileList: React.FunctionComponent<Props> = ({ files, onRemove, onClick, selected }: Props) => {
  return (
    <ScrollableList className="tw-p-2">
      {files.map((file: File, index: number) => (
        <FileListItem
          key={file.name}
          file={file}
          isSelected={selected === index}
          onClick={_.isFunction(onClick) ? () => onClick(index) : undefined}
          onRemove={_.isFunction(onRemove) ? () => onRemove(index) : undefined}
        />
      ))}
    </ScrollableList>
  );
};

export default FileList;
