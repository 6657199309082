import { AxiosResponse } from "axios";
import { MasterData } from "../types/api";
import client from "./apiClient";
import { api } from "./endpoints";

export const getContainers = async (search: string): Promise<MasterData[]> => {
  const response: AxiosResponse<MasterData[]> = await client.get(
    `${api.masterData}/containers`,
    {
      params: { search },
    }
  );
  return response.data;
};

export const getLocodes = async (
  search: string,
  code: string
): Promise<MasterData[]> => {
  const response: AxiosResponse<MasterData[]> = await client.get(
    `${api.masterData}/locodes`,
    {
      params: { search, code },
    }
  );
  return response.data;
};

export const getPackages = async (search: string): Promise<MasterData[]> => {
  const response: AxiosResponse<MasterData[]> = await client.get(
    `${api.masterData}/packages`,
    {
      params: { search },
    }
  );
  return response.data;
};

export const getDocumentTypes = async (): Promise<MasterData[]> => {
  const response: AxiosResponse<MasterData[]> = await client.get(
    `${api.masterData}/documents`
  );
  return response.data;
};
