import React from 'react'
import { MBLData } from '../types'
import { maxFileNotesLength } from '../utils/constants/mbl'
import { isGenericFocus, onChangeGenericData } from '../utils/data'
import { getLocodesAsSelectValueList } from '../utils/masterData'
import { getMBLRequiredMark } from '../utils/mbl'
import { getCarriersAsSelectValueList, getShippersAsSelectValueList } from '../utils/players'
import { getSeawaybillList } from '../utils/seawaybill'
import AsyncSelect from './DataInput/AsyncSelect'
import Button from './DataInput/Button'
import Date from './DataInput/Date'
import Number from './DataInput/Number'
import Text from './DataInput/Text'
import Wrapper from './DataInput/Wrapper'
import ScrollableList from './ScrollableList'

export type Props = {
  data: MBLData,
  onChangeData: (data: MBLData) => void
  focus?: keyof MBLData,
  onFocusField?: (field: keyof MBLData) => void
}

const ExpedienteMBLData: React.FunctionComponent<Props> = ({ data, onChangeData, focus, onFocusField }) => {

  const onChange = onChangeGenericData(data, onChangeData)

  const isFocused = isGenericFocus(focus)

  return (
    <ScrollableList >
      <Wrapper isFocused={isFocused('bl_num')}>
        <Text
          label={`Nº de MBL ${getMBLRequiredMark('bl_num')}`}
          value={data.bl_num}
          onChange={onChange('bl_num')}
          onFocusInput={() => onFocusField?.('bl_num')}
        />
      </Wrapper>
      <Wrapper isFocused={isFocused('carrier')}>
        <AsyncSelect
          label={`Naviera ${getMBLRequiredMark('carrier')}`}
          value={data.carrier}
          onChange={onChange('carrier')}
          onFocusInput={() => onFocusField?.('carrier')}
          onQueryOptions={getCarriersAsSelectValueList}
        />
      </Wrapper>
      <Wrapper isFocused={isFocused('shipper')}>
        <AsyncSelect
          label={`Shipper ${getMBLRequiredMark('shipper')}`}
          value={data.shipper}
          onChange={onChange('shipper')}
          onFocusInput={() => onFocusField?.('shipper')}
          onQueryOptions={getShippersAsSelectValueList}
        />
      </Wrapper>
      <Wrapper isFocused={isFocused('port_loading')}>
        <AsyncSelect
          label={`Puerto de carga ${getMBLRequiredMark('port_loading')}`}
          value={data.port_loading}
          onChange={onChange('port_loading')}
          onFocusInput={() => onFocusField?.('port_loading')}
          onQueryOptions={getLocodesAsSelectValueList}
        />
      </Wrapper>
      <Wrapper isFocused={isFocused('port_discharge')}>
        <AsyncSelect
          label={`Puerto de descarga ${getMBLRequiredMark('port_discharge')}`}
          value={data.port_discharge}
          onChange={onChange('port_discharge')}
          onFocusInput={() => onFocusField?.('port_discharge')}
          onQueryOptions={getLocodesAsSelectValueList}
        />
      </Wrapper>
      <Wrapper isFocused={isFocused('onboard')}>
        <Date
          label={`Fecha de emisión ${getMBLRequiredMark('onboard')}`}
          value={data.onboard}
          onChange={onChange('onboard')}
          onFocusInput={() => onFocusField?.('onboard')}
        />
      </Wrapper>
      <Wrapper isFocused={isFocused('seawaybill')}>
        <Button
          label={`BL original o express  ${getMBLRequiredMark('seawaybill')}`}
          value={data.seawaybill}
          options={getSeawaybillList()}
          onChange={onChange('seawaybill')}
          onFocusInput={() => onFocusField?.('seawaybill')}
        />
      </Wrapper>
      <Wrapper isFocused={isFocused('service_num')}>
        <Text
          label={`Service contract number ${getMBLRequiredMark('service_num')}`}
          value={data.service_num}
          onChange={onChange('service_num')}
          onFocusInput={() => onFocusField?.('service_num')}
        />
      </Wrapper>
      <Wrapper isFocused={isFocused('freight')}>
        <Text
          label={`Prepaid o collect ${getMBLRequiredMark('freight')}`}
          value={data.freight}
          onChange={onChange('freight')}
          onFocusInput={() => onFocusField?.('freight')}
        />
      </Wrapper>
      <Wrapper isFocused={isFocused('vessel')}>
        <Text
          label={`Barco ${getMBLRequiredMark('vessel')}`}
          value={data.vessel}
          onChange={onChange('vessel')}
          onFocusInput={() => onFocusField?.('vessel')}
        />
      </Wrapper>
      <Wrapper isFocused={isFocused('voyage_no')}>
        <Text
          label={`Viaje ${getMBLRequiredMark('voyage_no')}`}
          value={data.voyage_no}
          onChange={onChange('voyage_no')}
          onFocusInput={() => onFocusField?.('voyage_no')}
        />
      </Wrapper>
      <Wrapper isFocused={isFocused('file_notes')}>
        <Text
          label={`Notas file ${getMBLRequiredMark('file_notes')}`}
          value={data.file_notes}
          onChange={onChange('file_notes')}
          onFocusInput={() => onFocusField?.('file_notes')}
          maxLength={maxFileNotesLength}
          large={true}
        />
      </Wrapper>
      <Wrapper isFocused={isFocused('num_containers')}>
        <Number
          label={`Número de contenedores ${getMBLRequiredMark('num_containers')}`}
          value={data.num_containers}
          isInteger
          onChange={onChange('num_containers')}
          onFocusInput={() => onFocusField?.('num_containers')}
        />
      </Wrapper>
    </ScrollableList>
  )
}

export default ExpedienteMBLData
