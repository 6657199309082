import { SelectValue } from "../../types"

const createDocType = (id: string, label: string): SelectValue => ({
  id, label
})

const createDataType = (name: string) => createDocType(name, name)

export const docDataType = {
  EXP: createDataType('Expediente'),
  BKN: createDataType('Booking'),
} as const

export const docDataTypesList = [
  docDataType.EXP,
  docDataType.BKN
]
