import React from 'react';
import LayoutHeader from './LayoutHeader';

type Props = React.PropsWithChildren<{
  onClickNext?: () => Promise<void> | void;
  isNextDisabled?: boolean;
}>;

const Layout: React.FunctionComponent<Props> = ({
  children,
  onClickNext,
  isNextDisabled,
}) => {
  return (
    <div className="tw-h-full tw-flex-1 tw-flex tw-flex-col">
      <LayoutHeader
        onClickNext={onClickNext}
        isNextDisabled={isNextDisabled}
      />
      <div className="tw-flex tw-h-full tw-flex-1">
        {children}
      </div>
    </div>
  );
};

export default Layout;
