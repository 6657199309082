import { Icon, Position } from '@blueprintjs/core';
import { DateInput as DateInputBlueprint } from '@blueprintjs/datetime';
import _ from 'lodash/fp';
import { IconNames } from '@blueprintjs/icons';
import MomentLocaleUtils from 'react-day-picker/moment';
import React from 'react';
import { DateValue } from '../../types';
import { dateToString, getDefaultFormat, getDefaultLocale, getMaxDate, getMinDate, stringToDate } from '../../utils/date';
import ResetButton from './ResetButton';

type Props = {
  value: DateValue;
  onChange: (date: DateValue, fromCalendar: boolean) => void;
  onFocus?: () => void;
};

export const DateInput: React.FunctionComponent<Props> = ({
  value,
  onChange,
  onFocus = _.noop,
}: Props) => {
  return (
    <DateInputBlueprint
      formatDate={dateToString}
      parseDate={stringToDate}
      maxDate={getMaxDate()}
      minDate={getMinDate()}
      placeholder={getDefaultFormat()}
      canClearSelection={false}
      popoverProps={{ 
        position: Position.TOP, 
        canEscapeKeyClose: true,
      }}
      value={value}
      fill
      invalidDateMessage="Fecha no válida"
      locale={getDefaultLocale()}
      localeUtils={MomentLocaleUtils}
      onChange={onChange}
      inputProps={{
        onFocus: onFocus,
        leftIcon: <Icon icon={IconNames.CALENDAR} />,
        rightElement: !_.isNil(value) ? <ResetButton onClick={() => onChange(null, false)}/> : undefined
      }}
    />
  );
};

export default DateInput;
