import _ from 'lodash/fp';
import React from 'react';
import { usePDFSelection } from '../hooks/pdf';
import { BoxValue } from '../types';
import LoadingOverlay from './LoadingOverlay';
import PDFRect from './PDFRect';

type Props = React.PropsWithChildren<{
  onSelection?: (text: string, box: BoxValue) => void
}>

const PDFSelectionLayer: React.FunctionComponent<Props> = ({
  children,
  onSelection,
}) => {
  const { mouseRect, processing } = usePDFSelection(onSelection)

  return (
    <>
      {children}
      {_.isFunction(onSelection) && (
        <PDFRect
          className="tw-border tw-border-dashed tw-border-black"
          rect={mouseRect}
        />
      )}
      <LoadingOverlay text='Procesando...' isOpen={processing} />
    </>
  );
}

export default PDFSelectionLayer;
