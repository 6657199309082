import _ from "lodash/fp";
import { MapFnOf, MBLData, RequiredOf } from "../../types";
import { stringToDate } from "../date";
import { getContainerAsSelectValue, getLocodeAsSelectValue } from "../masterData";
import { stringToInteger } from "../number";
import { getCarrierAsSelectValue, getShipperAsSelectValue } from "../players";
import { findSeawaybillByLabel } from "../seawaybill";

export const mappings: MapFnOf<MBLData> = {
  bl_num: _.identity,
  carrier: _.flow(_.toString, getCarrierAsSelectValue),
  shipper: _.flow(_.toString, getShipperAsSelectValue),
  port_loading: _.flow(_.toString, getLocodeAsSelectValue),
  port_discharge: _.flow(_.toString, getLocodeAsSelectValue),
  onboard: stringToDate,
  seawaybill: findSeawaybillByLabel,
  service_num: _.identity,
  freight: _.identity,
  vessel: _.identity,
  voyage_no: _.identity,
  num_containers: stringToInteger,
  cont_type: _.flow(_.toString, getContainerAsSelectValue),
  non_operational: _.constant(true),
  file_notes: _.identity
}

export const required: RequiredOf<MBLData> = new Set([
  'bl_num', 
  'carrier',
  'shipper',
  'port_loading', 
  'port_discharge',
  'seawaybill',
  'freight',
  'vessel',
  'voyage_no',
  'num_containers'
] as (keyof MBLData)[]) 

export const requiredSimple: RequiredOf<MBLData> = new Set([
  'carrier',
  'shipper',
  'port_discharge', 
  'port_loading',
  'cont_type'
] as (keyof MBLData)[])

export const maxFileNotesLength = 5000