import _ from "lodash/fp";
import { BoxesOf, BoxValue, MapFnOf, Rect, RequiredOf, Scale, StringValue } from "../types";
import { round, scaleTo } from "./coords";

export const onChangeGenericData =
  <T extends object>(data: T, changeCB: (data: T) => void) =>
    <K extends keyof T>(field: K) =>
      (value: T[K]) => changeCB(_.set(field, value, data))


export const isGenericFocus = <T extends object>(a: keyof T | undefined) => (b: keyof T) => a === b

export const getGenericMappedValue =
  <T extends object>(map: MapFnOf<T>) =>
    <K extends keyof T>(field: K) =>
      (value: StringValue) => {
        const fn = _.get(field, map);
        if (!_.isFunction(fn)) return;

        return fn(value);
      }

export const getGenericRequired =
  <T extends object>(required: RequiredOf<T>) =>
    <K extends keyof T>(field: K) => (
      required.has(field)
    )

export const getGenericRequiredMark =
  <T extends object>(required: RequiredOf<T>) => {
    const requiredFn = getGenericRequired(required)
    return (
      <K extends keyof T>(field: K) => (
        requiredFn(field) ? '*' : ''
      )
    )
  }

export const isGenericFieldEmpty =
  <T extends object>(data: T) =>
    <K extends keyof T>(field: K) => (
      _.pipe([_.get(field), _.toString, _.isEmpty])(data)
    )

export const isGenericDataComplete =
  <T extends object>(required: RequiredOf<T>) => {
    const isRequired = getGenericRequired(required)
    return (data: T) => {
      const fields = _.keys(data)
      const isFieldEmpty = isGenericFieldEmpty(data)
      const isFieldComplete = (key: keyof T) => (
        !isRequired(key) || (isRequired(key) && !isFieldEmpty(key))
      )
      return _.every(isFieldComplete, fields)
    }
  }


export const mergeGenericData =
  <T extends object>(A: T, B: T) => (
    _.mergeWith(
      (a, b) => (_.pipe(_.toString, _.isEmpty)(a) ? b : a),
      A, B)
  )

export const genericRemoveAt =
  <T>(data: T[]) =>
    (index: number) => (
      _.concat(_.slice(0, index, data), _.slice(index + 1, _.size(data), data))
    )



export const scaleAndRoundAllGenericBoxes =
  (scale: Scale) =>
    <T extends BoxesOf<object>>(boxes: T) => {
      const scaleFn = (box: BoxValue) => _.isNil(box) ? box : _.merge(box, round(scaleTo(box as Rect, scale)))
      return _.mapValues(scaleFn, boxes) as T
    }
