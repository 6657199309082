import _ from "lodash/fp";
import { SelectValue } from "../types";
import seawaybillTypes, { seawaybillList } from "./constants/seawaybill";
import { findById, findByLabel } from "./select";

export const getSeawaybillTypes = () => seawaybillTypes
export const getSeawaybillList = () => seawaybillList

export const isOriginal = (value: SelectValue) => seawaybillTypes.ORIGINAL?.id === value?.id
export const isExpress = (value: SelectValue) => seawaybillTypes.EXPRESS?.id === value?.id

export const findSeawaybillById = findById(_.values(seawaybillTypes))
export const findSeawaybillByLabel = findByLabel(_.values(seawaybillTypes))
