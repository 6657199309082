import { createStore, persist } from 'easy-peasy';
import localforage from 'localforage';

import injections from './injections';
import model from './models';
import { Model } from './types';

const store = createStore<Model>(persist(model, { storage: localforage }), {
  name: 'CLOPY-BL-PRE-ALERTA',
  injections,
});

export default store;
