import { AxiosResponse } from "axios";
import _ from "lodash";
import { StringValue } from "../types";
import { TheBoxDataList, TheBoxExtractionData } from "../types/api";
import { isProduction } from "../utils/envs";
import { thebox } from './endpoints';
import client from "./theBoxClient";

const createFileForm = (file: File, tenant: StringValue) => {
  const form: FormData = new FormData();
  form.append('file', file);
  if(!_.isNil(tenant)){
    form.append('tenant_code', tenant);
  }
  return form
}


export const extract = async (file: File, tenant: StringValue) => {
  if(!isProduction()) return undefined;
  //if thebox does not work, just return undefined and keep going

  try {
    const response: AxiosResponse<TheBoxDataList> = await client.post(
      thebox.extract,
      createFileForm(file, tenant)
    );
    return response.data.bls[0];
  } catch (err) {
    console.error(err)
    return undefined
  }
}

export const validate = async (data: TheBoxExtractionData) => {
  if(!isProduction()) return undefined;
  
  //if thebox does not work, just return undefined and keep going
  try {
    await client.post(
      thebox.validate,
      data
    );
  } catch (err) {
    console.error(err)
    console.log(JSON.stringify(data))
    return undefined;
  }
}
