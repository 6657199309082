import _ from 'lodash/fp';
import React, { useEffect, useRef, useState } from 'react';

/* eslint-disable react-hooks/exhaustive-deps */

export const useEffectOnce = (cb: React.EffectCallback) => {
  useEffect(cb, [])
}

export const useArray = <T>(defaultValue?: T[]) => {
  const [array, setArray] = useState(defaultValue ?? []);

  const push = (element: T) => (
    setArray((current) => _.concat(current, element))
  );

  const filter = (callback: (element: T) => boolean) => (
    setArray((current) => _.filter(callback, current))
  );

  const update = (index: number, newElement: T) => (
    setArray((current) =>
      _.concat(_.slice(0, index, current),
        _.concat(newElement, _.slice(index + 1, current.length, current))))
  );

  const remove = (index: number) => (
    setArray((current) =>
      _.concat(_.slice(0, index, current),
        _.slice(index + 1, current.length, current)))
  );

  const clear = () => setArray([]);


  const actions = {
    set: setArray,
    push,
    filter,
    update,
    remove,
    clear
  }
  return [array, actions] as [typeof array, typeof actions]

};

export const useEventListener = <EventMap extends Record<string, any>, K extends keyof EventMap>(
    eventType: K,
    callback: (
      this: typeof element,
      evt: EventMap[K],
    ) => void,
    element: any = window,
) => {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    if (_.isNil(element)) return;

    const handler: typeof callbackRef.current = (evt) => callbackRef.current.call(element, evt);

    element.addEventListener(eventType, handler);
    return () => element.removeEventListener(eventType, handler);
  }, [eventType, element]);
};

export const useOnlineStatus = () => {
  const [online, setOnline] = useState(navigator.onLine)

  useEventListener("online", () => setOnline(navigator.onLine))
  useEventListener("offline", () => setOnline(navigator.onLine))

  return online
}

/* eslint-enable react-hooks/exhaustive-deps */
