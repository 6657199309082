import _ from "lodash/fp"
import { useCallback, useMemo } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { Route } from "../utils/constants/routes"
import { getPath } from "../utils/routes"

export const useRedirect = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const from = useMemo(() => (location as unknown as any).state?.from, [location])

  const redirect = useCallback((route?: Route) => {
    const path = getPath(route)
    if(_.isEmpty(path)) {
      navigate(from || "/")
      return
    }
    navigate(path!)
  }, [from, navigate])

  return redirect
} 
