import React from 'react';
import { Overlay, Spinner, SpinnerSize } from '@blueprintjs/core';

type Props = {
  text?: string
  isOpen?: boolean
}

const LoadingOverlay: React.FunctionComponent<Props> = ({ text, isOpen}) => {
  return (
    <Overlay isOpen={isOpen} usePortal={true} >
      <div className="tw-w-full tw-h-full tw-flex tw-flex-col tw-space-y-4 tw-items-center tw-justify-center">
        <Spinner size={SpinnerSize.STANDARD}  />
        <div className="tw-font-bold tw-text-lg tw-text-white">{text}</div>
      </div>
    </Overlay>
  )
};

export default LoadingOverlay;
