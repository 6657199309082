import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import _ from 'lodash/fp';
import React from 'react';
import AlertContainer from '../components/AlertContainer';
import Dropzone from '../components/Dropzone';
import FileList from '../components/FileList';
import Layout from '../components/Layout';
import { usePageActions } from '../hooks/page';
import { useRedirect } from '../hooks/redirect';
import { useStoreActions } from '../hooks/store';
import { useUploadFiles } from '../hooks/uploadfiles';
import routes from '../utils/constants/routes';
import { handleError } from '../utils/errors';
import { getAllAcceptedExtensionsAsList } from '../utils/file';

const acceptedExtensions = getAllAcceptedExtensionsAsList()

const UploadFiles: React.FunctionComponent = () => {
  const splitFiles = usePageActions(actions => actions.splitFiles)
  const clearStore = useStoreActions(actions => actions.clear)
  const [files, setFiles, removeFile] = useUploadFiles()
  const redirect = useRedirect()

  const handleNext = async () => {
    clearStore()
    
    await splitFiles(files)
    redirect(routes.classify)
  }

  return (
    <Layout onClickNext={handleError(handleNext)} isNextDisabled={_.isEmpty(files)}>
      <div className="tw-w-full tw-gap-2 tw-grid tw-grid-cols-[1fr,3fr] tw-p-2">
        <Dropzone onDrop={setFiles} acceptedExtensions={acceptedExtensions}>
          {!_.isEmpty(files) ? (
            <FileList files={files} onRemove={removeFile} />
          ) : (
            <AlertContainer message="No hay ficheros" />
          )}
        </Dropzone>
        <Dropzone onDrop={setFiles} acceptedExtensions={acceptedExtensions} >
          <div className="tw-flex-1 tw-w-full tw-h-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-p-4">
            <span className="tw-text-center tw-text-3xl tw-font-medium">
              Arrastra el fichero o haz click para seleccionar fichero
            </span>
            <div className="tw-mt-8">
              <Icon icon={IconNames.ADD} iconSize={60} />
            </div>
          </div>
        </Dropzone>
      </div>
    </Layout>
  );
};

export default UploadFiles;
