import _ from "lodash/fp";
import { HBLState, MBLState, Rect, StringValue } from "../types";
import { TheBoxExtractionData, TheBoxFieldValue } from "../types/api";
import { mappings, required } from "./constants/hbl";
import { scaleFrom } from "./coords";
import { getGenericMappedValue, getGenericRequired, getGenericRequiredMark, isGenericDataComplete, scaleAndRoundAllGenericBoxes } from "./data";
import { dateToISO } from "./date";
import { getSeawaybillTypes, isExpress } from "./seawaybill";
import { theboxToIdentityValue, theboxToValue, updateTheBoxIdentityField, updateTheBoxField } from "./thebox";

export const createDefaultHBLData = (): HBLState['data'] => ({
  bl_num: null,
  place_receipt: null,
  place_delivery: null,
  shipper: null,
  consignee: null,
  notify: null,
  freight: null,
  seawaybill: null,
  onboard: null,
  client_reference: null,
  on_hold: false,
  containers: [],
  internal_comments: null
})

export const createDefaultHBLBoxes = (): HBLState['boxes'] => ({
  bl_num: null,
  place_receipt: null,
  place_delivery: null,
  shipper: null,
  consignee: null,
  notify: null,
  freight: null,
  seawaybill: null,
  onboard: null,
  client_reference: null,
  on_hold: null,
  containers: null,
  internal_comments: null
})

export const createDefaultHBL = (): HBLState => ({
  data: createDefaultHBLData(),
  boxes: createDefaultHBLBoxes()
})

export const getMappings = () => mappings
export const getRequired = () => required

export const getHBLMappings = getGenericMappedValue(getMappings())
export const getHBLRequired = getGenericRequired(getRequired())
export const getHBLRequiredMark = getGenericRequiredMark(getRequired())

export const isHBLDataComplete = isGenericDataComplete(getRequired())

export const isSameAsConsignee = (val: StringValue): boolean => {
  if (_.isEmpty(val)) return false

  const regex = [/same/gi, /consignee/gi]

  return _.some((r) => !_.isNil(val?.match(r)), regex)
}

export const generateHBLDataFromTheBox = async (extraction: TheBoxExtractionData) => {
  const data = createDefaultHBLData()
  const thebox = extraction?.data
  if (_.isNil(thebox)) return data

  const localTheboxToValue = theboxToValue(thebox)
  const localTheboxToIdentityValue = theboxToIdentityValue(thebox)

  const seawaybills = getSeawaybillTypes()

  data.bl_num = await getHBLMappings('bl_num')(localTheboxToValue('bl_num'));
  data.shipper = await getHBLMappings('shipper')(localTheboxToIdentityValue('shipper'));
  data.onboard = await getHBLMappings('onboard')(localTheboxToValue('onboard'));
  data.freight = await getHBLMappings('freight')(localTheboxToValue('freight'));
  data.consignee = await getHBLMappings('consignee')(localTheboxToIdentityValue('consignee'));
  data.place_receipt = await getHBLMappings('place_receipt')(localTheboxToIdentityValue('place_receipt'));
  data.place_delivery = await getHBLMappings('place_delivery')(localTheboxToIdentityValue('place_delivery'));

  data.seawaybill = extraction?.seawaybill ? seawaybills.EXPRESS : seawaybills.ORIGINAL
  data.notify = !isSameAsConsignee(localTheboxToValue('notify')) ? await getHBLMappings('notify')(localTheboxToIdentityValue('notify')) : null;

  return data
}

export const generateHBLBoxesFromTheBox = (extraction: TheBoxExtractionData) => {
  const boxes = createDefaultHBLBoxes()
  if (_.isNil(extraction)) return boxes

  const canvas = extraction.canvas;
  const theboxData = extraction.data;

  const transform = (box: TheBoxFieldValue) => {
    if (_.isNil(box)) return null

    return _.merge(scaleFrom(box as Rect, canvas), { page: box.page, fileIndex: 0 })
  }

  boxes.bl_num = transform(theboxData.bl_num);
  boxes.shipper = transform(theboxData.shipper);
  boxes.onboard = transform(theboxData.onboard);
  boxes.freight = transform(theboxData.freight);
  boxes.consignee = transform(theboxData.consignee);
  boxes.place_receipt = transform(theboxData.place_receipt);
  boxes.place_delivery = transform(theboxData.place_delivery);
  boxes.notify = transform(theboxData.notify);

  return boxes
}

export const generateHBLFromTheBox = async (extraction: TheBoxExtractionData): Promise<HBLState> => ({
  data: await generateHBLDataFromTheBox(extraction),
  boxes: generateHBLBoxesFromTheBox(extraction)
})

export const generateTheBoxFromHBL = _.curry((extraction: TheBoxExtractionData, hbl: HBLState, mbl: MBLState) => {
  if (_.isNil(extraction)) return

  const thebox = _.cloneDeep(extraction)
  const theboxData = thebox.data
  const scaleFn = scaleAndRoundAllGenericBoxes(thebox.canvas)
  const scaledBoxesHBL = scaleFn(hbl.boxes)
  const scaledBoxesMBL = scaleFn(mbl.boxes)

  theboxData.bl_num = updateTheBoxField(theboxData.bl_num, {value: hbl.data.bl_num, box: scaledBoxesHBL.bl_num})
  theboxData.shipper = updateTheBoxIdentityField(theboxData.shipper, {value: hbl.data.shipper, box: scaledBoxesHBL.shipper})
  theboxData.onboard = updateTheBoxField(theboxData.onboard, {value: dateToISO(hbl.data.onboard), box: scaledBoxesHBL.onboard})
  theboxData.freight = updateTheBoxField(theboxData.freight, {value: hbl.data.freight, box: scaledBoxesHBL.freight})
  theboxData.consignee = updateTheBoxIdentityField(theboxData.consignee, {value: hbl.data.consignee, box: scaledBoxesHBL.consignee})
  theboxData.place_receipt = updateTheBoxIdentityField(theboxData.place_receipt, {value: hbl.data.place_receipt, box: scaledBoxesHBL.place_receipt})
  theboxData.place_delivery = updateTheBoxIdentityField(theboxData.place_delivery, {value: hbl.data.place_delivery, box: scaledBoxesHBL.place_delivery})
  theboxData.notify = updateTheBoxIdentityField(theboxData.notify, {value: hbl.data.notify, box: scaledBoxesHBL.notify})

  //Using mbl shipper as HBL carrier
  theboxData.carrier = updateTheBoxField(theboxData.carrier, {value: mbl.data.shipper?.label, box: scaledBoxesMBL.shipper})

  thebox.data = theboxData
  thebox.seawaybill = isExpress(hbl.data.seawaybill)

  return thebox
})
