import React from 'react';
import FileListItem from '../FileListItem';

type Props = {
  files: File[]
  onClick?: (file: File) => void
}

const SummaryFileList: React.FunctionComponent<Props> = ({ files, onClick }) => {
  return (
    <ul className="tw-grid tw-gap-2 tw-grid-cols-[repeat(auto-fill,minmax(200px,1fr))] tw-grid-flow-cols">
      {files.map((file) => (
        <FileListItem
          key={file.name}
          file={file} 
          onClick={() => onClick?.(file)}
        />
      ))}
    </ul>
  )
}


export default SummaryFileList
