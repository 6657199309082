import React from 'react'
import { useExpedienteState } from '../hooks/expediente'
import { HBLData } from '../types'
import { maxClientReferenceLength, maxInternalCommentsLength, maxBlNumLength } from '../utils/constants/hbl'
import { containersToSelectList } from '../utils/container'
import { isGenericFocus, onChangeGenericData } from '../utils/data'
import { getHBLRequiredMark } from '../utils/hbl'
import { getLocodesAsSelectValueList } from '../utils/masterData'
import { getConsigneesAsSelectValueList, getNotifiesAsSelectValueList, getShippersAsSelectValueList } from '../utils/players'
import { getSeawaybillList } from '../utils/seawaybill'
import AsyncSelect from './DataInput/AsyncSelect'
import Boolean from './DataInput/Boolean'
import Button from './DataInput/Button'
import Date from './DataInput/Date'
import MultiSelect from './DataInput/MultiSelect'
import Text from './DataInput/Text'
import Wrapper from './DataInput/Wrapper'
import ScrollableList from './ScrollableList'

export type Props = {
  data: HBLData,
  onChangeData: (data: HBLData) => void
  focus?: keyof HBLData,
  onFocusField?: (field: keyof HBLData) => void
}

const BookingHBLData: React.FunctionComponent<Props> = ({ data, onChangeData, focus, onFocusField }) => {

  const containers = useExpedienteState(state => state.state.containers.data)

  const onChange = onChangeGenericData(data, onChangeData)

  const isFocused = isGenericFocus(focus)

  return (
    <ScrollableList >
      <Wrapper isFocused={isFocused('bl_num')}>
        <Text
          label={`Nº de HBL ${getHBLRequiredMark('bl_num')}`}
          value={data.bl_num}
          onChange={onChange('bl_num')}
          onFocusInput={() => onFocusField?.('bl_num')}
          maxLength={maxBlNumLength}
        />
      </Wrapper>
      <Wrapper isFocused={isFocused('shipper')}>
        <AsyncSelect
          label={`Shipper ${getHBLRequiredMark('shipper')}`}
          value={data.shipper}
          onChange={onChange('shipper')}
          onFocusInput={() => onFocusField?.('shipper')}
          onQueryOptions={getShippersAsSelectValueList}
        />
      </Wrapper>
      <Wrapper isFocused={isFocused('consignee')}>
        <AsyncSelect
          label={`Consignee ${getHBLRequiredMark('consignee')}`}
          value={data.consignee}
          onChange={onChange('consignee')}
          onFocusInput={() => onFocusField?.('consignee')}
          onQueryOptions={getConsigneesAsSelectValueList}
        />
      </Wrapper>
      <Wrapper isFocused={isFocused('notify')}>
        <AsyncSelect
          label={`Notify ${getHBLRequiredMark('notify')}`}
          value={data.notify}
          onChange={onChange('notify')}
          onFocusInput={() => onFocusField?.('notify')}
          onQueryOptions={getNotifiesAsSelectValueList}
        />
      </Wrapper>
      <Wrapper isFocused={isFocused('client_reference')}>
        <Text
          maxLength={maxClientReferenceLength}
          label={`Referencia Cliente ${getHBLRequiredMark('client_reference')}`}
          value={data.client_reference}
          onChange={onChange('client_reference')}
          onFocusInput={() => onFocusField?.('client_reference')}
        />
      </Wrapper>
      <Wrapper isFocused={isFocused('place_receipt')}>
        <AsyncSelect
          label={`Origen ${getHBLRequiredMark('place_receipt')}`}
          value={data.place_receipt}
          onChange={onChange('place_receipt')}
          onFocusInput={() => onFocusField?.('place_receipt')}
          onQueryOptions={getLocodesAsSelectValueList}
        />
      </Wrapper>
      <Wrapper isFocused={isFocused('place_delivery')}>
        <AsyncSelect
          label={`Destino final ${getHBLRequiredMark('place_delivery')}`}
          value={data.place_delivery}
          onChange={onChange('place_delivery')}
          onFocusInput={() => onFocusField?.('place_delivery')}
          onQueryOptions={getLocodesAsSelectValueList}
        />
      </Wrapper>
      <Wrapper isFocused={isFocused('freight')}>
        <Text
          label={`Flete ${getHBLRequiredMark('freight')}`}
          value={data.freight}
          onChange={onChange('freight')}
          onFocusInput={() => onFocusField?.('freight')}
        />
      </Wrapper>
      <Wrapper isFocused={isFocused('seawaybill')}>
        <Button
          label={`Emisión B/L  ${getHBLRequiredMark('seawaybill')}`}
          value={data.seawaybill}
          options={getSeawaybillList()}
          onChange={onChange('seawaybill')}
          onFocusInput={() => onFocusField?.('seawaybill')}
        />
      </Wrapper>
      <Wrapper isFocused={isFocused('on_hold')}>
        <Boolean
          label={`On hold  ${getHBLRequiredMark('on_hold')}`}
          value={data.on_hold}
          onChange={onChange('on_hold')}
          onFocusInput={() => onFocusField?.('on_hold')}
        />
      </Wrapper>
      <Wrapper isFocused={isFocused('onboard')}>
        <Date
          label={`ETD del B/L ${getHBLRequiredMark('onboard')}`}
          value={data.onboard}
          onChange={onChange('onboard')}
          onFocusInput={() => onFocusField?.('onboard')}
        />
      </Wrapper>
      <Wrapper isFocused={isFocused('containers')}>
        <MultiSelect
          label={`Contenedores ${getHBLRequiredMark('containers')}`}
          value={data.containers}
          onChange={onChange('containers')}
          onFocusInput={() => onFocusField?.('containers')}
          options={containersToSelectList(containers)}
          minChars={0}
        />
      </Wrapper>
      <Wrapper isFocused={isFocused('internal_comments')}>
        <Text
          label={`Notas internas ${getHBLRequiredMark('internal_comments')}`}
          value={data.internal_comments}
          onChange={onChange('internal_comments')}
          onFocusInput={() => onFocusField?.('internal_comments')}
          large={true}
          maxLength={maxInternalCommentsLength}
        />
      </Wrapper>
    </ScrollableList>
  )
}

export default BookingHBLData
