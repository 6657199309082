import React from 'react';
import _ from 'lodash/fp';
import { Label } from '@blueprintjs/core';
import { NumberValue } from '../../types';
import NumberInput from './NumberInput';

type Props = {
  label: React.ReactNode;
  value: NumberValue;
  onChange: (value: NumberValue) => void;
  onFocusInput?: () => void;
  isInteger?: boolean
};

const Number: React.FunctionComponent<Props> = ({
  label,
  value,
  onChange,
  onFocusInput=_.noop,
  isInteger = false
}: Props) => {
  return (
    <Label className="tw-font-medium" style={{ marginBottom: 0 }}>
      {label}
      <NumberInput value={value} onChange={onChange} onFocus={onFocusInput} isInteger={isInteger}/> 
    </Label>
  );
};

export default Number;
