import _ from "lodash/fp";
import { useRef, useState, useCallback, MutableRefObject } from "react";
import { Point, Rect } from "../types";
import { getBoundingRect, getRectFromPoints } from "../utils/coords";
import { getPageFromElement, getRangeRects } from "../utils/pdf";
import { useEventListener } from "./utils";

export const useTextSelection = (callback?: (text: string, rect: Rect, element?: HTMLElement) => void) => {
  const range = useRef<Range>();
  const isCollapsed = useRef<boolean>();
  const [text, setText] = useState<string>('');
  const [rect, setRect] = useState<Rect>()

  const onSelectionChange = useCallback(() => {
    const selection = window.getSelection();
    if (_.isNil(selection)) return;

    if (selection.isCollapsed) {
      isCollapsed.current = true;
      return;
    }

    const selectionRange = selection.getRangeAt(0);
    if (_.isNil(selectionRange)) return;

    isCollapsed.current = false;
    range.current = selectionRange;
  }, [])

  const onTextSelectionEnd = useCallback(() => {
    const selectedRange = range.current;
    if (_.isNil(selectedRange) || isCollapsed.current) return;

    const { parentElement } = selectedRange.startContainer;

    if (!(parentElement instanceof HTMLElement)) return;

    const page = getPageFromElement(parentElement)
    if(_.isNil(page)) return 

    const rects = getRangeRects(selectedRange, page.node);

    if (_.isEmpty(rects)) return;

    const rect = getBoundingRect(rects)
    const text = selectedRange.toString()

    setText(text)
    setRect(rect)

    callback?.(text, rect!, parentElement)

  }, [callback])

  useEventListener('selectionchange', onSelectionChange, document)
  useEventListener('mouseup', onTextSelectionEnd, document)


  const reset = () => {
    window.getSelection()?.empty();
    range.current = undefined
    isCollapsed.current = false
  }

  return {
    reset,
    text,
    rect
  }
}

type MouseOptions = {
  onDragStart: () => void,
  onDragEnd: () => void,
  shouldStart: (e: MouseEvent) => boolean
  minSize?: number
}

export const useMouseSelection = (
  container: MutableRefObject<HTMLDivElement | null>,
  callback?: (rect: Rect, element?: HTMLElement) => void,
  options?: MouseOptions,
) => {

  const start = useRef<Point>();
  const selectedElement = useRef<HTMLElement>();
  const [end, setEnd] = useState<Point>();

  const locked = useRef<boolean>(false);

  const reset = useCallback(() => {
    options?.onDragEnd();
    start.current = undefined;
    setEnd(undefined);
    locked.current = false;
  }, [options]);

  const containerCoords = useCallback((pageX: number, pageY: number): Point | undefined => {
    if (_.isNil(container.current)) return

    const containerBoundingRect = container.current.getBoundingClientRect();
    return {
      x: pageX - containerBoundingRect.left + container.current.scrollLeft,
      y: pageY - containerBoundingRect.top + container.current.scrollTop,
    };
  }, [container])

  const mouseMove = useCallback((event: MouseEvent) => {
    if (_.isNil(start.current) || locked.current) return;

    setEnd(containerCoords(event.pageX, event.pageY))
  }, [containerCoords]);

  const mouseDown = useCallback((event: MouseEvent) => {
    if (!options?.shouldStart(event)) {
      reset();
      return;
    }

    const startTarget = event.target;
    if (!(startTarget instanceof HTMLElement)) return;

    const eventStart = containerCoords(event.pageX, event.pageY);
    if (_.isNil(eventStart)) return

    options?.onDragStart();
    start.current = eventStart;
    selectedElement.current = startTarget;
    setEnd(undefined);
    locked.current = false;

  }, [containerCoords, options, reset])

  const isCollapsed = useCallback((rect: Rect) => rect.width < _.toInteger(options?.minSize) && rect.height < _.toInteger(options?.minSize), [options?.minSize])

  const mouseUp = useCallback((event: MouseEvent): void => {
    if (_.isNil(start.current)) return;

    const eventEnd = containerCoords(event.pageX, event.pageY);
    if (_.isNil(eventEnd)) return

    const boundingRect = getRectFromPoints(start.current, eventEnd);

    //If the user has dragged out of the container
    if (
      !(event.target instanceof HTMLElement) ||
      !container.current?.contains(event.target) ||
      isCollapsed(boundingRect)
    ) {
      reset();
      return;
    }

    setEnd(eventEnd);
    locked.current = true;
    
    if (_.isNil(start.current) || _.isNil(end)) return;

    if (!(event.target instanceof HTMLElement)) return
    
    if (!(selectedElement.current instanceof HTMLElement)) return

    callback?.(boundingRect, selectedElement.current);
    options?.onDragEnd()
  }, [containerCoords, end, callback, reset, container, options, isCollapsed])

  useEventListener('mousedown', mouseDown, container.current)
  useEventListener('mousemove', mouseMove, container.current)
  useEventListener('mouseup', mouseUp, document.body)

  return {
    container,
    rect: !_.isNil(start.current) && !_.isNil(end) ? getRectFromPoints(start.current, end) : undefined
  }
}
