import { action, computed, thunk } from "easy-peasy";
import _ from "lodash/fp";
import { MBLState } from "../../types";
import { showInfoAlert, showSuccessAlert, showWarningAlert } from "../../utils/alert";
import { mergeGenericData } from "../../utils/data";
import { toSelectValueList } from "../../utils/docTypes";
import { fileToDocument, getHBLDocument, sortDocumentsByHBL } from "../../utils/document";
import { generateHBLFromTheBox, generateTheBoxFromHBL, isHBLDataComplete } from "../../utils/hbl";
import { generatePackageListFromTheBox, generateTheBoxFromPackages, isPackageDataComplete } from "../../utils/package";
import { groupFilesByDoc, groupPagesByBookingIndex } from "../../utils/page";
import { findById } from "../../utils/select";
import { Actions, Model, State, Booking } from "../types/booking";


const generateDataTheBox = (booking: Booking, mbl: MBLState) => {
  const bookingThebox = booking.extracted
  if (_.isNil(bookingThebox)) return

  const hbl = generateTheBoxFromHBL(bookingThebox, booking.state.hbl, mbl)
  const packages = generateTheBoxFromPackages(bookingThebox, booking.state.packages)
  const data = _.merge(hbl?.data, _.pick(['lg_weight', 'lg_volume', 'lg_hscodes', 'marks', 'description'], packages?.data))
  return _.set('data', data, hbl!)
}

const modelState: State = {
  list: [],
  currentIndex: 0,
  current: computed([
    state => state.currentIndex,
    state => state.list
  ], _.get),
  files: computed([
    state => _.get('docs', state.current),
  ], _.flow(_.map('file'), _.compact)),
  isReady: computed([
    state => state.current
  ], _.flow(_.get('state'), _.negate(_.isNil))),
  isCurrentHBLComplete: computed([
    state => state.current
  ], _.flow(_.get(['state', 'hbl', 'data']), isHBLDataComplete)),
  isCurrentPackagesComplete: computed([
    state => state.current
  ], _.flow(_.get(['state', 'packages']), _.map('data'), _.all(isPackageDataComplete))),
};

const modelActions: Actions = {
  clear: thunk((actions) => {
    actions.setList([])
    actions.setCurrentIndex(0)
  }),
  setCurrentIndex: action((state, payload) => {
    state.currentIndex = payload
  }),
  setList: action((state, payload) => {
    state.list = payload
  }),
  setDocs: action((state, payload) => {
    state.list[payload.index] = _.set('docs', payload.data, state.list[payload.index])
  }),
  setExtracted: action((state, payload) => {
    state.list[payload.index] = _.set('extracted', payload.data, state.list[payload.index])
  }),
  mergePages: thunk(async (actions, payload, { injections, getStoreState }) => {
    const { fileService } = injections
    const state = getStoreState()

    const groupedByIndex = groupPagesByBookingIndex(payload)
    const sortedIndexes = _.sortBy(_.toInteger, _.keys(groupedByIndex))
    const options = toSelectValueList(state.documentType.documents)
    const findDocType = findById(options)
    const toDocument = fileToDocument(options)

    const promises = _.map(async (index) => {
      const booking = _.get(index, groupedByIndex)
      const grouped = groupFilesByDoc(booking)

      const promises = _.map((key) => {
        const docType = findDocType(key)
        const files = _.get(key, grouped)
        return fileService.mergeFiles(_.toString(docType?.label), files)
      }, _.keys(grouped))

      return await Promise.all(promises)
    }, sortedIndexes)

    const bookings = await Promise.all(promises)

    return bookings.map((booking, index) => {
      const data = sortDocumentsByHBL(_.map(toDocument, booking))
      actions.setDocs({
        index,
        data
      })
      return data
    })
  }),
  extractData: thunk(async (actions, payload, { injections, getStoreState }) => {
    const { theboxService } = injections
    const tenant = getStoreState().session.tenant

    showInfoAlert("Se están extrayendo los HBL en segundo plano")

    const notifier = () => {
      let total = 0
      let current = 0
      return () => {
        total++
        return () => {
          current++
          if (total - current === 0) {
            return showSuccessAlert(`Todos los HBL extraidos`)
          }
          return showInfoAlert(`Queda${total - current > 1 ? 'n' : ''} ${total - current} HBL por extraer`)
        }
      }
    }
    const start = notifier()
    const promises = payload.map(async (docs, index) => {
      const hbl = getHBLDocument(docs)
      if (_.isNil(hbl?.file)) return
      const end = start()

      const data = await theboxService.extract(hbl!.file, tenant?.id);

      actions.setExtracted({ index, data })
      await actions.generateData({ index, data })
      if(_.isUndefined(data)) showWarningAlert("No se han podido extraer datos")
      end()
    })
    Promise.all(promises)
  }),
  generateData: thunk(async (actions, payload) => {
    const hbl = await generateHBLFromTheBox(payload.data)
    const packages = await generatePackageListFromTheBox(payload.data)
    actions.setHBLData({ index: payload.index, data: hbl.data })
    actions.setHBLBoxes({ index: payload.index, data: hbl.boxes })
    actions.setPackages({ index: payload.index, data: packages })
  }),
  setHBLData: action((state, payload) => {
    state.list[payload.index] = _.set(['state', 'hbl', 'data'], payload.data, state.list[payload.index])
  }),
  setHBLBoxes: action((state, payload) => {
    state.list[payload.index] = _.set(['state', 'hbl', 'boxes'], payload.data, state.list[payload.index])
  }),
  setPackageData: action((state, payload) => {
    state.list[payload.index] = _.set(['state', 'packages', payload.data.index, 'data'], payload.data.data, state.list[payload.index])
  }),
  setPackageBoxes: action((state, payload) => {
    state.list[payload.index] = _.set(['state', 'packages', payload.data.index, 'boxes'], payload.data.data, state.list[payload.index])
  }),
  mergeHBLData: action((state, payload) => {
    const merged = mergeGenericData(_.get(['state', 'hbl', 'data'], state.list[payload.index]), payload.data)
    state.list[payload.index] = _.set(['state', 'hbl', 'data'], merged, state.list[payload.index])
  }),
  setCurrentHBLData: thunk((actions, payload, { getState }) => {
    const currentIndex = getState().currentIndex
    actions.setHBLData({ index: currentIndex, data: payload })
  }),
  setCurrentHBLBoxes: thunk((actions, payload, { getState }) => {
    const currentIndex = getState().currentIndex
    actions.setHBLBoxes({ index: currentIndex, data: payload })
  }),
  setCurrentPackageData: thunk((actions, payload, { getState }) => {
    const currentIndex = getState().currentIndex
    actions.setPackageData({ index: currentIndex, data: payload })
  }),
  setCurrentPackageBoxes: thunk((actions, payload, { getState }) => {
    const currentIndex = getState().currentIndex
    actions.setPackageBoxes({ index: currentIndex, data: payload })
  }),
  setPackages: action((state, payload) => {
    state.list[payload.index] = _.set(['state', 'packages'], payload.data, state.list[payload.index])
  }),
  setCurrentPackages: thunk((actions, payload, { getState }) => {
    const currentIndex = getState().currentIndex
    actions.setPackages({ index: currentIndex, data: payload })
  }),
  nextBooking: thunk((actions, p, { getState }) => {
    const currentIndex = getState().currentIndex
    if (currentIndex + 1 < _.size(getState().list)) {
      actions.setCurrentIndex(currentIndex + 1)
      return true
    }
    return false
  }),
  sendCurrentTheBox: thunk(async (a, p, { injections, getState, getStoreState }) => {
    const state = getState()
    const mbl = getStoreState().expediente.state.mbl
    const { theboxService } = injections
    const booking = state.current

    const data = generateDataTheBox(booking, mbl)
    if (_.isNil(data)) return

    await theboxService.validate(data)
  }),
  sendTheBox: thunk(async (a, p, { injections, getState, getStoreState }) => {
    const state = getState()
    const mbl = getStoreState().expediente.state.mbl
    const { theboxService } = injections
    const promises = _.map(async (item) => {
      const data = generateDataTheBox(item, mbl)
      if (_.isNil(data)) return

      await theboxService.validate(data)
    }, state.list)

    await Promise.all(promises)
  })
};


const model: Model = {
  ...modelState,
  ...modelActions,
};

export default model
