import React from 'react';
import _ from 'lodash/fp';
import { Label } from '@blueprintjs/core';
import { SelectValue } from '../../types';
import ButtonInput from './ButtonInput';
import AsyncSelectInput from './AsyncSelectInput';

type Props = {
  label: React.ReactNode;
  value: SelectValue;
  defaultOptions?: SelectValue[];
  onChange: (value: SelectValue) => void;
  onQueryOptions: (query: string) => Promise<SelectValue[]>;
  onFocusInput?: () => void;
  minChars?: number
};

const AsyncSelect: React.FunctionComponent<Props> = ({
  label,
  value,
  onChange,
  defaultOptions,
  onFocusInput = _.noop,
  onQueryOptions,
  minChars
}: Props) => {
  return (
    <Label className="tw-font-medium" style={{ marginBottom: 0 }}>
      {label}
      <div className="tw-mt-1">
        <AsyncSelectInput
          value={value}
          onChange={onChange}
          onFocusInput={onFocusInput}
          onQueryOptions={onQueryOptions}
          minChars={minChars}
        />
      </div>
      {!_.isEmpty(defaultOptions) && (
        <div className="tw-mt-2">
          <ButtonInput 
            options={defaultOptions!} 
            value={value} 
            onChange={onChange} 
          />
        </div>
      )}
    </Label>
  );
};

export default AsyncSelect;
