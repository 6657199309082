import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import React from 'react';

type Props = {
  message: string
}

const AlertContainer: React.FunctionComponent<Props> = ({ message }) => {
  return (
    <div className="tw-p-4 tw-h-full tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center">
      <span className="tw-text-center tw-text-yellow-600 tw-text-2xl tw-font-medium tw-flex tw-flex-col tw-items-center tw-space-y-4">
        <span>{message}</span>
        <Icon icon={IconNames.ISSUE} size={32} />
      </span>
    </div>
  );
};

export default AlertContainer;
