import React from 'react';

type Props = React.PropsWithChildren<{}>;

const SummaryBox: React.FunctionComponent<Props> = ({ children }) => {
  return (
    <div className="tw-grid tw-grid-rows-[auto,1fr] tw-mb-4">
      {children}
    </div>
  )
}


export default SummaryBox
