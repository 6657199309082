import _ from "lodash/fp";
import * as pdfjs from 'pdfjs-dist';
// @ts-ignore
import {PDFViewer, EventBus, SimpleLinkService, NullL10n} from 'pdfjs-dist/web/pdf_viewer';
import React, { createContext, createRef, MutableRefObject, useCallback, useContext, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { createWorker } from 'tesseract.js';
import { BoxValue, FileValue, PDFPage, Rect } from "../types";
import { rotate, scaleFrom, scaleTo } from "../utils/coords";
import { getAreaImage } from "../utils/image";
import { findOrCreateContainerLayer, getPageFromElement, rectToBox, toggleTextSelection } from "../utils/pdf";
import { useMouseSelection, useTextSelection } from "./selection";
import { useEffectOnce, useEventListener } from "./utils";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.js`;

export const PDFContext = createContext<{
  pdfViewer: PDFViewer | undefined | null,
  pdfContainer: MutableRefObject<HTMLDivElement | null>
}>({
  pdfViewer: undefined,
  pdfContainer: createRef()
})

export const usePDFContext = () => {
  const pdfContext = useContext(PDFContext)
  if (_.isNil(pdfContext)) {
    throw Error("Use this hook inside PDFContext.Provider")
  }
  return pdfContext
}

const readFile = async (file: File) => {
  return new Promise((resolve: (result: ArrayBuffer) => void, reject: () => void) => {
    const reader = new FileReader()

    reader.onload = () => resolve(reader.result as ArrayBuffer)
    reader.onerror = reject
    reader.readAsArrayBuffer(file)
  })
}

const getAsByteArray = async (file: File) => {
  return new Uint8Array(await readFile(file))
}

const createEventBus = () => {
  const eventBus = new EventBus()
  const emit = (eventName: string) => {
    eventBus.on(eventName, (a: any) => {
      const event = new CustomEvent(eventName, { detail: a });
      document.dispatchEvent(event);
    })
  }
  emit('textlayerrendered')
  emit('pagesinit')
  return eventBus
}

const eventBus = createEventBus()
const linkService = new SimpleLinkService()

export const usePDF = (src: FileValue) => {
  const [viewer, setViewer] = useState<PDFViewer>();
  const container = useRef<HTMLDivElement>(null);
  const [document, setDocument] = useState<Awaited<ReturnType<typeof pdfjs.getDocument>['promise']> | null>(null);
  const [error, setError] = useState<Error | null>(null)

  const clear = () => {
    setError(null)
    setDocument(null);
  }

  useEffect(() => {
    (async () => {
      if (_.isNil(src)) {
        setError(new Error("No hay ningun documento"))
        return
      }

      clear()
      try {
        const loadingTask = pdfjs.getDocument({
          data: await getAsByteArray(src),
          cMapPacked: true,
          cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`
        });
        const doc = await loadingTask.promise;
        setDocument(doc)
      } catch (err) {
        setError(err as Error)
      }
    })()
  }, [src]);

  useEffect(() => {
    if (_.isNil(document) || _.isNil(container.current)) return

    const viewer = new PDFViewer({
      container: container.current,
      textLayerMode: 2, // 0 (disabled) 1 (enabled) 2 (enabled-enhanced)
      removePageBorders: true,
      eventBus: eventBus,
      linkService:  linkService,
      renderer: 'canvas',
      l10n: NullL10n
    });
    setViewer(viewer)

    if (_.isNil(viewer)) return;

    viewer.setDocument(document)
  }, [document]);


  return {
    container,
    viewer,
    error
  }
}

export const usePDFActions = () => {
  const { pdfViewer } = usePDFContext()

  const zoom = useCallback((z: number) => {
    if (_.isNil(pdfViewer)) return

    const scale = pdfViewer.currentScale + z;
    if (0.7 < scale && scale < 2.5) {
      pdfViewer.currentScale = scale;
    }
  }, [pdfViewer])

  const rotate = useCallback((deg: number) => {
    if (_.isNil(pdfViewer)) return

    pdfViewer.pagesRotation += deg;
  }, [pdfViewer])

  const fit = useCallback((): void => {
    if (_.isNil(pdfViewer)) return

    pdfViewer.currentScaleValue = 'auto';
  }, [pdfViewer])

  return {
    rotate,
    zoom,
    fit
  }
}


const initializeWorker = () => {
  const worker = createWorker()
  worker.load().then(() =>
    worker.loadLanguage().then(() =>
      worker.initialize()
    )
  )
  return worker
}

const worker = initializeWorker()


export const usePDFSelection = (onSelection?: (text: string, box: BoxValue) => void) => {
  const { pdfViewer, pdfContainer: container } = usePDFContext()
  const [processing, setProcessing] = useState(false)

  const isActive = _.isFunction(onSelection)

  const showTextLayer = useCallback(() => toggleTextSelection(isActive), [isActive])
  const hideTextLayer = () => toggleTextSelection(false)

  const adjustToViewer = useCallback((rect: Rect, page: PDFPage) => {
    const pageWidth = page.node.clientWidth;
    const pageHeight = page.node.clientHeight;
    if (_.isNil(pdfViewer)) return rect;

    const { pagesRotation } = pdfViewer;

    const scaled = scaleFrom(rect, {
      width: pageWidth,
      height: pageHeight,
    });

    const rotated = rotate(-pagesRotation, scaled);
    return rotated
  }, [pdfViewer])

  const onTextSelection = useCallback((text: string, rect: Rect, element?: HTMLElement) => {
    if (_.isNil(element)) return

    const page = getPageFromElement(element);
    if (_.isNil(page)) return

    onSelection?.(text, rectToBox(adjustToViewer(rect, page), page))
  }, [onSelection, adjustToViewer])

  const { reset: resetTextSelection, text, rect: textRect } = useTextSelection(isActive ? onTextSelection : undefined)

  const onAreaSelection = async (rect: Rect, element?: HTMLElement) => {
    if (_.isNil(element)) return
    
    const page = getPageFromElement(element);
    if (_.isNil(page)) return
    
    const { canvas } = pdfViewer?.getPageView(page.number - 1);
    if (_.isNil(canvas)) return

    const normalizedRect = {
      width: rect.width,
      height: rect.height,
      top: rect.top - page.node.offsetTop,
      left: rect.left - page.node.offsetLeft,
    }

    const image = getAreaImage(canvas, normalizedRect)

    setProcessing(true)
    const { data } = await worker.recognize(image);

    onSelection?.(data.text, rectToBox(adjustToViewer(normalizedRect, page), page))
    setProcessing(false)
  };

  const { rect: mouseRect } = useMouseSelection(
    container,
    isActive ? onAreaSelection : undefined,
    {
      onDragEnd: showTextLayer,
      onDragStart: () => {
        hideTextLayer()
        resetTextSelection()
      },
      shouldStart: (event) => (
        event.altKey &&
        event.target instanceof HTMLElement &&
        Boolean(event.target.closest('.page'))
      ),
      minSize: 15
    })

  useEffectOnce(showTextLayer)

  return {
    processing,
    text,
    textRect,
    mouseRect
  }
}

const findOrCreateBoxLayer = (page: HTMLElement) => {
  return findOrCreateContainerLayer(page, 'PdfHeatmap__heatmap-layer');
};

export const usePDFBoxes = (boxes: BoxValue[], render: (box: BoxValue) => React.ReactElement) => {
  const { pdfViewer } = usePDFContext()

  const adjustToViewer = useCallback((box: BoxValue) => {
    if (_.isNil(pdfViewer)) return box;
    const { viewport } = pdfViewer?.getPageView(_.toInteger(box?.page) - 1)

    const { pagesRotation } = pdfViewer;
    const rotated = rotate(pagesRotation, box as Rect)

    const scaled = scaleTo(rotated, {
      width: viewport.width,
      height: viewport.height,
    });

    return _.merge(box, scaled)
  }, [pdfViewer])

  const renderBoxes = (boxes: BoxValue[], pageNum: number) => {
    const byPage = _.filter({page: pageNum}, boxes);

    const page = pdfViewer?.getPageView(pageNum - 1)?.div
    if (_.isNil(page)) return

    const layer = findOrCreateBoxLayer(page)
    if (_.isNil(layer)) return

    const renderBox = (box: BoxValue) => React.cloneElement(render(box), { key: `${box!.page}_${box!.top}_${box!.left}` })

    ReactDOM.render(
      _.pipe(_.compact, _.map(adjustToViewer), _.map(renderBox))(byPage),
      layer
    )
  }

  useEventListener('textlayerrendered',
    (event) => renderBoxes(boxes, event.detail.pageNumber),
    document)

  useEffect(() => {
    const totalPages = _.toInteger(pdfViewer?.pagesCount)
    if(totalPages < 1) return 

    _.map((p) => renderBoxes(boxes, p), _.range(1, totalPages + 1))
    // eslint-disable-next-line
  }, [boxes, pdfViewer])
}
