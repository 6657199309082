import _ from "lodash/fp";
import { Page } from "../types";
import { showErrorAlert } from "./alert";
import { isBooking, isExpediente, isHBI, isHBL, isMBI, isMBL, isSHB, isSMB } from "./docTypes";

export const createDefaultPage = (): Page => ({
  file: null,
  type: null,
  doc: null,
  bookingIndex: null
})

export const isMBIPage = (page: Page) => isMBI(page.doc)
export const isSMBPage = (page: Page) => isSMB(page.doc)
export const isMBLPage = (page: Page) => isMBL(page.doc)

export const isHBIPage = (page: Page) => isHBI(page.doc)
export const isSHBPage = (page: Page) => isSHB(page.doc)
export const isHBLPage = (page: Page) => isHBL(page.doc)

export const isPageExpediente = (page: Page) => isExpediente(page.type)
export const isPageBooking = (page: Page) => isBooking(page.type)
export const hasPageType = (page: Page) => !_.isEmpty(page.type)
export const hasPageDoc = (page: Page) => !_.isEmpty(page.doc)
export const hasPageBookingIndex = _.curry((page: Page) => !_.isNil(page.bookingIndex))

export const setPageFile = _.curry((file: Page['file'], page: Page): Page => _.set('file', file, page))
export const setPageType = _.curry((type: Page['type'], page: Page): Page => _.set('type', type, page))
export const setPageDoc = _.curry((doc: Page['doc'], page: Page): Page => _.set('doc', doc, page))
export const setPageBookingIndex = _.curry((index: Page['bookingIndex'], page: Page): Page => _.set('bookingIndex', index, page))
export const isPageComplete = (page: Page) => {
  if (!hasPageType(page)) return false

  let conditions: boolean[] = []
  if (isPageExpediente(page)) {
    conditions = [hasPageDoc(page)]
  } else if (isPageBooking(page)) {
    conditions = [hasPageDoc(page), hasPageBookingIndex(page)]
  }

  return !_.isEmpty(conditions) && _.all(_.identity, conditions)
}
export const filterBookingPages = (pages: Page[]) => _.filter(isPageBooking, pages)
export const filterExpedientePages = (pages: Page[]) => _.filter(isPageExpediente, pages)

export const groupPagesByDoc = (pages: Page[]) => _.groupBy('doc.id', pages)
export const groupPagesByBookingIndex = (pages: Page[]) => _.groupBy('bookingIndex', pages)

export const groupFilesByDoc = (pages: Page[]) => (
  _.pipe(
    groupPagesByDoc,
    _.mapValues(_.flow(_.map('file'), _.compact))
  )(pages)
)

export const maybeAlertMissingBooking = (pages: Page[]) => {
  const bookingPages = filterBookingPages(pages)
  if (_.isEmpty(bookingPages)) {
    showErrorAlert("Debe de existir al menos un booking")
    return false
  }

  const groupedByIndex = groupPagesByBookingIndex(bookingPages)
  const checkedMinOneHBL = _.mapValues((pages) => _.any(isHBLPage, pages), groupedByIndex)

  const alerts = _.mapKeys((index) => {
    if(_.get(index, checkedMinOneHBL)) return true

    showErrorAlert(`El booking ${_.toInteger(index) + 1} debe de tener al menos un HBL`)

    return false
  },checkedMinOneHBL)
  return _.all(_.identity, alerts)
}
