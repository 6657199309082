import React from 'react';
import _ from 'lodash/fp';
import Card from './Card';
import { preventParentTrigger } from '../utils/events';
import FocusContainer from './FocusContainer';

type Props = React.PropsWithChildren<{
  onClick?: () => void;
  isSelected?: boolean;
  className?: string;
}>;

const ListItem: React.FunctionComponent<Props> = ({
  onClick = _.noop,
  isSelected = false,
  children,
  className = '',
}: Props) => {
  return (
    <li onClick={preventParentTrigger(onClick)} className="tw-overflow-hidden">
      <Card className={`tw-duration-300 tw-transition-colors tw-bg-white ${className}`}>
        <FocusContainer className="hover:tw-bg-gray-200" isFocused={isSelected}>
          {children}
        </FocusContainer>
      </Card>
    </li>
  );
};

export default ListItem;
