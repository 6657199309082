import React from 'react';
import _ from 'lodash/fp';
import { Switch, Label } from '@blueprintjs/core';
import { BooleanValue } from '../../types';

type Props = {
  label: React.ReactNode;
  value: BooleanValue;
  onChange: (value: BooleanValue) => void;
  onFocusInput?: () => void;
};

const Boolean: React.FunctionComponent<Props> = ({
  label,
  value,
  onChange,
  onFocusInput = _.noop,
}: Props) => {
  return (
    <Label className="tw-font-medium" style={{ marginBottom: 0 }}>
      <div className="tw-flex tw-items-baseline tw-gap-2">
        {label}
        <Switch
          onFocus={onFocusInput}
          checked={!_.isNil(value) ? value : undefined}
          onChange={() => onChange(!value)}
        />
      </div>
    </Label>
  );
};

export default Boolean;
