import React, { useCallback } from 'react';
import { usePDFBoxes } from '../hooks/pdf';
import { BoxValue, Rect } from '../types';
import PDFRect from './PDFRect';

type Props = React.PropsWithChildren<{
  boxes: BoxValue[]
}>

const PDFBoxLayer: React.FunctionComponent<Props> = ({
  children,
  boxes
}) => {
  const renderBox = useCallback((box: BoxValue) => (
    <PDFRect
      className="tw-pointer-events-none tw-cursor-default"
      rect={box as Rect}
    />
  ), [])

  usePDFBoxes(boxes, renderBox)

  return (
    <>
      {children}
    </>
  );
}

export default PDFBoxLayer;
