import { action, computed } from "easy-peasy";
import { mergeGenericData } from "../../utils/data";
import { createDefaultMBL, isMBLDataComplete, isMBLSimpleDataComplete } from "../../utils/mbl";
import { Actions, Model, State } from "../types/mbl";

const createDefaultState = () => ({
  ...createDefaultMBL(),
  isComplete: false
})

const modelState: State = {
  ...createDefaultState(),
  isComplete: computed([
    state => state.data,
    (_, storeState) => storeState.expediente.hasMBL
  ], (data, hasMbl) => ( hasMbl ? isMBLDataComplete(data) : isMBLSimpleDataComplete(data)))
};

const modelActions: Actions = {
  clear: action((_) => createDefaultState()),
  setData: action((state, payload) => {
    state.data = payload
  }),
  mergeData: action((state, payload) => {
    state.data = mergeGenericData(state.data, payload)
  }),
  setBoxes: action((state, payload) => {
    state.boxes = payload
  })
};

const model: Model = {
  ...modelState,
  ...modelActions,
};

export default model
