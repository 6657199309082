import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSessionState } from '../hooks/session';
import routes from '../utils/constants/routes';
import { getPath } from '../utils/routes';


type Props = React.PropsWithChildren<{}>

const Authenticated: React.FunctionComponent<Props> = ({ children }) => {
  const isLoggedIn = useSessionState(state => state.isLoggedIn);
  const location = useLocation()

  if (!isLoggedIn) {
    // not logged in so redirect to login page with the return url
    return (
      <Navigate
        to={getPath(routes.login)!}
        state={{ from: location }}
        replace
      />
    )
  }

// authorised so return component
return (
  <>
    {children}
  </>
)
};

export default Authenticated;
