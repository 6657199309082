import { action, computed, thunk } from 'easy-peasy';
import _ from 'lodash/fp';
import { filterMainDocuments, filterOtherDocuments } from '../../utils/docTypes';
import { Actions, Model, State } from '../types/documentType';


const modelState: State = {
  documents: [],
  orderedDocuments: computed([
    state => state.documents
  ], (docs) => _.sortBy('order', docs)),
  mainDocuments: computed([
    state => state.orderedDocuments,
  ], filterMainDocuments),
  otherDocuments: computed([
    state => state.documents
  ], filterOtherDocuments)
};

const modelActions: Actions = {
  clear: thunk((actions) => {
    actions.setDocuments([]);
  }),
  setDocuments: action((state, payload) => {
    state.documents = payload;
  }),
};

const model: Model = {
  ...modelState,
  ...modelActions,
};

export default model;
