import _ from "lodash/fp";
import {
  ContainerData,
  Document,
  HBLData,
  MBLData,
  PackageData,
  StringValue,
} from "../types";
import {
  BillOfLadingDocument,
  BillOfLadingHBL,
  BillOfLadingHBLPackage,
  BillOfLadingMBL,
  BillOfLadingMBLContainer,
} from "../types/api/bill_of_lading";
import {
  maxBlNumLength,
  maxClientReferenceLength,
  maxInternalCommentsLength,
} from "./constants/hbl";
import { maxFileNotesLength } from "./constants/mbl";
import { maxAditionalHSCODELength } from "./constants/package";
import { isContType40REEFERCONTAINER } from "./container";
import { dateToISO } from "./date";
import { fileToBase64 } from "./file";
import { isNO } from "./imo";

export const createBillOfLadingMBLContainer = (
  container: ContainerData
): BillOfLadingMBLContainer => {
  const billofladingContainer: BillOfLadingMBLContainer = {
    cont_id: container.cont_id,
    cont_type: container.cont_type?.label,
    packages: container.packages,
    seal_no: container.seal_no,
    weight: container.weight,
    volume: container.volume,
  };
  billofladingContainer.non_operational = isContType40REEFERCONTAINER(
    container.cont_type
  )
    ? !!container.non_operational
    : undefined;
  return billofladingContainer;
};

export const createBillOfLadingMBL = ({
  mbl,
  containers,
}: {
  mbl: MBLData;
  containers: ContainerData[];
}): BillOfLadingMBL => {
  const billofladingMBL: BillOfLadingMBL = {
    bl_num: _.pipe(_.toString, _.trim)(mbl.bl_num),
    vessel: mbl.vessel,
    carrier: mbl.carrier?.label,
    freight: mbl.freight,
    onboard: dateToISO(mbl.onboard),
    shipper: mbl.shipper?.label,
    voyage_no: mbl.voyage_no,
    seawaybill: mbl.seawaybill?.label,
    service_num: mbl.service_num,
    port_discharge: mbl.port_discharge?.label,
    port_loading: mbl.port_loading?.label,
    cont_type: mbl.cont_type?.label,
    file_notes: _.pipe(
      _.toString,
      _.trim,
      _.truncate({ length: maxFileNotesLength, omission: "" })
    )(mbl.file_notes),
    containers: _.map(createBillOfLadingMBLContainer, containers),
  };

  billofladingMBL.non_operational = isContType40REEFERCONTAINER(mbl.cont_type)
    ? !!mbl.non_operational
    : undefined;

  return billofladingMBL;
};

const truncateAditionalHSCODE = (codes: StringValue, max: number): string => {
  const str = _.trim(_.toString(codes));
  if (str.length <= max) {
    return str;
  }

  const hscodes = _.split(",", str);
  return truncateAditionalHSCODE(_.join(",", _.slice(0, -1, hscodes)), max);
};

export const createBillOfLadingHBLPackage = (
  pack: PackageData
): BillOfLadingHBLPackage => ({
  packages: pack.packages,
  volume: pack.volume,
  weight: pack.weight,
  packages_type: pack.packages_type?.label,
  multiple_hscode: pack.multiple_hscode,
  hscode: pack.hscode,
  aditional_hscode: pack.multiple_hscode
    ? truncateAditionalHSCODE(pack.aditional_hscode, maxAditionalHSCODELength)
    : null,
  imo: pack.imo?.label,
  art_15: isNO(pack.imo) ? null : pack.art_15,
  un_number: isNO(pack.imo) ? null : pack.un_number,
  class_number: isNO(pack.imo) ? null : pack.class_number,
  packaging_group: isNO(pack.imo) ? null : pack.packaging_group,
  marks: pack.marks,
  description: pack.description,
  containers: _.map("id", pack.containers),
});

export const createBillOfLadingHBL = ({
  hbl,
  packages,
}: {
  hbl: HBLData;
  packages: PackageData[];
}): BillOfLadingHBL => ({
  bl_num: _.pipe(
    _.toString,
    _.trim,
    _.truncate({ length: maxBlNumLength, omission: "" })
  )(hbl.bl_num),
  notify: hbl.notify?.label,
  shipper: hbl.shipper?.label,
  freight: hbl.freight,
  seawaybill: hbl.seawaybill?.label,
  consignee: hbl.consignee?.label,
  place_receipt: hbl.place_receipt?.label,
  place_delivery: hbl.place_delivery?.label,
  onboard: dateToISO(hbl.onboard),
  on_hold: hbl.on_hold,
  client_reference: _.pipe(
    _.toString,
    _.trim,
    _.truncate({ length: maxClientReferenceLength, omission: "" })
  )(hbl.client_reference),
  packages: _.map(createBillOfLadingHBLPackage, packages),
  containers: _.map("id", hbl.containers),
  internal_comments: _.pipe(
    _.toString,
    _.trim,
    _.truncate({ length: maxInternalCommentsLength, omission: "" })
  )(hbl.internal_comments),
});

export const createBillOfLadingDocument = async (
  document: Document
): Promise<BillOfLadingDocument> => ({
  type: _.toString(document.type?.id),
  name: _.toString(document.file?.name),
  data: await fileToBase64(document.file!),
});
