import { Intent, Position, Toaster } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

const Alert = Toaster.create({
  autoFocus: false,
  canEscapeKeyClear: true,
  position: Position.TOP,
})

export const defaultProps = {
  timeout: 5000,
} as const

export const defaultErrorProps = {
  ...defaultProps,
  icon: IconNames.ERROR,
  intent: Intent.DANGER,
} as const

export const defaultSuccessProps = {
  ...defaultProps,
  icon: IconNames.TICK,
  intent: Intent.SUCCESS,
} as const

export const defaultWarningProps = {
  ...defaultProps,
  icon: IconNames.WARNING_SIGN,
  intent: Intent.WARNING,
} as const

export const defaultInfoProps = {
  ...defaultProps,
  icon: IconNames.INFO_SIGN,
  intent: Intent.PRIMARY,
} as const

export default Alert;
