import React from 'react';
import Card from '../components/Card';
import ClassifyControls from '../components/ClassifyControls';
import ClassifyStatusTree from '../components/ClassifyStatusTree';
import Layout from '../components/Layout';
import PDFViewer from '../components/PDFViewer';
import { useBookingActions } from '../hooks/booking';
import { useClassify } from '../hooks/classify';
import { useExpedienteActions } from '../hooks/expediente';
import { usePageState } from '../hooks/page';
import { useRedirect } from '../hooks/redirect';
import routes from '../utils/constants/routes';
import { handleError } from '../utils/errors';
import { maybeAlertMissingBooking } from '../utils/page';

const Classify: React.FunctionComponent = () => {
  const { isCompleted, pages: { current, total, index, lastCompleted }, control, document, type, booking } = useClassify()
  const redirect = useRedirect()
  const expedientePages = usePageState(state => state.expedientePages)
  const bookingPages = usePageState(state => state.bookingPages)
  const { mergeExpediente, extractExpediente } = useExpedienteActions(actions => ({
    mergeExpediente: actions.mergePages,
    extractExpediente: actions.extractData,
  }))
  const { mergeBookings, extractBookings } = useBookingActions(actions => ({
    mergeBookings: actions.mergePages,
    extractBookings: actions.extractData
  }))
  
  const handleNext = async () => {
    const allOk = maybeAlertMissingBooking(bookingPages)
    if (!allOk) return

    const [expDocs, bknDocs] = await Promise.all([
      mergeExpediente(expedientePages),
      mergeBookings(bookingPages)
    ])

    await extractExpediente(expDocs)
    extractBookings(bknDocs)

    redirect(routes.expediente)
  }

  return (
    <Layout onClickNext={handleError(handleNext)} isNextDisabled={!isCompleted}>
      <div className="tw-w-full tw-gap-2 tw-grid tw-grid-cols-[3fr,2fr] tw-p-2">
        <Card>
          <PDFViewer src={current.file} title={`Página ${index + 1} / ${total}`} />
        </Card>
        <div className="tw-grid tw-grid-rows-[auto,1fr]">
          <ClassifyControls
            document={document}
            type={type}
            booking={booking}
            pagination={{
              current: index,
              total,
              lastIndexCompleted: lastCompleted
            }}
            control={{
              next: control.next,
              prev: control.prev,
              save: control.persist,
              addBooking: control.addBooking,
              goto: control.goto
            }} />
          <ClassifyStatusTree />
        </div>
      </div>
    </Layout >
  );
};

export default Classify
