import _ from "lodash/fp";
import React from "react";
import { useExpedienteState } from "../hooks/expediente";
import { PackageData } from "../types";
import { maxAditionalHSCODELength } from "../utils/constants/package";
import { containersToSelectList } from "../utils/container";
import { isGenericFocus, onChangeGenericData } from "../utils/data";
import { getHBLRequiredMark } from "../utils/hbl";
import { getIMOList, isSI } from "../utils/imo";
import { getPackagesAsSelectValueList } from "../utils/masterData";
import { getPackageRequiredMark } from "../utils/package";
import Card from "./Card";
import CardHeader from "./CardHeader";
import AsyncSelect from "./DataInput/AsyncSelect";
import Boolean from "./DataInput/Boolean";
import Button from "./DataInput/Button";
import MultiSelect from "./DataInput/MultiSelect";
import Number from "./DataInput/Number";
import Text from "./DataInput/Text";
import Wrapper from "./DataInput/Wrapper";
import HoverRemoveIcon from "./HoverRemoveIcon";
import ScrollableList from "./ScrollableList";

type ItemProps = {
  name: string;
  data: PackageData;
  onChangeData: (data: PackageData) => void;
  focus?: keyof PackageData;
  onFocusField?: (field: keyof PackageData) => void;
  onRemove?: () => void;
};

const Item: React.FunctionComponent<ItemProps> = ({
  name,
  data,
  focus,
  onChangeData,
  onFocusField,
  onRemove,
}) => {
  const containers = useExpedienteState((state) => state.state.containers.data);

  const onChange = onChangeGenericData(data, onChangeData);

  const isFocused = isGenericFocus(focus);

  return (
    <Card className="tw-group">
      <CardHeader>
        {`Bulto ${name}`}
        {_.isFunction(onRemove) && (
          <HoverRemoveIcon onClick={onRemove} content="Eliminar contenedor" />
        )}
      </CardHeader>
      <Wrapper isFocused={isFocused("packages")}>
        <Number
          label={`Bultos ${getPackageRequiredMark("packages")}`}
          value={data.packages}
          isInteger
          onChange={onChange("packages")}
          onFocusInput={() => onFocusField?.("packages")}
        />
      </Wrapper>
      <Wrapper isFocused={isFocused("packages_type")}>
        <AsyncSelect
          label={`Tipo ${getPackageRequiredMark("packages_type")}`}
          value={data.packages_type}
          onChange={onChange("packages_type")}
          onFocusInput={() => onFocusField?.("packages_type")}
          onQueryOptions={getPackagesAsSelectValueList}
        />
      </Wrapper>
      <Wrapper isFocused={isFocused("weight")}>
        <Number
          label={`Kilos ${getPackageRequiredMark("weight")}`}
          value={data.weight}
          onChange={onChange("weight")}
          onFocusInput={() => onFocusField?.("weight")}
        />
      </Wrapper>
      <Wrapper isFocused={isFocused("volume")}>
        <Number
          label={`Cúbico ${getPackageRequiredMark("volume")}`}
          value={data.volume}
          onChange={onChange("volume")}
          onFocusInput={() => onFocusField?.("volume")}
        />
      </Wrapper>
      <Wrapper isFocused={isFocused("marks")}>
        <Text
          label={`Marcas ${getPackageRequiredMark("marks")}`}
          value={data.marks}
          onChange={onChange("marks")}
          onFocusInput={() => onFocusField?.("marks")}
        />
      </Wrapper>
      <Wrapper isFocused={isFocused("description")}>
        <Text
          large
          label={`Descripción ${getPackageRequiredMark("description")}`}
          value={data.description}
          onChange={onChange("description")}
          onFocusInput={() => onFocusField?.("description")}
        />
      </Wrapper>
      <Wrapper isFocused={isFocused("multiple_hscode")}>
        <Boolean
          label={`Múltiple HSCODE ${getPackageRequiredMark("multiple_hscode")}`}
          value={data.multiple_hscode}
          onChange={onChange("multiple_hscode")}
          onFocusInput={() => onFocusField?.("multiple_hscode")}
        />
      </Wrapper>
      <Wrapper isFocused={isFocused("hscode")}>
        <Text
          label={`HSCODE ${getPackageRequiredMark("hscode")}`}
          value={data.hscode}
          onChange={onChange("hscode")}
          onFocusInput={() => onFocusField?.("hscode")}
        />
      </Wrapper>
      {data.multiple_hscode && (
        <Wrapper isFocused={isFocused("aditional_hscode")}>
          <Text
            label={`HSCODE adicionales ${getPackageRequiredMark(
              "aditional_hscode"
            )}`}
            value={data.aditional_hscode}
            onChange={onChange("aditional_hscode")}
            onFocusInput={() => onFocusField?.("aditional_hscode")}
            maxLength={maxAditionalHSCODELength}
          />
        </Wrapper>
      )}
      <Wrapper isFocused={isFocused("imo")}>
        <Button
          label={`IMO ${getPackageRequiredMark("imo")}`}
          value={data.imo}
          onChange={onChange("imo")}
          options={getIMOList()}
          onFocusInput={() => onFocusField?.("imo")}
        />
      </Wrapper>
      {isSI(data.imo) && (
        <>
          <Wrapper isFocused={isFocused("art_15")}>
            <Boolean
              label={`Artículo 15 ${getPackageRequiredMark("art_15")}`}
              value={data.art_15}
              onChange={onChange("art_15")}
              onFocusInput={() => onFocusField?.("art_15")}
            />
          </Wrapper>
          <Wrapper isFocused={isFocused("un_number")}>
            <Text
              label={`UN number ${getPackageRequiredMark("un_number")}`}
              value={data.un_number}
              onChange={onChange("un_number")}
              onFocusInput={() => onFocusField?.("un_number")}
              forceNumberChars
            />
          </Wrapper>
          <Wrapper isFocused={isFocused("class_number")}>
            <Text
              label={`IMO class ${getPackageRequiredMark("class_number")}`}
              value={data.class_number}
              onChange={onChange("class_number")}
              onFocusInput={() => onFocusField?.("class_number")}
              forceNumberChars
            />
          </Wrapper>
          <Wrapper isFocused={isFocused("packaging_group")}>
            <Text
              label={`Packaging group ${getPackageRequiredMark(
                "packaging_group"
              )}`}
              value={data.packaging_group}
              onChange={onChange("packaging_group")}
              onFocusInput={() => onFocusField?.("packaging_group")}
            />
          </Wrapper>
        </>
      )}
      <Wrapper isFocused={isFocused("containers")}>
        <MultiSelect
          label={`Contenedores ${getHBLRequiredMark("containers")}`}
          value={data.containers}
          onChange={onChange("containers")}
          onFocusInput={() => onFocusField?.("containers")}
          options={containersToSelectList(containers)}
          minChars={0}
        />
      </Wrapper>
    </Card>
  );
};

export type Props = {
  data: PackageData[];
  onChangeDataAt: (index: number, data: PackageData) => void;
  onRemoveDataAt: (index: number) => void;
  focus?: [number, keyof PackageData];
  onFocusField?: (focus: [number, keyof PackageData]) => void;
};

const BookingPackageData: React.FunctionComponent<Props> = ({
  data,
  onChangeDataAt,
  onRemoveDataAt,
  onFocusField,
  focus,
}) => {
  const isItemFocused = (index: number) => _.get(0, focus) === index;

  return (
    <ScrollableList className="tw-p-2">
      {data.map((item, index) => (
        <Item
          key={index}
          name={_.toString(index + 1)}
          data={item}
          onChangeData={(data) => onChangeDataAt(index, data)}
          focus={isItemFocused(index) ? _.get(1, focus) : undefined}
          onRemove={() => onRemoveDataAt(index)}
          onFocusField={(field) => onFocusField?.([index, field])}
        />
      ))}
    </ScrollableList>
  );
};

export default BookingPackageData;
