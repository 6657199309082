import { AxiosResponse } from "axios"
import client from "./apiClient"
import { api } from "./endpoints"

export const getTenants = async (): Promise<string[]> => {
  const response: AxiosResponse<string[]> = await client.get(
    `${api.tenant}`
  )
  return response.data
}
