import _ from "lodash/fp";
import { Rect } from "../types";

export const getAreaImage = (canvas: HTMLCanvasElement, position: Rect): string => {
  const { left, top, width, height } = position;

  const newCanvas = document.createElement('canvas');
  _.defer(() => newCanvas.remove())

  newCanvas.width = width;
  newCanvas.height = height;

  const newCanvasContext = newCanvas.getContext('2d');

  if (!newCanvasContext || !canvas) return '';

  const dpr: number = window.devicePixelRatio;

  newCanvasContext.drawImage(
    canvas,
    left * dpr,
    top * dpr,
    width * dpr,
    height * dpr,
    0,
    0,
    width,
    height
  );

  return newCanvas.toDataURL('image/png');
};
