import { Icon, Position } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Tooltip2 } from '@blueprintjs/popover2';
import React from 'react';

type Props = {
  title: string;
  onClickButton?: () => void,
  showButton?: boolean
  isActive?: boolean
};

const PDFViewerHeader: React.FunctionComponent<Props> = ({
  title,
  onClickButton,
  isActive,
  showButton
}: Props) => {
  return (
    <div className="tw-z-10 tw-px-4 tw-flex tw-h-12 tw-items-center tw-text-white tw-bg-gray-800 tw-w-full tw-justify-between">
      <span className="tw-font-medium">{title}</span>
      {showButton && (
        <Tooltip2 content={`${isActive ? 'Ocultar' : 'Mostrar'} cajas`} placement={Position.TOP}>
          <div
            onClick={onClickButton}
            className="tw-flex tw-items-center tw-justify-center tw-p-2 tw-cursor-pointer"
          >
            <Icon icon={isActive ? IconNames.EYE_OPEN : IconNames.EYE_OFF} iconSize={20} />
          </div>
        </Tooltip2>
      )}
    </div>
  );
};

export default PDFViewerHeader;
