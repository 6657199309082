import classNames from 'classnames';
import _ from 'lodash/fp';
import React from 'react';
import Card from '../components/Card';
import ExpedienteMBLDataFiller from '../components/ExpedienteMBLDataFiller';
import FileList from '../components/FileList';
import Layout from '../components/Layout';
import { useBookingState } from '../hooks/booking';
import { useExpedienteState } from '../hooks/expediente';
import { useSelect } from '../hooks/list';
import { useRedirect } from '../hooks/redirect';
import { showWarningAlert } from '../utils/alert';
import routes from '../utils/constants/routes';
import { handleError } from '../utils/errors';

const Expediente: React.FunctionComponent = () => {
  const files = useExpedienteState(state => state.files)
  const isComplete = useExpedienteState(state => state.state.mbl.isComplete)
  const isHBLReady = useBookingState(state => state.isReady)
  const hasMBL = useExpedienteState(state => state.hasMBL)
  const { current, index, changeIndex } = useSelect(files)
  const redirect = useRedirect()

  const hasFiles = !_.isEmpty(files)
  const layoutClass = classNames(
    "tw-w-full tw-gap-2 tw-grid tw-p-2",
    {
      "tw-grid-cols-[1fr,4fr,2fr]": hasFiles,
      "tw-grid-cols-[4fr,2fr]": !hasFiles
    }
  )

  const handleNext = async () => {
    if(!hasMBL && !isHBLReady) {
      showWarningAlert("Por favor, espere. Aún no se ha procesado el primer HBL")
      return
    }
    redirect(hasMBL ? routes.expediente_containers : routes.booking)
  }

  return (
    <Layout onClickNext={handleError(handleNext)} isNextDisabled={!isComplete}>
      <div className={layoutClass}>
        {hasFiles && (
          <Card>
            <FileList files={files} onClick={changeIndex} selected={index} />
          </Card>
        )}
        <ExpedienteMBLDataFiller 
          file={current}
          index={index}
          isSimple={!hasMBL} 
        />
      </div>
    </Layout >
  );
};

export default Expediente;
