import { AxiosResponse } from "axios";
import { Player } from "../types/api";
import client from "./apiClient";
import { api } from "./endpoints";


export const getShippers = async (search: string, code: string): Promise<Player[]> => {
  const response: AxiosResponse<Player[]> = await client.get(
    `${api.player}/shippers`,
    {
      params: { search, code }
    }
  );
  return response.data
};

export const getConsignees = async (search: string, code: string): Promise<Player[]> => {
  const response: AxiosResponse<Player[]> = await client.get(
    `${api.player}/consignees`,
    {
      params: { search, code }
    }
  );
  return response.data
};

export const getNotifies = async (search: string, code: string): Promise<Player[]> => {
  const response: AxiosResponse<Player[]> = await client.get(
    `${api.player}/notifies`,
    {
      params: { search, code }
    }
  );
  return response.data
};

export const getCarriers = async (search: string, code: string): Promise<Player[]> => {
  const response: AxiosResponse<Player[]> = await client.get(
    `${api.player}/carriers`,
    {
      params: { search, code }
    }
  );
  return response.data
};
