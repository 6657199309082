import { action, computed } from "easy-peasy";
import _ from "lodash/fp";
import { createDefaultContainerList, isContainerDataComplete } from "../../utils/container";
import { Actions, Model, State } from "../types/container";

const createDefaultState = () => ({
  list: createDefaultContainerList(),
  data: [],
  boxes: [],
  isComplete: false,
})

const modelState: State = {
  ...createDefaultState(),
  data: computed([
    (state) => state.list
  ], _.map('data')),
  boxes: computed([
    state => state.list
  ], _.map('boxes')),
  isComplete: computed([
    state => state.data
  ], _.all(isContainerDataComplete)),
}

const modelActions: Actions = {
  clear: action((_) => createDefaultState()),
  setList: action((state, payload) => {
    state.list = payload
  }),
  setData: action((state, payload) => {
    if (payload.index >= _.size(state.list)) return
    
    state.list[payload.index] = _.set('data', payload.data, state.list[payload.index])
  }),
  setBoxes: action((state, payload) => {
    if (payload.index >= _.size(state.list)) return
    
    state.list[payload.index] = _.set('boxes', payload.data, state.list[payload.index])
  })
};

const model: Model = {
  ...modelState,
  ...modelActions,
};

export default model
