import { Card, Spinner, SpinnerSize } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import _ from 'lodash/fp'
import React from 'react'
import { useDocumentTypes } from '../hooks/classify'
import { NumberValue, SelectValue } from '../types'
import { getDocDataTypeList, isBooking } from '../utils/docTypes'
import BlueButton from './BlueButton'
import ButtonInput from './DataInput/ButtonInput'
import RadioInput from './DataInput/RadioInput'
import Select from './DataInput/Select'
import Pagination from './Pagination'

type DataSelect = {
  value: SelectValue,
  set: (value: SelectValue) => void,
}

type Booking = {
  value: NumberValue,
  set: (value: NumberValue) => void,
  total: number
}

type Props = {
  document: DataSelect,
  type: DataSelect,
  booking: Booking,
  pagination: {
    current: number,
    total: number,
    lastIndexCompleted?: number
  }
  control: Partial<{
    next: () => void,
    prev: () => void,
    goto: (page: number) => void,
    addBooking: () => void
    save: () => void
  }>
}

const dataTypes = getDocDataTypeList()

const numberToSelect = (n: NumberValue): SelectValue => ({ id: _.toString(n), label: _.toString(_.toInteger(n) + 1) })

const ClassifyControls: React.FunctionComponent<Props> = ({
  document, type, booking,
  control: { prev, next, save, goto, addBooking },
  pagination: { current, total, lastIndexCompleted }
}) => {
  const { main, other, loading } = useDocumentTypes()

  const bookings = _.map(numberToSelect, _.range(0, booking.total))

  const selectBooking = (b: SelectValue) => booking.set(_.toInteger(b?.id))

  const bookingDisabled = !isBooking(type.value)

  return (
    <div className="tw-flex tw-flex-col tw-space-y-2 tw-py-2">
      <div className="tw-flex tw-flex-row tw-items-center tw-space-x-4 tw-w-full">
        <span className="tw-font-medium tw-whitespace-nowrap">Tipo de documento:</span>
        <RadioInput
          value={type.value}
          options={dataTypes}
          onChange={type.set}
          direction='horizontal'
        />
      </div>
      <div className="tw-grid tw-gap-2 tw-grid-cols-2">
        <Card className="tw-p-4">
          {!loading ? (
            <div className='tw-grid tw-gap-4'>
              <div className='tw-overflow-auto tw-max-h-96'>
                <ButtonInput
                  value={document.value}
                  options={main}
                  onChange={document.set}
                  direction='vertical'
                />
              </div>
              <Select
                label="Otros"
                value={_.find({ id: document.value?.id }, other) ?? null}
                options={other}
                onChange={document.set}
                minChars={0}
              />
            </div>
          ) : (
            <div className='tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center'>
              <Spinner className="" size={SpinnerSize.SMALL} />
            </div>
          )}
        </Card>
        <Card className="tw-flex tw-flex-col tw-space-y-2 tw-p-4">
          <BlueButton
            disabled={_.isNil(addBooking) || bookingDisabled}
            onClick={addBooking}
            icon={IconNames.ADD}
            fill
          >
            Asignar a nuevo Booking
          </BlueButton>
          <ButtonInput
            disabled={bookingDisabled}
            value={!bookingDisabled ? numberToSelect(booking.value) : undefined}
            options={bookings}
            onChange={selectBooking}
            direction='vertical'
          />
        </Card>
      </div>
      <div className="tw-flex tw-flex-row tw-justify-center tw-items-center tw-space-x-4">
        <Pagination
          next={next}
          prev={prev}
          goto={(n) => goto?.(n)}
          total={total}
          current={current + 1}
          disableAfter={_.toInteger(lastIndexCompleted) + 2}
        />
        <BlueButton
          disabled={_.isNil(save)}
          onClick={save}
          icon={IconNames.CONFIRM}
        >
          Guardar
        </BlueButton>
      </div>
    </div>
  )
}

export default ClassifyControls
