import { Label } from '@blueprintjs/core';
import _ from 'lodash/fp';
import React from 'react';
import { SelectValue } from '../../types';
import MultiSelectInput from './MultiSelectInput';

type Props = {
  label: React.ReactNode;
  value: SelectValue[];
  options: SelectValue[];
  onChange: (value: SelectValue[]) => void;
  onFocusInput?: () => void;
  minChars?: number
};

const MultiSelect: React.FunctionComponent<Props> = ({
  label,
  options,
  value,
  onChange,
  onFocusInput = _.noop,
  minChars
}: Props) => {
  return (
    <Label className="tw-font-medium" style={{ marginBottom: 0 }}>
      {label}
      <div className="tw-mt-1">
        <MultiSelectInput
          value={value}
          options={options}
          onChange={onChange}
          onFocusInput={onFocusInput}
          minChars={minChars}
        />
      </div>
    </Label>
  );
};

export default MultiSelect;
