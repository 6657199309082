import { Button, ButtonProps, Intent } from '@blueprintjs/core'
import React from 'react'

type Props =  React.PropsWithChildren<Omit<ButtonProps, 'intent'>>

const BlueButton: React.FunctionComponent<Props> = ({children, ...props}) => {
  return (
    <Button intent={Intent.PRIMARY} {...props}>{children}</Button>
  )
}


export default BlueButton
