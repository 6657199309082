import { Position, Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { Tooltip2 } from '@blueprintjs/popover2'
import classNames from 'classnames'
import React from 'react'
import { usePDFActions } from '../hooks/pdf'


const PDFViewerBottomBar: React.FunctionComponent = () => {
  const {fit, zoom, rotate} = usePDFActions()

  const decreaseZoom = () => zoom(-0.1);

  const increaseZoom = () => zoom(0.1)

  const rotateClockwise = () => rotate(90)

  const rotateCounterClockwise = () => rotate(-90)

  const iconClass = classNames("tw-flex tw-items-center tw-justify-center tw-cursor-pointer")

  return (
    <div className="tw-z-10 tw-px-4 tw-flex tw-h-12 tw-items-center tw-text-white tw-bg-gray-800 tw-w-full tw-justify-center tw-space-x-10">
      <Tooltip2 content="Incrementar zoom" placement={Position.TOP}>
        <div
          className={iconClass}
          onClick={increaseZoom}
        >
          <Icon icon={IconNames.ZOOM_IN} iconSize={30} />
        </div>
      </Tooltip2>
      <Tooltip2 content="Decrementar zoom" placement={Position.TOP}>
        <div
          className={iconClass}
          onClick={decreaseZoom}
        >
          <Icon icon={IconNames.ZOOM_OUT} iconSize={30} />
        </div>
      </Tooltip2>
      <Tooltip2 content="Rotar izquierda" placement={Position.TOP}>
        <div
          className={iconClass}
          onClick={rotateCounterClockwise}
        >
          <Icon icon={IconNames.ROTATE_DOCUMENT} iconSize={30}/>
        </div>
      </Tooltip2>
      <Tooltip2 content="Rotar derecha" placement={Position.TOP}>
        <div
          className={iconClass + " tw-scale-x-[-100%]"}
          onClick={rotateClockwise}
        >
          <Icon icon={IconNames.ROTATE_DOCUMENT} iconSize={30} />
        </div>
      </Tooltip2>
      <Tooltip2 content="Auto ajuste" placement={Position.TOP}>
        <div
          className={iconClass}
          onClick={fit}
        >
          <Icon icon={IconNames.ZOOM_TO_FIT} iconSize={30} />
        </div>
      </Tooltip2>
    </div>
  )
}

export default PDFViewerBottomBar
