import _ from "lodash/fp";
import { PackageState, Rect, StringValue } from "../types";
import {
  TheBoxData,
  TheBoxExtractionData,
  TheBoxFieldValue,
} from "../types/api";
import { showErrorAlert } from "./alert";
import { mappings, required } from "./constants/package";
import { scaleFrom } from "./coords";
import { isHSCODE } from "./crc";
import {
  getGenericMappedValue,
  getGenericRequired,
  getGenericRequiredMark,
  isGenericDataComplete,
  scaleAndRoundAllGenericBoxes,
} from "./data";
import { getIMOTypes } from "./imo";
import { createDefaultTheBoxField, updateTheBoxField } from "./thebox";

const imo = getIMOTypes();

export const createDefaultPackageData = (): PackageState["data"] => ({
  packages: null,
  packages_type: null,
  weight: null,
  volume: null,
  marks: null,
  description: null,
  multiple_hscode: false,
  hscode: null,
  aditional_hscode: null,
  imo: imo.NO,
  art_15: false,
  un_number: null,
  class_number: null,
  packaging_group: null,
  containers: [],
});

export const createDefaultPackageBoxes = (): PackageState["boxes"] => ({
  packages: null,
  packages_type: null,
  weight: null,
  volume: null,
  marks: null,
  description: null,
  multiple_hscode: null,
  hscode: null,
  aditional_hscode: null,
  imo: null,
  art_15: null,
  un_number: null,
  class_number: null,
  packaging_group: null,
  containers: null,
});

export const getMappings = () => mappings;
export const getRequired = () => required;

export const getPackageMappings = getGenericMappedValue(getMappings());
export const getPackageRequired = getGenericRequired(getRequired());
export const getPackageRequiredMark = getGenericRequiredMark(getRequired());

export const isPackageDataComplete = isGenericDataComplete(getRequired());

export const generatePackageDataFromTheBox = async (
  extraction: TheBoxExtractionData
) => {
  const data = createDefaultPackageData();
  const thebox = extraction?.data;
  if (_.isNil(thebox)) return data;

  const contValue = _.get([0, "value"], thebox.containers);
  const hscode = _.get([0, "value"], thebox.lg_hscodes);
  const aditionalHscodes = _.map("value", _.tail(thebox.lg_hscodes));

  const theboxToValue = (field: keyof TheBoxData) =>
    _.get([field, "value"], thebox);

  data.weight = await getPackageMappings("weight")(theboxToValue("lg_weight"));
  data.volume = await getPackageMappings("volume")(theboxToValue("lg_volume"));
  data.packages = await getPackageMappings("packages")(contValue?.packages);
  data.hscode = await getPackageMappings("hscode")(hscode);
  data.aditional_hscode = _.join(",", aditionalHscodes);
  data.multiple_hscode = aditionalHscodes.length > 0;
  data.marks = await getPackageMappings("marks")(theboxToValue("marks"));
  data.description = await getPackageMappings("description")(
    theboxToValue("description")
  );

  return data;
};

export const generatePackageBoxesFromTheBox = (
  extraction: TheBoxExtractionData
) => {
  const boxes = createDefaultPackageBoxes();
  if (_.isNil(extraction)) return boxes;

  const canvas = extraction.canvas;
  const theboxData = extraction.data;
  if (_.isEmpty(theboxData)) return boxes;

  const transform = (box: TheBoxFieldValue) => {
    if (_.isNil(box)) return null;

    return _.merge(scaleFrom(box as Rect, canvas), {
      page: box.page,
      fileIndex: 0,
    });
  };
  const hscode = _.get(0, theboxData.lg_hscodes);

  boxes.weight = transform(theboxData.lg_weight);
  boxes.volume = transform(theboxData.lg_volume);
  boxes.hscode = transform(hscode);
  boxes.marks = transform(theboxData.marks);
  boxes.description = transform(theboxData.description);

  return boxes;
};

export const createDefaultPackage = (): PackageState => ({
  data: createDefaultPackageData(),
  boxes: createDefaultPackageBoxes(),
});

export const generatePackageFromTheBox = async (
  extraction: TheBoxExtractionData
): Promise<PackageState> => ({
  data: await generatePackageDataFromTheBox(extraction),
  boxes: generatePackageBoxesFromTheBox(extraction),
});

export const generatePackageListFromTheBox = async (
  extraction: TheBoxExtractionData
) => {
  return Promise.all([generatePackageFromTheBox(extraction)]);
};

export const generateTheBoxFromPackages = _.curry(
  (extraction: TheBoxExtractionData, packages: PackageState[]) => {
    if (_.isNil(extraction)) return;

    const thebox = _.cloneDeep(extraction);
    const theboxData = thebox.data;
    const scaledBoxesFn = scaleAndRoundAllGenericBoxes(thebox.canvas);

    const sumByKey = (key: keyof PackageState["data"]) =>
      _.pipe(_.map(`data.${key}`), _.sum, _.toString)(packages);
    const firstPackage = _.first(packages);

    theboxData.lg_weight = updateTheBoxField(theboxData.lg_weight, {
      value: sumByKey("weight"),
      box: theboxData.lg_weight,
    });

    theboxData.lg_volume = updateTheBoxField(theboxData.lg_volume, {
      value: sumByKey("volume"),
      box: theboxData.lg_volume,
    });

    if (!_.isNil(firstPackage)) {
      theboxData.marks = updateTheBoxField(theboxData.marks, {
        value: firstPackage.data.marks,
        box: scaledBoxesFn(firstPackage.boxes).marks,
      });

      theboxData.description = updateTheBoxField(theboxData.description, {
        value: firstPackage.data.description,
        box: scaledBoxesFn(firstPackage.boxes).description,
      });
    }

    theboxData.lg_hscodes = _.map((p) => {
      const scaledBoxes = scaledBoxesFn(p.boxes);
      return updateTheBoxField(createDefaultTheBoxField(), {
        value: p.data.hscode,
        box: scaledBoxes.hscode,
      });
    }, packages);

    thebox.data = theboxData;
    return thebox;
  }
);

export const maybeAlertPackageHSCODE = (
  pack: PackageState["data"]
): boolean => {
  if (isHSCODE(_.toString(pack.hscode))) return true;

  showErrorAlert(`El código ${pack.hscode} no corresponde con un HSCODE`);
  return false;
};

const maybeAlertPackageAditionalHSCODE = (code: StringValue): boolean => {
  if (isHSCODE(_.toString(code))) return true;

  showErrorAlert(`El código adicional ${code} no corresponde con un HSCODE`);

  return false;
};

export const maybeAlertPackageAditionalHSCODES = (
  pack: PackageState["data"]
): boolean => {
  if (!pack.multiple_hscode) return true;

  const codes = _.split(",", _.toString(pack.aditional_hscode));

  return _.all(maybeAlertPackageAditionalHSCODE, _.reject(_.isEmpty, codes));
};
