import React from 'react';
import _ from 'lodash/fp';
import { Radio, RadioGroup } from '@blueprintjs/core';
import { SelectValue } from '../../types';
import { handleStringChange } from '../../utils/events';
import classNames from 'classnames';

type Props = {
  value: SelectValue;
  options: SelectValue[];
  onChange: (value: SelectValue) => void;
  direction?: 'vertical' | 'horizontal'
};

const RadioInput: React.FunctionComponent<Props> = ({ options, value, onChange, direction = 'horizontal' }: Props) => {
  const onChangeOption = (id: string) => onChange(_.find({ id }, options))

  const radioClass = classNames({
    "tw-mb-0": direction === 'horizontal'
  })

  return (
    <RadioGroup
      inline={direction === 'horizontal'}
      onChange={handleStringChange(onChangeOption)}
      selectedValue={value?.id}
    >
      {_.map((option) => (
        <Radio
          className={radioClass}
          key={`${option?.id}_${option?.label}`}
          label={option?.label}
          value={option?.id}
        />
      ), options)}
    </RadioGroup>
  );
};

export default RadioInput;
