import { InputGroup } from '@blueprintjs/core';
import _ from 'lodash/fp';
import React, { useEffect, useState } from 'react';
import { NumberValue } from '../../types';
import { getDefaultLocale } from '../../utils/date';
import { handleStringChange } from '../../utils/events';
import { numberToString, stringToInteger, stringToNumber } from '../../utils/number';
import ResetButton from './ResetButton';

type Props = {
  value: NumberValue;
  onChange: (value: NumberValue) => void;
  onFocus?: () => void;
  isInteger?: boolean
};

export const NumberInput: React.FunctionComponent<Props> = ({
  value,
  onChange,
  onFocus,
  isInteger = false,
}: Props) => {

  const [num, setNum] = useState(numberToString(value))
  
  useEffect(() => {
    setNum(numberToString(value))
  }, [value])

  const onChangeNumber = (v: string) => {
    if (isInteger) {
      onChange(stringToInteger(v))
    } else {
      onChange(stringToNumber(v))
    }
  }

  return (
    <InputGroup
      className="tw-mt-1"
      fill
      onFocus={onFocus}
      step={isInteger ? 1 : 0.01}
      value={num}
      onChange={handleStringChange(setNum)}
      onBlur={() => onChangeNumber(num)}
      type={'number'}
      lang={getDefaultLocale()}
      rightElement={!_.isNil(value) ? <ResetButton onClick={() => onChange(null)}/> : undefined}
    />
  );
};

export default NumberInput;
