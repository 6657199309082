import { Overlay, Spinner, SpinnerSize } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import _ from "lodash/fp";
import React, { useState } from "react";
import BlueButton from "../components/BlueButton";
import Card from "../components/Card";
import Layout from "../components/Layout";
import PDFViewer from "../components/PDFViewer";
import SummaryBookings from "../components/Summary/SummaryBookings";
import SummaryExpediente from "../components/Summary/SummaryExpediente";
import { useRedirect } from "../hooks/redirect";
import { useStoreActions, useStoreState } from "../hooks/store";
import { showSuccessAlert } from "../utils/alert";
import routes from "../utils/constants/routes";
import { handleError } from "../utils/errors";

const Summary: React.FunctionComponent = () => {
  const clear = useStoreActions((actions) => actions.clear);
  const isSaved = useStoreState((state) => state.isSaved);
  const setSaved = useStoreActions((actions) => actions.setIsSaved);
  const sendThebox = useStoreActions((actions) => actions.sendTheBox);
  const sendBilloflading = useStoreActions(
    (actions) => actions.sendBilloflading
  );
  const [file, setFile] = useState<File>();
  const redirect = useRedirect();

  const [saving, setSaving] = useState(false);

  const handleSave = async () => {
    setSaving(true);

    handleError(async () => {
      await sendThebox();
      await sendBilloflading();

      setSaved(true);
      showSuccessAlert("Se ha guardado correctamente");
    })();

    setSaving(false);
  };

  const handleNext = () => {
    clear();
    redirect(routes.root);
  };

  return (
    <Layout onClickNext={handleError(handleNext)} isNextDisabled={!isSaved}>
      <div className="tw-flex tw-flex-col tw-w-full tw-p-4 tw-space-y-4">
        <div className="tw-flex-1 tw-gap-4 tw-grid tw-grid-cols-5">
          <Card className="tw-h-full tw-col-span-2">
            <SummaryExpediente onClickFile={setFile} />
          </Card>
          <Card className="tw-h-full tw-col-span-3">
            <SummaryBookings onClickFile={setFile} />
          </Card>
        </div>
        <div className="tw-flex tw-items-center tw-justify-center">
          <BlueButton
            onClick={handleSave}
            disabled={saving || isSaved}
            rightIcon={IconNames.CONFIRM}
          >
            <div className="tw-flex tw-flex-row tw-items-center tw-space-x-2">
              {saving && <Spinner size={SpinnerSize.SMALL} />}
              <span>Guardar</span>
            </div>
          </BlueButton>
        </div>
      </div>
      {!_.isNil(file) && (
        <Overlay
          isOpen
          autoFocus={false}
          canOutsideClickClose
          canEscapeKeyClose
          usePortal
          onClose={() => setFile(undefined)}
          className="tw-w-screen tw-h-screen tw-px-64 tw-py-12"
        >
          <Card className="tw-w-full tw-h-full">
            <PDFViewer src={file} />
          </Card>
        </Overlay>
      )}
    </Layout>
  );
};

export default Summary;
