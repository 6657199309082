import _ from "lodash/fp";
import { Box, BoxValue, Nullable, SelectValue, StringValue } from "../types";
import { Status, TheBoxContainer, TheBoxData, TheBoxIdentityField, TheBoxField, TheBoxFieldValue } from "../types/api";


export const softCompareSizes = (a: number, b: number, margin: number = 10) => a >= b - margin && a <= b + margin
export const softCompareBoxes = (a: Box, b: Box) => (
  _.all(_.identity, [
    softCompareSizes(a.height, b.height),
    softCompareSizes(a.width, b.width),
    softCompareSizes(a.left, b.left),
    softCompareSizes(a.top, b.top),
  ])
)

export const getTextStatus = _.curry((a: string, b: string): Status => _.isEqual(a, b) ? 'CORRECT' : 'MODIFIED')

export const getBoxStatus = _.curry((a: Box, b: Box): Status => (
  _.all(_.identity, [a.page === b.page, softCompareBoxes(a, b)]) ? 'CORRECT' : 'MODIFIED'
))

export const generateStatusFrom = _.curry((values: {thebox: StringValue, mine: StringValue}, boxes: {thebox: BoxValue, mine: BoxValue}) => ({
    text_status: getTextStatus(_.toString(values.thebox), _.toString(values.mine)),
    bbox_status: _.isNil(boxes.thebox) || _.isNil(boxes.mine) ? 'CORRECT' : getBoxStatus(boxes.thebox, boxes.mine),
}))

export const createDefaultTheBoxField = (): TheBoxField => ({
  page: 0,
  bbox_status: 'UNCHECKED',
  bbox_confidence: 0,
  text_confidence: 0,
  text_status: 'UNCHECKED',
  value: null,
  top: 0,
  left:0,
  width: 0,
  height: 0
})


export const createDefaultTheBoxContainer = (): TheBoxContainer => ({
  ...createDefaultTheBoxField(),
  value: {
    cont_id: null,
    cont_type: null,
    packages: null,
    seal_no: null,
    volume: null,
    weight: null
  }
})

export const theboxToValue = (thebox: TheBoxData) => (field: keyof TheBoxData) => _.get([field, 'value'], thebox)
export const theboxToId = (thebox: TheBoxData) => (field: keyof TheBoxData) => _.get([field, 'id'], thebox)
export const theboxToIdentityValue = (thebox: TheBoxData) => (field: keyof TheBoxData) => {
  if (_.isEmpty(theboxToId(thebox)(field))) return theboxToValue(thebox)(field)

  return theboxToId(thebox)(field)
}

export const updateTheBoxField = <T extends TheBoxFieldValue>(defaultBox: T, data: {value: StringValue, box: BoxValue}): T => {
  const withStatus = _.merge(defaultBox, generateStatusFrom(
    { thebox: _.toString(defaultBox?.value), mine: data.value},
    { thebox: defaultBox as BoxValue, mine: data.box}))
  const newValue = _.merge({value: data.value}, _.pick(['page','left','top', 'width', 'height'] , data.box))
  return _.merge(withStatus, newValue)
}

export const updateTheBoxIdentityField = <T extends TheBoxIdentityField | Nullable>(defaultBox: T, data: {value: SelectValue, box: BoxValue}): T => {
  const field = updateTheBoxField(defaultBox, {value: data.value?.label, box: data.box})
  return _.merge(field, {id: data.value?.id})
}
