import _ from "lodash/fp"
import { useCallback, useMemo, useState } from "react"
import { BoxValue, StringValue } from "../types"
import { genericRemoveAt, onChangeGenericData } from "../utils/data"
import { createDefaultPackage, getPackageMappings } from "../utils/package"
import { useBookingActions, useBookingState } from "./booking"


export const usePackageController = (idx: number) => {
  const all = useBookingState(state => state.current.state.packages)
  const data = _.map('data', all)
  const boxes = _.map('boxes', all)
  const setData = useBookingActions(actions => actions.setCurrentPackageData)
  const setBoxes = useBookingActions(actions => actions.setCurrentPackageBoxes)
  const setAll = useBookingActions(actions => actions.setCurrentPackages)
  const [focus, setFocus] = useState<[number, keyof typeof data[0]]>([0, 'packages'])
  const [loading, setLoading] = useState(false)

  const [index, field] = focus


  const setDataAt = useCallback((index: number, values: typeof data[0]) => (
    setData({ index, data: values })
  ), [setData])

  const setBoxesAt = useCallback((index: number, values: typeof boxes[0]) => (
    setBoxes({ index, data: values })
  ), [setBoxes])

  const removeDataAt = useCallback((index: number) => {
    setAll(genericRemoveAt(all)(index))
  }, [all, setAll])

  const addData = useCallback(() => {
    setAll(_.concat(all, createDefaultPackage()))
  }, [setAll, all])

  const mapFocusData = useCallback(async (value: StringValue) => {
    const text = _.trim(_.toString(value))
    if(_.isEmpty(text)) return

    const updateFn = getPackageMappings(field)
    const newValue = await updateFn(value)
    if(_.isNil(newValue)) return
    
    onChangeGenericData(_.get(index, data), (d) => setDataAt(index, d))(field)(newValue)
  }, [field, index, setDataAt, data])

  const mapFocusBoxes = useCallback((value: BoxValue) => {
    if(_.isNil(value)) return

    setBoxesAt(index, _.pipe(_.get(index), _.set(field, _.set('fileIndex', idx, value)))(boxes))
  }, [field, index, setBoxesAt, boxes, idx])
  
  const updateFocusState = useCallback(async (text: string, box: BoxValue) => {
    setLoading(true)
    await mapFocusData(text)
    mapFocusBoxes(box)
    setLoading(false)
  }, [mapFocusData, mapFocusBoxes])

  const boxesList = useMemo(() => {
    const box = _.get([index, field], boxes)
    if(box?.fileIndex !== idx) return []

    return [box]
  }, [boxes, index, field, idx])
  
  return {
    loading,
    focus: {
      value: focus,
      set: setFocus,
      updateState: updateFocusState
    },
    boxes: {
      list: boxes,
      current: boxesList
    },
    data: {
      list: data,
      setAt: setDataAt,
      removeAt: removeDataAt,
      add: addData
    }
  }
}
