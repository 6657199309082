import React from 'react';

type Props = React.PropsWithChildren<{}>

const CardHeader: React.FunctionComponent<Props> = ({ children }) => {
  return (
    <div className="tw-p-2 tw-font-medium tw-text-white tw-bg-gray-800 tw-flex tw-items-center tw-justify-between">
      {children}
    </div>
  );
};

export default CardHeader;
