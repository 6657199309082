import React from 'react'
import { useHBLController } from '../hooks/hbl'
import BookingHBLData from './BookingHBLData'
import Card from './Card'
import PDFViewer from './PDFViewer'

type Props = {
  file: File
  index: number
}


const BookingHBLDataFiller: React.FunctionComponent<Props> = ({ file, index }) => {
  const {data, focus, boxes} = useHBLController(index)
  
  return (
    <>
      <Card>
        <PDFViewer 
          src={file} 
          onSelection={focus.updateState}
          boxes={boxes.current}
        />
      </Card>
      <Card>
        <BookingHBLData 
          data={data.state} 
          onChangeData={data.set}
          focus={focus.value}
          onFocusField={focus.set}
        />
      </Card>
    </>
  )
}

export default BookingHBLDataFiller
