import { SelectValue } from "../../types"

const createIMO = (name: string): SelectValue => ({
  id: name, label: name
})

const imoTypes = {
  SI: createIMO('SI'),
  NO: createIMO('NO')
} as const

export const imoList = [
  imoTypes.SI, 
  imoTypes.NO
]

export default imoTypes
