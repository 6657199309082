import {
  Divider,
  FormGroup, InputGroup, Spinner, SpinnerSize
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useLoginForm } from '../hooks/login';
import { LoginData } from '../types';
import BlueButton from './BlueButton';
import SelectInput from './DataInput/SelectInput';
import FormErrorMessage from './FormErrorMessage';


type Props = {
  onSubmit: (data: LoginData) => Promise<void>;
};

const LoginForm: React.FunctionComponent<Props> = ({ onSubmit }: Props) => {
  const { submit, control, errors, loading, state: {tenants} } = useLoginForm(onSubmit)
  const requiredError = () => <FormErrorMessage>El campo es requerido</FormErrorMessage>;

  return (
    <form className="tw-w-64" onSubmit={submit}>
      <FormGroup label="Usuario" labelFor="username">
        <Controller
          name="username"
          control={control}
          rules={{ required: true }}
          render={({ field: {value, ...field} }) => (
            <InputGroup
              fill
              id="username"
              leftIcon={IconNames.USER}
              autoComplete="username"
              value={value ?? ""}
              {...field}
            />
          )}
        />
        {errors.username && requiredError()}
      </FormGroup>
      <FormGroup label="Contraseña" labelFor="password">
        <Controller
          name="password"
          control={control}
          rules={{ required: true }}
          render={({ field: {value, ...field} }) => (
            <InputGroup
              fill
              id="password"
              leftIcon={IconNames.LOCK}
              type="password"
              autoComplete="current-password"
              value={value ?? ""}
              {...field}
            />
          )}
        />
        {errors.password && requiredError()}
      </FormGroup>
      <Divider className="tw-mx-0" />
      <FormGroup label="Usuario GRM" labelFor="grm_user">
        <Controller
          name="grm_user"
          control={control}
          rules={{ required: true }}
          render={({ field: {value, ...field} }) => (
            <InputGroup
              fill
              id="gmr_user"
              leftIcon={IconNames.USER}
              value={value ?? ""}
              {...field}
            />)
          }
        />
        {errors.grm_user && requiredError()}
      </FormGroup>
      <FormGroup label="Empresa" labelFor="tenant">
        <Controller
          name="tenant"
          control={control}
          rules={{ required: true }}
          render={({ field: {ref, ...field} }) => (
            <SelectInput
              {...field}
              minChars={0}
              options={tenants}
            />
          )}
        />
        {errors.tenant && requiredError()}
      </FormGroup>
      <div className="tw-flex tw-items-center tw-justify-center tw-mt-8">
        <BlueButton type='submit' disabled={loading} rightIcon={IconNames.LOG_IN}>
          <div className="tw-flex tw-flex-row tw-items-center tw-space-x-2">
            {loading && <Spinner size={SpinnerSize.SMALL} />}
            <span>Entrar</span>
          </div>
        </BlueButton>
      </div>
    </form>
  );
};

export default LoginForm;
