import _ from 'lodash/fp';
import React from 'react';
import { typeToIcon } from '../utils/file';
import HoverRemoveIcon from './HoverRemoveIcon';
import ListItem from './ListItem';

type Props = {
  file: File;
  isSelected?: boolean;
  onClick?: () => void;
  onRemove?: () => void;
};

const FileListItem: React.FunctionComponent<Props> = ({ file, onRemove, onClick, isSelected }: Props) => (
  <ListItem
    className="tw-group tw-cursor-pointer tw-text-black"
    onClick={onClick}
    isSelected={isSelected}
  >
    <div className="tw-flex tw-flex-row tw-p-2">
      <div className="tw-w-4 tw-mr-2">
        <img src={typeToIcon(file.type)} alt="File icon" />
      </div>
      <span className="tw-flex-1 tw-truncate tw-font-medium">{file.name}</span>
      {_.isFunction(onRemove) && <HoverRemoveIcon onClick={onRemove} content="Eliminar fichero" />}
    </div>
  </ListItem>
);

export default FileListItem;
