import { AxiosResponse } from 'axios';

import { LoginData, StringValue } from '../types';

import client from './apiClient';
import { api } from './endpoints';

export const login = async (data: LoginData) => {
  const response: AxiosResponse<StringValue> = await client.post(api.login, data);
  return response.data;
};
