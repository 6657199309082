import _ from "lodash/fp";
import {
  getCarriers,
  getConsignees,
  getNotifies,
  getShippers,
} from "../services/player.service";
import { SelectValue } from "../types";
import { Player } from "../types/api";
import { getTenant } from "./tenant";

const handleError =
  (callback: (query: string) => Promise<SelectValue[]>) =>
  async (query: string): Promise<SelectValue[]> => {
    try {
      return await callback(query);
    } catch (err) {
      return Promise.resolve([] as SelectValue[]);
    }
  };

const toSelectValue = (data: Player): SelectValue => ({
  id: _.toString(data.guid),
  label: data.name,
});

const toSelectValueList = (data: Player[]) => _.map(toSelectValue, data);

export const getConsigneesAsSelectValueList = handleError(
  async (query: string) => {
    const data = await getConsignees(query, getTenant());
    return toSelectValueList(data);
  }
);

export const getShippersAsSelectValueList = handleError(
  async (query: string) => {
    const data = await getShippers(query, getTenant());
    return toSelectValueList(data);
  }
);

export const getNotifiesAsSelectValueList = handleError(
  async (query: string) => {
    const data = await getNotifies(query, getTenant());
    return toSelectValueList(data);
  }
);

export const getCarriersAsSelectValueList = handleError(
  async (query: string) => {
    const data = await getCarriers(query, getTenant());
    return toSelectValueList(data);
  }
);

export const getShipperAsSelectValue = async (query: string) => {
  const options = await getShippersAsSelectValueList(query);
  return _.first(options);
};

export const getConsigneeAsSelectValue = async (query: string) => {
  const options = await getConsigneesAsSelectValueList(query);
  return _.first(options);
};

export const getNotifyAsSelectValue = async (query: string) => {
  const options = await getNotifiesAsSelectValueList(query);
  return _.first(options);
};

export const getCarrierAsSelectValue = async (query: string) => {
  const options = await getCarriersAsSelectValueList(query);
  return _.first(options);
};
