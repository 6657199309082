const errors = {
  UNKNOWN_ERROR: 'Error desconocido',
  CONNECTION_REFUSED: 'Error de red',
  BAD_CREDENTIALS: 'Credenciales incorrectas',
  UNAUTHORIZED: 'No autorizado o sesión caducada',
  UPLOADING_FILES: 'Error subiendo ficheros',
  CORRUPTED_PDF: 'Existe al menos un PDF corrupto'
} as const

export default errors;
