import _ from "lodash/fp";
import React from "react";
import { ContainerData, PackageData } from "../../types";
import { isContType40REEFERCONTAINER } from "../../utils/container";
import { isNO } from "../../utils/imo";

type Props = {
  columns: string[];
  data: Record<number, string>[];
};

const SummaryTable: React.FunctionComponent<Props> = ({ data, columns }) => {
  return (
    <div className="tw-overflow-x-auto">
      <table className="tw-w-full ">
        <thead className="tw-bg-gray-800 tw-text-white">
          <tr>
            {columns.map((column) => (
              <th
                key={column}
                className="tw-text-left tw-p-2 tw-whitespace-nowrap"
              >
                {column}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr
              key={index}
              className={index % 2 === 0 ? "tw-bg-white" : "tw-bg-gray-100"}
            >
              {_.map(
                (key) => (
                  <td className="tw-p-2" key={key}>
                    {_.pipe(_.get(key), _.toString)(item)}
                  </td>
                ),
                _.pipe(_.keys, _.sortBy(_.toInteger))(item)
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

type SummaryPackagesTableProps = {
  packages: PackageData[];
};

export const SummaryPackagesTable: React.FunctionComponent<
  SummaryPackagesTableProps
> = ({ packages }) => {
  return (
    <SummaryTable
      columns={[
        "Bultos",
        "Tipo",
        "Kilos",
        "Cúbico",
        "Marcas",
        "Descripción",
        "Múltiple HSCODE",
        "HSCODE",
        "HSCODE Adicionales",
        "IMO",
        "Artículo 15",
        "UN number",
        "IMO class",
        "Packaging group",
        "Contenedores",
      ]}
      data={_.map(
        (p) => ({
          0: _.toString(p.packages),
          1: _.toString(p.packages_type?.label),
          2: _.toString(p.weight),
          3: _.toString(p.volume),
          4: _.toString(p.marks),
          5: _.toString(p.description),
          6: _.toString(p.multiple_hscode ? "SI" : "NO"),
          7: _.toString(p.hscode),
          8: _.toString(p.multiple_hscode ? p.aditional_hscode : null),
          9: _.toString(p.imo?.label),
          10: _.toString(isNO(p.imo) ? null : p.art_15 ? "SI" : "NO"),
          11: _.toString(isNO(p.imo) ? null : p.un_number),
          12: _.toString(isNO(p.imo) ? null : p.class_number),
          13: _.toString(isNO(p.imo) ? null : p.packaging_group),
          14: _.join(", ", _.map("label", p.containers)),
        }),
        packages
      )}
    />
  );
};

type SummaryContainersTableProps = {
  containers: ContainerData[];
};

export const SummaryContainersTable: React.FunctionComponent<
  SummaryContainersTableProps
> = ({ containers }) => {
  return (
    <SummaryTable
      columns={["Contenedor", "Tipo", "Precinto", "Bultos", "Kilos", "Volumen"]}
      data={_.map((container) => {
        const data = {
          0: _.toString(container.cont_id),
          1: _.toString(container.cont_type?.label),
          2: _.toString(container.seal_no),
          3: _.toString(container.packages),
          4: _.toString(container.weight),
          5: _.toString(container.volume),
        };
        if (
          isContType40REEFERCONTAINER(container.cont_type) &&
          !!container.non_operational
        ) {
          return _.set(
            0,
            _.toString(`${container.cont_id} (No operacional)`),
            data
          );
        }
        return data;
      }, containers)}
    />
  );
};

export default SummaryTable;
