import React from 'react';
import LoadingComp from '../components/Loading'

const Loading: React.FunctionComponent = () => (
  <div className="tw-z-50 tw-fixed tw-inset-0 tw-flex tw-items-center tw-justify-center">
    <LoadingComp/>
  </div>
);

export default Loading;
