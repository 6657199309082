import _ from 'lodash/fp';
import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useLocation } from 'react-router';

import { getRouteByLink } from '../utils/routes';

const DocumentTitle: React.FunctionComponent = () => {
  const location = useLocation();
  const route = getRouteByLink(location.pathname);

  return (
    <HelmetProvider>
      <Helmet>
        <title>{`CLOPY BL Pre Alerta ${!_.isNil(route) ? `- ${route.name}` : ''}`}</title>
      </Helmet>
    </HelmetProvider>
  );
};

export default DocumentTitle;
