import React from 'react';
import _ from 'lodash/fp';
import { Label } from '@blueprintjs/core';
import { DateValue } from '../../types';
import DateInput from './DateInput';

type Props = {
  label: React.ReactNode;
  value: DateValue;
  onChange: (value: DateValue) => void;
  onFocusInput?: () => void;
};

const Date: React.FunctionComponent<Props> = ({
  label,
  value,
  onChange,
  onFocusInput = _.noop,
}: Props) => {
  return (
    <Label className="tw-font-medium" style={{ marginBottom: 0 }}>
      {label}
      <DateInput
        value={value}
        onChange={onChange}
        onFocus={onFocusInput}
      />
    </Label>
  );
};

export default Date;
