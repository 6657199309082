import Fuse from 'fuse.js'
import _ from 'lodash/fp'
import { SelectValue, StringValue } from '../types';


const createFuzzySearch = (options: SelectValue[]): Fuse<SelectValue> => {
  const key = ['label'];
  const index = Fuse.createIndex<SelectValue>(key, options);
  return new Fuse(
    options,
    {
      minMatchCharLength: 3,
      keys: key,
      shouldSort: true,
      ignoreLocation: true,
    },
    index,
  );
};

export const findById = _.curry((options: SelectValue[], id: StringValue) => _.find({id: _.toString(id)}, options))

export const findByLabel = _.curry((options: SelectValue[], label: StringValue) => {
  const fuse = createFuzzySearch(options);
  return fuse.search(_.pipe(_.toString, _.trim)(label))[0]?.item
})
