import React from 'react'
import ScrollableList from './ScrollableList'
import Wrapper from './DataInput/Wrapper'
import AsyncSelect from './DataInput/AsyncSelect'
import Boolean from './DataInput/Boolean'
import { getCarriersAsSelectValueList, getShippersAsSelectValueList } from '../utils/players'
import { isGenericFocus, onChangeGenericData } from '../utils/data'
import { getContainersAsSelectValueList, getLocodesAsSelectValueList } from '../utils/masterData'
import { Props } from './ExpedienteMBLData'
import { getMBLRequiredSimpleMark } from '../utils/mbl'
import { isContType40REEFERCONTAINER } from '../utils/container'

const ExpedienteMBLDataSimple: React.FunctionComponent<Props> = ({ data, onChangeData, focus, onFocusField }) => {

  const onChange = onChangeGenericData(data, onChangeData)

  const isFocused = isGenericFocus(focus)

  return (
    <ScrollableList >
      <Wrapper isFocused={isFocused('carrier')}>
        <AsyncSelect
          label={`Naviera ${getMBLRequiredSimpleMark('carrier')}`}
          value={data.carrier}
          onChange={onChange('carrier')}
          onFocusInput={() => onFocusField?.('carrier')}
          onQueryOptions={getCarriersAsSelectValueList}
        />
      </Wrapper>
      <Wrapper isFocused={isFocused('shipper')}>
        <AsyncSelect
          label={`Shipper ${getMBLRequiredSimpleMark('shipper')}`}
          value={data.shipper}
          onChange={onChange('shipper')}
          onFocusInput={() => onFocusField?.('shipper')}
          onQueryOptions={getShippersAsSelectValueList}
        />
      </Wrapper>
      <Wrapper isFocused={isFocused('port_loading')}>
        <AsyncSelect
          label={`Puerto de carga ${getMBLRequiredSimpleMark('port_loading')}`}
          value={data.port_loading}
          onChange={onChange('port_loading')}
          onFocusInput={() => onFocusField?.('port_loading')}
          onQueryOptions={getLocodesAsSelectValueList}
        />
      </Wrapper>
      <Wrapper isFocused={isFocused('port_discharge')}>
        <AsyncSelect
          label={`Puerto de descarga ${getMBLRequiredSimpleMark('port_discharge')}`}
          value={data.port_discharge}
          onChange={onChange('port_discharge')}
          onFocusInput={() => onFocusField?.('port_discharge')}
          onQueryOptions={getLocodesAsSelectValueList}
        />
      </Wrapper>
      <Wrapper isFocused={isFocused('cont_type')}>
        <AsyncSelect
          label={`Tipo de contenedor ${getMBLRequiredSimpleMark('cont_type')}`}
          value={data.cont_type}
          onChange={onChange('cont_type')}
          onFocusInput={() => onFocusField?.('cont_type')}
          onQueryOptions={getContainersAsSelectValueList}
        />
      </Wrapper>
      {isContType40REEFERCONTAINER(data.cont_type) &&
        <Wrapper isFocused={isFocused('non_operational')}>
          <Boolean
            label={`No Conectado ${getMBLRequiredSimpleMark('non_operational')}`}
            value={data.non_operational}
            onChange={onChange('non_operational')}
            onFocusInput={() => onFocusField?.('non_operational')}
          />
        </Wrapper>
      }
    </ScrollableList>
  )
}

export default ExpedienteMBLDataSimple
