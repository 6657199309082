import { IconNames } from '@blueprintjs/icons'
import React from 'react'
import { usePackageController } from '../hooks/package'
import BlueButton from './BlueButton'
import BookingPackageData from './BookingPackageData'
import Card from './Card'
import PDFViewer from './PDFViewer'

type Props = {
  file: File
  index: number
}


const BookingPackageDataFiller: React.FunctionComponent<Props> = ({ file, index }) => {
  const { data, focus, boxes } = usePackageController(index)

  return (
    <>
      <Card>
        <PDFViewer
          src={file}
          onSelection={focus.updateState}
          boxes={boxes.current}
        />
      </Card>
      <Card className="tw-grid tw-grid-rows-[1fr,auto]">
        <BookingPackageData
          data={data.list}
          onChangeDataAt={data.setAt}
          onRemoveDataAt={data.removeAt}
          focus={focus.value}
          onFocusField={focus.set}
        />
        <div className="tw-p-2">
          <BlueButton
            fill
            icon={IconNames.ADD}
            onClick={data.add}
          >
            Añadir bulto
          </BlueButton>
        </div>
      </Card>
    </>
  )
}

export default BookingPackageDataFiller
