import _ from "lodash/fp"
import { useCallback, useState } from "react"

export const useSelect = <T>(list: T[]) => {
  const [index, setIndex] = useState(0)

  const changeIndex = useCallback((i: number) => {
    if(!_.inRange(0, list.length)) return

    setIndex(i)
  }, [list.length])

  const current = _.get(index, list)

  return {
    current,
    index,
    changeIndex
  }
}
