import { createTypedHooks } from 'easy-peasy';

import { Model } from '../store/types';

const typedHooks = createTypedHooks<Model>();

export const {
  useStoreActions,
  useStoreDispatch,
  useStoreState,
  useStore
} = typedHooks;
