import _ from "lodash/fp";
import { BoxValue, PDFPage, Rect } from "../types";
import { optimizeRects } from "./coords";

export const getPageFromElement = (target: HTMLElement): PDFPage | null => {
  const node = target.closest('.page');

  if (!(node instanceof HTMLElement)) return null;

  const number = Number(node.dataset.pageNumber);

  return { node, number };
};

export const getPageFromRange = (range: Range): PDFPage | null => {
  const { parentElement } = range.startContainer;

  if (!(parentElement instanceof HTMLElement)) return null;

  return getPageFromElement(parentElement);
};

export const getRangeRects = (range: Range, containerEl: HTMLElement, shouldOptimize = true) => {
  const clientRects = Array.from(range.getClientRects());

  const offset = containerEl.getBoundingClientRect();

  const rects = _.map(
    (rect) => ({
      top: rect.top + containerEl.scrollTop - offset.top,
      left: rect.left + containerEl.scrollLeft - offset.left,
      width: rect.width,
      height: rect.height,
    }),
    clientRects
  );

  return shouldOptimize ? optimizeRects(rects) : rects;
};

export const rectToBox = (rect: Rect, page: PDFPage): BoxValue => ({
  ...rect,
  page: page.number,
})

export const toggleTextSelection = (enable: boolean) => {
  const textLayers: any = document.getElementsByClassName('textLayer')

  _.forEach((textLayer) => {
    textLayer.style.display = enable ? 'block' : 'none';
  }, textLayers)
};

export const findOrCreateContainerLayer = (container: HTMLElement, className: string): Element => {
  const layer = container.querySelector(`.${className}`);
  if (!_.isNil(layer)) return layer;

  const newlayer = document.createElement('div');
  newlayer.className = `tw-absolute tw-inset-0 tw-overflow-hidden ${className}`;
  container.insertBefore(newlayer, container.firstChild);
  return newlayer;
};
