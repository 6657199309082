import _ from 'lodash/fp';
import { useCallback, useMemo, useState } from 'react';
import { Model } from '../store/types/container';
import { MapActions, MapState } from '../store/types/map';
import { BoxValue, StringValue } from '../types';
import { createDefaultContainer, getContainerMappings } from '../utils/container';
import { genericRemoveAt, onChangeGenericData } from '../utils/data';
import { useStoreActions, useStoreState } from './store';

export const useContainerActions: MapActions<Model> = (mapActions) => useStoreActions((actions) => mapActions(actions.expediente.state.containers));
export const useContainerState: MapState<Model> = (mapState) => useStoreState((state) => mapState(state.expediente.state.containers));

export const useContainerController = (idx: number) => {
  const data = useContainerState(state => state.data)
  const boxes = useContainerState(state => state.boxes)
  const all = useContainerState(state => state.list)
  const setData = useContainerActions(actions => actions.setData)
  const setBoxes = useContainerActions(actions => actions.setBoxes)
  const setAll = useContainerActions(actions => actions.setList)
  const [focus, setFocus] = useState<[number, keyof typeof data[0]]>([0, 'cont_id'])
  const [loading, setLoading] = useState(false)

  const [index, field] = focus

  const setDataAt = useCallback((index: number, values: typeof data[0]) => (
    setData({ index, data: values })
  ), [setData])

  const setBoxesAt = useCallback((index: number, values: typeof boxes[0]) => (
    setBoxes({ index, data: values })
  ), [setBoxes])

  const removeDataAt = useCallback((index: number) => {
    setAll(genericRemoveAt(all)(index))
  }, [all, setAll])

  const addData = useCallback(() => {
    setAll(_.concat(all, createDefaultContainer()))
  }, [setAll, all])

  const mapFocusData = useCallback(async (value: StringValue) => {
    const text = _.trim(_.toString(value))
    if (_.isEmpty(text)) return

    const updateFn = getContainerMappings(field)
    const newValue = await updateFn(value)
    if (_.isNil(newValue)) return

    onChangeGenericData(_.get(index, data), (d) => setDataAt(index, d))(field)(newValue)
  }, [field, index, setDataAt, data])

  const mapFocusBoxes = useCallback((value: BoxValue) => {
    if(_.isNil(value)) return

    setBoxesAt(index, _.pipe(_.get(index), _.set(field, _.set('fileIndex', idx, value)))(boxes))
  }, [field, index, setBoxesAt, boxes, idx])

  const updateFocusState = useCallback(async (text: string, box: BoxValue) => {
    setLoading(true)
    await mapFocusData(text)
    mapFocusBoxes(box)
    setLoading(false)
  }, [mapFocusData, mapFocusBoxes])

  const boxesList = useMemo(() => {
    const box = _.get([index, field], boxes)
    if(box?.fileIndex !== idx) return []

    return [box]
  }, [boxes, index, field, idx])

  return {
    loading,
    focus: {
      value: focus,
      set: setFocus,
      updateState: updateFocusState
    },
    boxes: {
      list: boxes,
      current: boxesList
    },
    data: {
      list: data,
      setAt: setDataAt,
      removeAt: removeDataAt,
      add: addData
    }
  }
}
