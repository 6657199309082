import _ from 'lodash/fp';
import React from 'react';
import { useBookingState } from '../../hooks/booking';
import { HBLData, PackageData } from '../../types';
import { dateToString } from '../../utils/date';
import Card from '../Card';
import CardHeader from '../CardHeader';
import ScrollableList from '../ScrollableList';
import SummaryBox from './SummaryBox';
import SummaryField from './SummaryField';
import SummaryFileList from './SummaryFileList';
import SummaryHeader from './SummaryHeader';
import SummarySubHeader from './SummarySubHeader';
import { SummaryPackagesTable } from './SummaryTable';

type SummaryBookingProps = {
  files: File[]
  hbl: HBLData,
  packages: PackageData[]
  onClickFile?: (file: File) => void
}

const SummaryBooking: React.FunctionComponent<SummaryBookingProps> = ({ files, hbl, packages, onClickFile }) => {
  return (
    <div className="tw-p-4 tw-flex-1">
      <SummaryBox>
        <SummarySubHeader text="Ficheros" />
        <SummaryFileList files={files} onClick={onClickFile} />
      </SummaryBox>
      <SummaryBox>
        <SummarySubHeader text="HBL" />
        <SummaryField label={`Nº de HBL`} value={_.toString(hbl.bl_num)} />
        <SummaryField label={`Shipper`} value={_.toString(hbl.shipper?.label)} />
        <SummaryField label={`Consignee`} value={_.toString(hbl.consignee?.label)} />
        <SummaryField label={`Notify`} value={_.toString(hbl.notify?.label)} />
        <SummaryField label={`Referencia Cliente`} value={_.toString(hbl.client_reference)} />
        <SummaryField label={`Origen`} value={_.toString(hbl.place_receipt?.label)} />
        <SummaryField label={`Destino final`} value={_.toString(hbl.place_delivery?.label)} />
        <SummaryField label={`Flete`} value={_.toString(hbl.freight)} />
        <SummaryField label={`Emisión B/L `} value={_.toString(hbl.seawaybill?.label)} />
        <SummaryField label={`On hold`} value={hbl.on_hold ? "SI" : "NO"} />
        <SummaryField label={`ETD del B/L`} value={dateToString(hbl.onboard)} />
        <SummaryField label={`Contenedores`} value={_.join(', ', _.map('label', hbl.containers))} />
        <SummaryField label={`Notas internas`} value={_.toString(hbl.internal_comments)} />
      </SummaryBox>
      <SummaryBox>
        <SummarySubHeader text="Packages" />
        <Card>
          <SummaryPackagesTable packages={packages} />
        </Card>
      </SummaryBox>
    </div >
  )
}

type Props = {
  onClickFile?: (file: File) => void
}

const SummaryBookings: React.FunctionComponent<Props> = ({ onClickFile }) => {
  const bookings = useBookingState(state => state.list)
  return (
    <div className="tw-h-full tw-flex tw-flex-col">
      <SummaryHeader text='Bookings' />
      <ScrollableList className="tw-p-4">
        {bookings.map((booking, index) => (
          <Card key={index}>
            <CardHeader>{`Booking ${index + 1}`}</CardHeader>
            <SummaryBooking
              onClickFile={onClickFile}
              files={_.pipe(_.map('file'), _.compact)(booking.docs)}
              hbl={booking.state.hbl.data}
              packages={_.map('data', booking.state.packages)}
            />
          </Card>
        ))}
      </ScrollableList >
    </div>
  )
}


export default SummaryBookings
