import _ from 'lodash/fp';
import { action, computed, thunk } from 'easy-peasy';

import { Actions, Model, State } from '../types/page';
import { createDefaultPage, filterBookingPages, filterExpedientePages, setPageFile } from '../../utils/page';

const modelState: State = {
  pages: [],
  totalPages: computed([
    state => state.pages,
  ], _.size),
  bookingPages: computed([
    state => state.pages,
  ], filterBookingPages),
  expedientePages: computed([
    state => state.pages,
  ], filterExpedientePages)
};

const modelActions: Actions = {
  clear: thunk((actions) => {
    actions.setPages([])
  }),
  setPages: action((state, payload) => {
    state.pages = payload
  }),
  setPage: action((state, payload) => {
    state.pages[payload.index] = payload.data
  }),
  splitFiles: thunk(async (actions, payload, { injections })=> {
    const { fileService } = injections
    const files = await Promise.all(_.map(fileService.splitFiles, payload))
    const pages = _.map((file) => setPageFile(file, createDefaultPage()), _.flatten(files))  

    actions.setPages(pages);
    return pages
  })
};

const model: Model = {
  ...modelState,
  ...modelActions,
};

export default model;
