import { StoreProvider } from 'easy-peasy';
import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Authenticated from './components/Authenticated';
import DocumentTitle from './components/DocumentTitle';
import WaitForStateRehydration from './components/WaitForStateRehydration';
import { useOnlineStatus } from './hooks/utils';
import store from './store';
import { showErrorAlert } from './utils/alert';
import routes from './utils/constants/routes';
import { getPath } from './utils/routes';
import BookingView from './views/Booking';
import BookingPackagesView from './views/BookingPackages';
import ClassifyView from './views/Classify';
import ExpedienteView from './views/Expediente';
import ExpedienteContainersView from './views/ExpedienteContainers';
import Loading from './views/Loading';
import LoginView from './views/Login';
import NotFound from './views/NotFound';
import SummaryView from './views/Summary';
import UploadFilesView from './views/UploadFiles';

const Root: React.FunctionComponent = () => {
  const isOnline = useOnlineStatus()

  useEffect(() => {
    if (!isOnline) {
      showErrorAlert("No hay conexión a Internet")
    }
  }, [isOnline])

  return (
    // @ts-ignore
    <StoreProvider store={store}>
      <WaitForStateRehydration loading={<Loading />}>
        <main className="tw-max-h-screen tw-max-w-screen tw-h-screen tw-w-screen">
          <BrowserRouter>
            <DocumentTitle />
            <Routes>
              <Route
                path={getPath(routes.login)}
                element={<LoginView />}
              />
              <Route
                path={getPath(routes.root)}
                element={
                  <Authenticated>
                    <UploadFilesView />
                  </Authenticated>
                } />
              <Route
                path={getPath(routes.classify)}
                element={
                  <Authenticated>
                    <ClassifyView />
                  </Authenticated>
                } />
              <Route
                path={getPath(routes.expediente)}
                element={
                  <Authenticated>
                    <ExpedienteView />
                  </Authenticated>
                } />
              <Route
                path={getPath(routes.expediente_containers)}
                element={
                  <Authenticated>
                    <ExpedienteContainersView />
                  </Authenticated>
                } />
              <Route
                path={getPath(routes.booking)}
                element={
                  <Authenticated>
                    <BookingView />
                  </Authenticated>
                } />
              <Route
                path={getPath(routes.booking_packages)}
                element={
                  <Authenticated>
                    <BookingPackagesView />
                  </Authenticated>
                } />
              <Route
                path={getPath(routes.summary)}
                element={
                  <Authenticated>
                    <SummaryView />
                  </Authenticated>
                } />
              <Route 
                path={"*"}
                element={<NotFound />} 
              />
            </Routes>
          </BrowserRouter>
        </main>
      </WaitForStateRehydration>
    </StoreProvider>
  );
};

export default Root;
