import React from 'react';

type Props = {
  text: string
}

const SummaryHeader: React.FunctionComponent<Props> = ({ text }) => {
  return (
    <div className="tw-py-2 tw-px-4 tw-text-lg tw-font-bold tw-text-white tw-bg-gray-800">
      {text}
    </div>
  )
}


export default SummaryHeader
