import _ from 'lodash/fp';
import React, { useState } from 'react'
import PDFViewerBottomBar from './PDFViewerBottomBar';
import { PDFContext, usePDF } from '../hooks/pdf';
import PDFViewerHeader from './PDFViewerHeader';
import AlertContainer from './AlertContainer';
import 'pdfjs-dist/web/pdf_viewer.css';
import { BoxValue, FileValue, StringValue } from '../types';
import PDFSelectionLayer from './PDFSelectionLayer';
import PDFBoxLayer from './PDFBoxLayer';

type Props = {
  src: FileValue;
  title?: StringValue;
  onSelection?: (text: string, box: BoxValue) => void,
  boxes?: BoxValue[]
};

const PDFViewer: React.FunctionComponent<Props> = ({ src, title, onSelection, boxes}) => {
  const { viewer, container, error } = usePDF(src)
  const [show, setShow] = useState(true)

  return (
    <PDFContext.Provider value={{
      pdfViewer: viewer,
      pdfContainer: container
    }}>
      <div className="tw-relative tw-flex tw-flex-col tw-w-full tw-h-full tw-bg-gray-600">
        <PDFViewerHeader
          title={_.isNil(title) ? _.toString(src?.name) : title}
          showButton={!_.isEmpty(boxes)}
          isActive={show}
          onClickButton={() =>  setShow(s => !s)}
        />
        <div className="tw-relative tw-w-full tw-h-full">
          <div
            className="tw-absolute tw-overflow-auto tw-w-full tw-h-full"
            ref={container}
          >
            <PDFBoxLayer boxes={show ? boxes ?? [] : []}>
              <PDFSelectionLayer
                onSelection={onSelection}
              >
                <div className="pdfViewer" />
              </PDFSelectionLayer>
            </PDFBoxLayer>
          </div>
          {!_.isNil(error) && (
            <AlertContainer message={error.message} />
          )}
        </div>
        <PDFViewerBottomBar />
      </div>
    </PDFContext.Provider>
  );
}

export default PDFViewer
