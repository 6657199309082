import _ from "lodash/fp"
import { useCallback, useEffect, useMemo, useState } from "react"
import { NumberValue, Page, SelectValue } from "../types"
import { docDataType } from "../utils/constants/docTypes"
import { getDocDataType, isExpediente, isHBL, isMBL } from "../utils/docTypes"
import { getDocumentTypesList } from "../utils/masterData"
import { filterBookingPages, groupPagesByBookingIndex, groupPagesByDoc, isPageBooking, isPageComplete, isPageExpediente } from "../utils/page"
import { useDocumentTypeActions, useDocumentTypeState } from "./documentType"
import { usePageActions, usePageState } from "./page"
import { usePagination } from "./pagination"


const dataType = getDocDataType()

type ClassifyState = Omit<Page, 'file'>

export const usePagePagination = (pages: Page[]) => {
  const [currentPages, { page, total }, { next, prev, goto }] = usePagination(pages, 1)

  const currentPage = _.first(currentPages) as Page

  return {
    index: page - 1,
    page: currentPage,
    total,
    control: {
      next,
      prev,
      goto
    },
  }
}

const DEFAULT_NUM_BOOKINGS = 1

const createDefaulState = (): ClassifyState => ({
  type: dataType.EXP,
  doc: null,
  bookingIndex: null
})

const setPageFromState = (state: ClassifyState, page: Page): Page => (
  _.pipe(
    _.set('type', state.type),
    _.set('doc', state.doc),
    _.set('bookingIndex', state.bookingIndex),
  )(page)
)

const generateStateFromPage = (page: Page): ClassifyState => (
  _.pipe(
    _.set('type', page.type),
    _.set('doc', page.doc),
    _.set('bookingIndex', page.bookingIndex)
  )(createDefaulState())
)

const isStateComplete = (state: ClassifyState) => isPageComplete(state as Page)
const isModified = (state: ClassifyState, page: Page) => {
  let keys: (keyof ClassifyState)[] = []
  if (isPageExpediente(page)) {
    keys = ['type', 'doc']
  } else if (isPageBooking(page)) {
    keys = ['type', 'doc', 'bookingIndex']
  }

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i]
    if (!_.equals(_.get(key, page), _.get(key, state))) return true;
  }
  return false
}

const getMaxBookingIndex = (pages: Page[]) => (
  _.pipe(
    filterBookingPages,
    _.countBy('bookingIndex'),
    _.keys,
    _.map(_.toInteger),
    _.max,
  )(pages) as number | undefined
)

export const useClassify = () => {
  const pages = usePageState(state => state.pages)
  const totalPages = usePageState(state => state.totalPages)
  const storePage = usePageActions(actions => actions.setPage)
  const { index, page, control, total } = usePagePagination(pages)

  const [localState, setLocalState] = useState<ClassifyState>(createDefaulState())
  const [bookings, setBookings] = useState(DEFAULT_NUM_BOOKINGS)

  const setType = useCallback((value: SelectValue) => {
    setLocalState(s => {
      const newState = _.set('type', value, s)
      if(!isExpediente(value)) return newState

      return _.set('bookingIndex', null, newState)
    })
  }, [])

  const setDoc = useCallback((value: SelectValue) => {
    setLocalState(s => _.set('doc', value, s))
  }, [])

  useEffect(
    () => {
      if(isMBL(localState.doc)) setType(docDataType.EXP)

      if(isHBL(localState.doc)) setType(docDataType.BKN)
      
    }, [localState.doc, setType]
  )

  const setBooking = useCallback((value: NumberValue) => {
    setLocalState(s => _.set('bookingIndex', value, s))
  }, [])

  const addBooking = useCallback(() => {
    setBookings(b => {
      setBooking(b)
      return b + 1
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const persist = useCallback(() => {
    storePage({ index, data: setPageFromState(localState, page) })
    if (isPageComplete(page)) return

    control.next?.()
  }, [index, localState, page, control, storePage])

  useEffect(() => {
    if (!isPageComplete(page)) return

    setLocalState(generateStateFromPage(page))
  }, [page])

  const maxIndex = useMemo(() => getMaxBookingIndex(pages), [pages])

  useEffect(() => {
    setBookings(_.isNil(maxIndex) ? DEFAULT_NUM_BOOKINGS : maxIndex + 1)
  }, [maxIndex])

  const lastIndexCompleted = useMemo(() => _.findLastIndex(isPageComplete, pages), [pages])

  const isPersistEnabled = useMemo(() => (
    isStateComplete(localState) && (!isPageComplete(page) || isModified(localState, page)
    )), [localState, page])

  const isAddbookingEnabled = useMemo(() => (
    !_.isNil(maxIndex) && maxIndex + 1 === bookings && totalPages > bookings
  ), [maxIndex, bookings, totalPages])

  const isNextEnabled = useMemo(() => isPageComplete(page), [page])

  const isCompleted = useMemo(() => _.all(isPageComplete, pages), [pages])

  return {
    isCompleted,
    pages: {
      list: pages,
      index,
      current: page,
      total,
      lastCompleted: lastIndexCompleted
    },
    control: {
      next: isNextEnabled ? control.next : undefined,
      prev: control.prev,
      goto: control.goto,
      persist: isPersistEnabled ? persist : undefined,
      addBooking: isAddbookingEnabled ? addBooking : undefined
    },
    document: {
      value: localState.doc,
      set: setDoc,
    },
    type: {
      value: localState.type,
      set: setType,
    },
    booking: {
      total: bookings,
      value: localState.bookingIndex,
      set: setBooking
    }
  }
}

const mapToDocData = _.curry((indexed: Record<string, number>, docs: Page[]) => ({
  ..._.first(docs)?.doc,
  pages: _.map((doc) => _.get(_.toString(doc.file?.name), indexed), docs)
}))

export const useClassifyStatus = () => {
  const pages = usePageState(state => state.pages)
  const bookings = usePageState(state => state.bookingPages)
  const expediente = usePageState(state => state.expedientePages)

  const indexedPagesByName = useMemo(() => (
    pages.reduce((acc: Record<string, number>, page, index) => {
      if (_.isNil(page.file)) return acc

      return _.set(page.file.name, index, acc)
    }, {})
  ), [pages])

  const mapDocs = useCallback((pages: Page[]): ReturnType<typeof mapToDocData>[] => (
    _.pipe(
      groupPagesByDoc,
      _.values,
      _.map(mapToDocData(indexedPagesByName))
    )(pages)
  ), [indexedPagesByName])

  const expedienteGrouped = useMemo(() => mapDocs(expediente), [expediente, mapDocs])

  const bookingsGrouped = useMemo(() => {
    const grouped = groupPagesByBookingIndex(bookings)
    const max = _.pipe(
      _.keys,
      _.maxBy(_.toInteger)
    )(grouped)

    if (_.isNil(max)) return []

    return _.map((index) => mapDocs(_.get(index, grouped)), _.range(0, _.toInteger(max) + 1))
  }, [bookings, mapDocs])

  return {
    bookings: bookingsGrouped,
    expediente: expedienteGrouped
  }
}

export const useDocumentTypes = () => {
  const [loading, setLoading] = useState(false)
  const setDocuments = useDocumentTypeActions(actions => actions.setDocuments)
  const main = useDocumentTypeState(state => state.mainDocuments)
  const other = useDocumentTypeState(state => state.otherDocuments)

  useEffect(() => {
    (async () => {
      setLoading(true)
      const documents = await getDocumentTypesList()
      setDocuments(documents)
      setLoading(false)
    })()
  }, [setDocuments])

  return {
    main,
    other,
    loading
  }
}
