import classNames from 'classnames';
import _ from 'lodash/fp';
import React from 'react';
import Card from '../components/Card';
import ExpedienteContainerDataFiller from '../components/ExpedienteContainerDataFiller';
import FileList from '../components/FileList';
import Layout from '../components/Layout';
import { useBookingState } from '../hooks/booking';
import { useExpedienteActions, useExpedienteState } from '../hooks/expediente';
import { useSelect } from '../hooks/list';
import { useRedirect } from '../hooks/redirect';
import { showWarningAlert } from '../utils/alert';
import routes from '../utils/constants/routes';
import { maybeAlertContainerISO, maybeAlertContainerNumber } from '../utils/container';
import { handleError } from '../utils/errors';


const ExpedienteContainers: React.FunctionComponent = () => {
  const files = useExpedienteState(state => state.files)
  const isComplete = useExpedienteState(state => state.state.containers.isComplete)
  const isHBLReady = useBookingState(state => state.isReady)
  const containers = useExpedienteState(state => state.state.containers.data)
  const mbl = useExpedienteState(state => state.state.mbl.data)
  const sendThebox = useExpedienteActions(actions => actions.sendTheBox)
  const { current, index, changeIndex } = useSelect(files)
  const redirect = useRedirect()

  const hasFiles = !_.isEmpty(files)
  const layoutClass = classNames(
    "tw-w-full tw-gap-2 tw-grid tw-p-2",
    {
      "tw-grid-cols-[1fr,4fr,2fr]": hasFiles,
      "tw-grid-cols-[4fr,2fr]": !hasFiles
    }
  )

  const handleNext = async () => {
    const isoValid = _.all(maybeAlertContainerISO, containers)
    const numValid = maybeAlertContainerNumber(mbl, containers)

    if (!isoValid || !numValid) return

    if (!isHBLReady) {
      showWarningAlert("Por favor, espere. Aún no se ha procesado el primer HBL")
      return
    }

    await sendThebox()
    redirect(routes.booking)
  }

  return (
    <Layout onClickNext={handleError(handleNext)} isNextDisabled={!isComplete}>
      <div className={layoutClass}>
        {hasFiles && (
          <Card>
            <FileList files={files} onClick={changeIndex} selected={index} />
          </Card>
        )}
        <ExpedienteContainerDataFiller
          file={current}
          index={index}
        />
      </div>
    </Layout >
  );
};

export default ExpedienteContainers;
