import React from 'react';
import ScrollWrapper from './ScrollWrapper';

type Props = React.PropsWithChildren<{
  className?: string;
}>;

const ScrollableList: React.FunctionComponent<Props> = ({ children, className = '' }: Props) => {
  return (
    <ScrollWrapper>
      <div className="tw-overflow-auto tw-h-full tw-w-full">
        <ul className={`tw-grid tw-gap-2 ${className}`}>
          {children}
        </ul>
      </div>
    </ScrollWrapper>
  );
};

export default ScrollableList;
