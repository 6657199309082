import { InputGroup, Label } from '@blueprintjs/core';
import { TextArea } from '@blueprintjs/core';
import _ from 'lodash/fp';
import React, { useEffect, useState } from 'react';
import { StringValue } from '../../types';
import { handleStringChange } from '../../utils/events';
import ResetButton from './ResetButton';

type ConditionalProps = {
  large?: false
  forceNumberChars?: boolean
} | {
  large?: true,
  forceNumberChars?: never
}

type CommonProps = {
  maxLength?: number
  label: React.ReactNode;
  value: StringValue;
  onChange: (value: StringValue) => void;
  onFocusInput?: () => void;
  large?: boolean
}

type Props = CommonProps & ConditionalProps;

const Text: React.FunctionComponent<Props> = ({
  label,
  value,
  onChange,
  onFocusInput = _.noop,
  large = false,
  maxLength,
  forceNumberChars = false
}: Props) => {
  const [text, setText] = useState(value)

  useEffect(() => {
    setText(value)
  }, [value])

  const resetInput = () => {
    setText(null)
    onChange(null)
  }


  return (
    <Label className="tw-font-medium" style={{ marginBottom: 0 }}>
      {label}
      {large ? (
        <div className="tw-flex tw-flex-row tw-mt-1">
          <TextArea
            style={{ marginTop: 0 }}
            growVertically={true}
            fill
            maxLength={maxLength}
            onFocus={onFocusInput}
            onChange={handleStringChange(setText)}
            value={_.toString(text)}
            onBlur={() => onChange(text)}
          />
          {!_.isEmpty(text) && (
            <div className="tw-ml-1" >
              <ResetButton onClick={resetInput} />
            </div>
          )}
        </div>
      ) : (
        <InputGroup
          className="tw-mt-1"
          fill
          type={forceNumberChars ? 'number' : 'text'}
          maxLength={maxLength}
          onFocus={onFocusInput}
          value={_.toString(text)}
          onChange={handleStringChange(setText)}
          onBlur={() => onChange(text)}
          rightElement={!_.isEmpty(text) ? <ResetButton onClick={resetInput} /> : undefined}
        />
      )}
    </Label>
  );
};

export default Text;
