import React from 'react';
import _ from 'lodash/fp';
import { Button, Intent } from '@blueprintjs/core';
import { SelectValue } from '../../types';
import classNames from 'classnames';

type Props = {
  value: SelectValue;
  options: SelectValue[];
  onChange: (value: SelectValue) => void;
  direction?: 'vertical' | 'horizontal',
  disabled?: boolean,
  onFocus?: () => void
};

const ButtonInput: React.FunctionComponent<Props> = ({ 
  options, value, onChange, direction = 'horizontal', disabled = false, onFocus
}: Props) => {
  const directionClass = classNames(
    "tw-grid tw-gap-2 tw-w-full",
    {
      "tw-grid-cols-[repeat(auto-fit,minmax(150px,1fr))] tw-grid-flow-cols": direction === 'horizontal',
      "tw-grid-flow-row": direction === 'vertical'
    }
  )
  return (
    <div className={directionClass}>
      {_.map(
        (option) => (
          <Button
            tabIndex={-1}
            disabled={disabled}
            onFocus={onFocus}
            key={`${option?.id}_${option?.label}`}
            intent={value?.id === option?.id ? Intent.SUCCESS : Intent.PRIMARY}
            onClick={() => onChange(option)}
          >
            {option?.label}
          </Button>
        ),
        options
      )}
    </div>
  );
};

export default ButtonInput;
