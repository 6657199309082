import _ from "lodash/fp";
import { MapFnOf, PackageData, RequiredOf } from "../../types";
import { findIMOByLabel } from "../imo";
import { getPackageAsSelectValue } from "../masterData";
import { stringToInteger, stringToNumber } from "../number";

export const mappings: MapFnOf<PackageData> = {
  packages: stringToInteger,
  packages_type: _.flow(_.toString, getPackageAsSelectValue),
  weight: stringToNumber,
  volume: stringToNumber,
  marks: _.identity,
  description: _.identity,
  multiple_hscode: _.constant(true),
  hscode: _.identity,
  aditional_hscode: _.identity,
  imo: findIMOByLabel,
  art_15: _.constant(true),
  un_number: _.identity,
  class_number: _.identity,
  packaging_group: _.identity,
  containers: _.constant([]),
};

export const required: RequiredOf<PackageData> = new Set([
  "packages",
  "packages_type",
  "weight",
  "volume",
  "marks",
  "description",
] as (keyof PackageData)[]);

export const maxAditionalHSCODELength = 100;
