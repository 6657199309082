export const api = {
  login: '/login',
  file: '/files',
  player: '/players',
  masterData: '/masterdata',
  tenant: '/tenants',
  bill_of_lading: '/billoflading'
};

export const thebox = {
  extract: '/extract_bl',
  validate: '/validate_bl',
};
