import _ from "lodash/fp";
import { SelectValue } from "../types";
import imoTypes, { imoList } from "./constants/imo";
import { findById, findByLabel } from "./select";

export const getIMOTypes = () => imoTypes
export const getIMOList = () => imoList

export const isSI = (value: SelectValue) => imoTypes.SI?.id === value?.id
export const isNO = (value: SelectValue) => imoTypes.NO?.id === value?.id

export const findIMOById = findById(_.values(imoTypes))
export const findIMOByLabel = findByLabel(_.values(imoTypes))
