import { AxiosResponse } from 'axios';
import { pdfBase64ToFile } from '../utils/file';
import client from './apiClient'
import { v4 as uuidv4 } from 'uuid';
import { api } from './endpoints';

const createForm = (key: string, files: File[]) => {
  const form: FormData = new FormData();
  for (let i = 0; i < files.length; i++) {
    form.append(key, files[i])
  }
  return form
}

const createFilesForm = (files: File[]) => {
  return createForm('files', files)
}

const createFileForm = (file: File) => {
  return createForm('file', [file])
}

export const splitFiles = async (file: File): Promise<File[]> => {
  const response: AxiosResponse<string[]> = await client.post(
    `${api.file}/split`,
    createFileForm(file)
  )
  const data = response.data.map((pdf, index) => pdfBase64ToFile(pdf, uuidv4()))
  return Promise.all(data)
}

export const mergeFiles = async (name: string, files: File[]): Promise<File> => {
  const response: AxiosResponse<string> = await client.post(
    `${api.file}/merge`,
    createFilesForm(files)
  )
  return pdfBase64ToFile(response.data, name)
}
