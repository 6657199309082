import React from 'react';
import _ from 'lodash/fp';
import { Label } from '@blueprintjs/core';
import { SelectValue } from '../../types';
import ButtonInput from './ButtonInput';
import SelectInput from './SelectInput';

type Props = {
  label: React.ReactNode;
  value: SelectValue;
  options: SelectValue[];
  defaultOptions?: SelectValue[];
  onChange: (value: SelectValue) => void;
  onFocusInput?: () => void;
  minChars?: number
};

const Select: React.FunctionComponent<Props> = ({
  label,
  options,
  value,
  onChange,
  defaultOptions,
  onFocusInput = _.noop,
  minChars
}: Props) => {
  return (
    <Label className="tw-font-medium" style={{ marginBottom: 0 }}>
      {label}
      <div className="tw-mt-1">
        <SelectInput
          value={value}
          options={options}
          onChange={onChange}
          onFocusInput={onFocusInput}
          minChars={minChars}
        />
      </div>
      {!_.isEmpty(defaultOptions) && (
        <div className="tw-mt-2">
          <ButtonInput
            options={defaultOptions!}
            value={value}
            onChange={onChange}
          />
        </div>
      )}
    </Label>
  );
};

export default Select;
