import React from 'react';
import _ from 'lodash/fp';
import Select, { components, MenuPlacement} from 'react-select';
import { SelectValue } from '../../types';
import { preventDefaultTrigger } from '../../utils/events';

export type Props = {
  value: SelectValue;
  options: SelectValue[];
  onChange: (value: SelectValue) => void;
  onFocusInput?: () => void;
  minChars?: number;
};

export const DEFAULT_MIN_CHARS = 2;

export const customStyles = {
  control: (base: any) => ({
    ...base,
    minHeight: 30,
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    padding: 4,
  }),
  clearIndicator: (base: any) => ({
    ...base,
    padding: 4,
  }),
  valueContainer: (base: any) => ({
    ...base,
    padding: '0px 6px',
  }),
  singleValue: (base: any) => ({
    ...base,
    fontWeight: 'normal',
  }),
  input: (base: any) => ({
    ...base,
    margin: 0,
    padding: 0,
  }),
  placeholder: (base: any) => ({
    ...base,
    fontWeight: 'normal',
  }),
  menu: (base: any) => ({
    ...base,
    zIndex: 100,
  }),
  menuPortal: (base: any) => ({
    ...base,
    zIndex: 100,
  }),
};

export const Option = (props: any) => {
  const { innerProps } = props;
  const { onClick } = innerProps;
  props.innerProps.onClick = preventDefaultTrigger(onClick);

  return <components.Option {...props} />;
};

export const DEFAULT_PROPS = {
  menuPortalTarget: document.body,
  menuPlacement: 'auto' as MenuPlacement,
  isSearchable: true,
  isClearable: true,
  menuShouldBlockScroll: true,
  components: { Option },
  noOptionsMessage: () => 'Sin resultados',
};

const SelectInput: React.FunctionComponent<Props> = ({
  options,
  value,
  onChange,
  onFocusInput = _.noop,
  minChars = DEFAULT_MIN_CHARS,
}: Props) => {
  const [showList, setShowList] = React.useState(minChars === 0);

  const onInputChange = (query: string) => {
    setShowList(_.trim(query).length >= minChars);
  };

  return (
    <Select
      {...DEFAULT_PROPS}
      styles={customStyles}
      onFocus={onFocusInput}
      value={value}
      onChange={onChange}
      getOptionValue={(option) => option?.id}
      getOptionLabel={(option) => option?.label}
      options={showList ? (options as []) : []}
      onInputChange={onInputChange}
      placeholder={minChars > 0 ? `Buscar (min ${minChars} caracteres)`: 'Selecciona...'}
    />
  );
};

export default SelectInput;
