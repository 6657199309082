import { action, computed, thunk } from "easy-peasy";
import _ from "lodash/fp";
import { showInfoAlert, showWarningAlert } from "../../utils/alert";
import { generateContainerListFromTheBox, generateTheBoxFromContainers } from "../../utils/container";
import { toSelectValueList } from "../../utils/docTypes";
import { fileToDocument, getMBLDocument, sortDocumentsByMBL } from "../../utils/document";
import { generateMBLFromTheBox, generateTheBoxFromMBL } from "../../utils/mbl";
import { groupFilesByDoc } from "../../utils/page";
import { findById } from "../../utils/select";
import containerModel from '../models/container';
import mblModel from '../models/mbl';
import { Actions, Model, State } from "../types/expediente";

const modelState: State = {
  docs: [],
  extracted: undefined,
  files: computed([
    state => state.docs
  ], _.flow(_.map('file'), _.compact)),
  hasMBL: computed([
    state => state.docs
  ], _.flow(getMBLDocument, _.negate(_.isEmpty))),
  state: {
    mbl: mblModel,
    containers: containerModel
  }
};

const modelActions: Actions = {
  clear: thunk((actions) => {
    actions.setDocs([])
    actions.setExtracted(undefined)
    actions.state.mbl.clear()
    actions.state.containers.clear()
  }),
  setDocs: action((state, payload) => {
    state.docs = payload
  }),
  setExtracted: action((state, payload) => {
    state.extracted = payload
  }),
  mergePages: thunk(async (actions, payload, { injections, getStoreState }) => {
    const { fileService } = injections
    const state = getStoreState()

    const grouped = groupFilesByDoc(payload)
    const options = toSelectValueList(state.documentType.documents)
    const findDocType = findById(options)
    const toDocument = fileToDocument(options)

    const promises = _.map((key) => {
      const docType = findDocType(key)
      const files = _.get(key, grouped)
      return fileService.mergeFiles(_.toString(docType?.label), files)
    }, _.keys(grouped))

    const files = await Promise.all(promises)

    const documents = sortDocumentsByMBL(_.map(toDocument, files))

    actions.setDocs(documents)
    return documents
  }),
  extractData: thunk(async (actions, payload, { injections, getStoreState }) => {
    const { theboxService } = injections
    const tenant = getStoreState().session.tenant

    const mbl = getMBLDocument(payload)
    if (_.isNil(mbl?.file)) return

    showInfoAlert("Extrayendo MBL. Por favor ten paciencia...")
    const data = await theboxService.extract(mbl!.file, tenant?.id);

    actions.setExtracted(data)
    await actions.generateData(data)
    if(_.isUndefined(data)) showWarningAlert("No se han podido extraer datos")

  }),
  generateData: thunk(async (actions, payload) => {
    const mbl = await generateMBLFromTheBox(payload)
    const containers = await generateContainerListFromTheBox(payload)
    actions.state.mbl.setData(mbl.data)
    actions.state.mbl.setBoxes(mbl.boxes)
    actions.state.containers.setList(containers)
  }),
  sendTheBox: thunk(async (a, p, { injections, getState }) => {
    const state = getState()
    const { theboxService } = injections
    const expedienteTheBox = state.extracted
    if (_.isNil(expedienteTheBox)) return

    const mbl = generateTheBoxFromMBL(expedienteTheBox, state.state.mbl)
    const containers = generateTheBoxFromContainers(expedienteTheBox, state.state.containers.list)
    const data = _.merge(mbl?.data, _.pick(['containers'], containers?.data))

    await theboxService.validate(_.set('data', data, mbl!))
  })
};

const model: Model = {
  ...modelState,
  ...modelActions,
};

export default model
