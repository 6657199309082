import { AxiosResponse } from 'axios';
import { StringValue } from '../types';
import { BillOfLading } from '../types/api/bill_of_lading';
import client from './apiClient';
import { api } from './endpoints';

export const saveBillOfLading = async (data: BillOfLading): Promise<StringValue> => {
  const response: AxiosResponse<StringValue> = await client.post(
    api.bill_of_lading,
    data
  )
  return response.data
}
