import { Divider } from '@blueprintjs/core';
import React from 'react';

type Props = {
  text: string
}

const SummarySubHeader: React.FunctionComponent<Props> = ({ text }) => {
  return (
    <div>
      <div className="tw-font-bold tw-text-black">{text}</div>
      <Divider className="tw-mx-0 tw-mb-2" />
    </div>
  )
}


export default SummarySubHeader
