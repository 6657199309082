import { useBookingState } from "./booking"
import { useLocation } from 'react-router-dom'
import { useMemo } from "react"
import routes from "../utils/constants/routes"

export const useMBLNavigation = () => {
  return {
    current: 1,
    total: 1
  }
}

export const useHBLNavigation = () => {
  const current = useBookingState(state => state.currentIndex)
  const total = useBookingState(state => state.list.length)
  return {
    current: current + 1,
    total
  }
}

export const useNavigation = () => {
  const mbl = useMBLNavigation()
  const hbl = useHBLNavigation()
  const { pathname } = useLocation()

  const isMBLActive = useMemo(() => pathname.startsWith(routes.expediente.link), [pathname])
  const isHBLActive = useMemo(() => pathname.startsWith(routes.booking.link), [pathname])
  return {
    mbl: { ...mbl, active: isMBLActive },
    hbl: { ...hbl, active: isHBLActive }
  }
}
