import moment from 'moment';
import 'moment/locale/es'
import _ from 'lodash/fp'
import { isDateValid, isDayInRange } from '@blueprintjs/datetime/lib/esm/common/dateUtils';
import { DateValue, StringValue } from '../types';
import { DEFAULT_FORMAT, DEFAULT_LOCALE, MAX_DATE, MIN_DATE } from './constants/date';

export const getMaxDate = () => MAX_DATE
export const getMinDate = () => MIN_DATE
export const getDefaultFormat = () => DEFAULT_FORMAT
export const getDefaultLocale = () => DEFAULT_LOCALE

const isValid = (date: Date) => (
  !_.isNaN(date?.getTime()) &&
  isDateValid(date) &&
  isDayInRange(date, [MIN_DATE, MAX_DATE])
)

const stringToDateWithDefaultFormat = (s: StringValue) => {
  const date = moment(s, DEFAULT_FORMAT).locale(DEFAULT_LOCALE).toDate();
  if (isValid(date)) return date

  return null
}

const stringToDateWithoutFormat = (s: StringValue) => {
  const defaultFormat = moment(s).locale(DEFAULT_LOCALE).toDate();
  if (isValid(defaultFormat)) return defaultFormat

  return null
}

export const stringToDate = (s: StringValue) => (
  stringToDateWithoutFormat(s) ??
  stringToDateWithDefaultFormat(s)
)

export const dateToString = (d: DateValue) => {
  if (_.isNil(d)) return ""

  return moment(d).locale(DEFAULT_LOCALE).format(DEFAULT_FORMAT)
}

export const dateToISO = (d: DateValue) => {
  if (_.isNil(d)) return null

  return moment(d).format('YYYY-MM-DD[T]HH:mm:ss')
}
