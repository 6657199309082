import React from 'react'
import { useMBLController } from '../hooks/mbl'
import Card from './Card'
import ExpedienteMBLData from './ExpedienteMBLData'
import ExpedienteMBLDataSimple from './ExpedienteMBLDataSimple'
import PDFViewer from './PDFViewer'

type Props = {
  file: File
  index: number
  isSimple: boolean
}


const ExpedienteMBLDataFiller: React.FunctionComponent<Props> = ({ file, index, isSimple }) => {
  const { data, focus, boxes } = useMBLController(index, isSimple)

  return (
    <>
      <Card>
        <PDFViewer 
          src={file} 
          onSelection={focus.updateState}
          boxes={boxes.current}
        />
      </Card>
      <Card>
        {isSimple ? (
          <ExpedienteMBLDataSimple
            data={data.state}
            onChangeData={data.set}
            focus={focus.value}
            onFocusField={focus.set}
          />
        ) : (
          <ExpedienteMBLData
            data={data.state}
            onChangeData={data.set}
            focus={focus.value}
            onFocusField={focus.set}
          />
        )
        }
      </Card>
    </>
  )
}

export default ExpedienteMBLDataFiller
