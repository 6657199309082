import classNames from "classnames";
import _ from "lodash/fp";
import React from "react";
import BookingPackageDataFiller from "../components/BookingPackageDataFiller";
import Card from "../components/Card";
import FileList from "../components/FileList";
import Layout from "../components/Layout";
import { useBookingActions, useBookingState } from "../hooks/booking";
import { useSelect } from "../hooks/list";
import { useRedirect } from "../hooks/redirect";
import routes from "../utils/constants/routes";
import { handleError } from "../utils/errors";
import {
  maybeAlertPackageAditionalHSCODES,
  maybeAlertPackageHSCODE,
} from "../utils/package";

const BookingPackages: React.FunctionComponent = () => {
  const files = useBookingState((state) => state.files);
  const isComplete = useBookingState(
    (state) => state.isCurrentPackagesComplete
  );
  const packages = useBookingState((state) => state.current.state.packages);
  const next = useBookingActions((actions) => actions.nextBooking);
  const sendThebox = useBookingActions((actions) => actions.sendCurrentTheBox);
  const { current, index, changeIndex } = useSelect(files);
  const redirect = useRedirect();

  const hasFiles = !_.isEmpty(files);
  const layoutClass = classNames("tw-w-full tw-gap-2 tw-grid tw-p-2", {
    "tw-grid-cols-[1fr,4fr,2fr]": hasFiles,
    "tw-grid-cols-[4fr,2fr]": !hasFiles,
  });

  const handleNext = async () => {
    const hscodeValid = _.all(maybeAlertPackageHSCODE, _.map("data", packages));
    const aditionalHscodesValid = _.all(
      maybeAlertPackageAditionalHSCODES,
      _.map("data", packages)
    );
    if (!hscodeValid || !aditionalHscodesValid) return;

    await sendThebox();

    if (next()) {
      redirect(routes.booking);
      return;
    }

    redirect(routes.summary);
  };

  return (
    <Layout onClickNext={handleError(handleNext)} isNextDisabled={!isComplete}>
      <div className={layoutClass}>
        {hasFiles && (
          <Card>
            <FileList files={files} onClick={changeIndex} selected={index} />
          </Card>
        )}
        <BookingPackageDataFiller file={current} index={index} />
      </div>
    </Layout>
  );
};

export default BookingPackages;
