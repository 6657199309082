import { action, computed, thunk } from 'easy-peasy';
import _ from 'lodash/fp';

import { Actions, Model, State } from '../types/session';

const modelState: State = {
  token: null,
  grmUser: null,
  tenant: null,
  isLoggedIn: computed([
    state => state.token,
  ], (token) => !_.isEmpty(token)),
};

const modelActions: Actions = {
  clear: thunk((actions) => {
    actions.setToken(null);
    actions.setGRMUser(null);
    actions.setTenant(null);
  }),
  setToken: action((state, payload) => {
    state.token = payload;
  }),
  setGRMUser: action((state, payload) => {
    state.grmUser = payload
  }),
  setTenant: action((state, payload) => {
    state.tenant = payload
  }),
  login: thunk(async (actions, payload, { injections }) => {
    const { authService } = injections;
    const token = await authService.login(payload);
    actions.setToken(token);
    actions.setGRMUser(payload.grm_user)
    actions.setTenant(payload.tenant)
  }),
  logout: thunk(async (actions) => {
    actions.clear();
  }),
};

const model: Model = {
  ...modelState,
  ...modelActions,
};

export default model;
