import axios, {
  AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse,
} from 'axios';
import _ from 'lodash/fp';

import store from '../store';

const logout = () => store.getActions().session.logout();
const getToken = () => store.getState().session.token;

const isStatusUnauthorized = (status: number) => [401, 403].indexOf(status) !== -1;

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const token = getToken();
  if (_.isEmpty(token)) {
    return config;
  }
  config.headers = _.set('Authorization', `Bearer ${token}`, config.headers ?? {});
  return config;
};
const onRequestError = (error: AxiosError): Promise<AxiosError> => Promise.reject(error);
const onResponse = (response: AxiosResponse): AxiosResponse => response;
const onResponseError = async (error: AxiosError): Promise<AxiosError> => {
  const errorResp = error.response;

  if (!_.isNil(errorResp)) {
    if (isStatusUnauthorized(errorResp.status)) {
      // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
      await logout();

      return Promise.reject(new Error('UNAUTHORIZED'));
    }
    return Promise.reject(new Error(errorResp.data as string));
  }
  // NO response so no internet connection or server not reachable
  return Promise.reject(new Error('CONNECTION_REFUSED'));
};
export const setupInterceptorsTo = (axiosInstance: AxiosInstance): AxiosInstance => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
};

const client = setupInterceptorsTo(axios.create({
  baseURL: '/web',
}));

export default client;
