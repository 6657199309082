import _ from "lodash/fp";
import { ContainerState, MBLData, Rect, SelectValue } from "../types";
import { TheBoxExtractionData } from "../types/api";
import { showErrorAlert } from "./alert";
import { mappings, required } from "./constants/container";
import { getBoundingRect, scaleFrom } from "./coords";
import { isISO6346, isUnknown } from "./crc";
import { getGenericMappedValue, getGenericRequired, getGenericRequiredMark, isGenericDataComplete, scaleAndRoundAllGenericBoxes } from "./data";
import { createDefaultTheBoxContainer } from "./thebox";

export const createDefaultContainerData = (): ContainerState['data'] => ({
  cont_id: null,
  cont_type: null,
  seal_no: null,
  packages: null,
  weight: null,
  volume: null,
  non_operational: true
})

export const createDefaultContainerBoxes = (): ContainerState['boxes'] => ({
  cont_id: null,
  cont_type: null,
  seal_no: null,
  packages: null,
  weight: null,
  volume: null,
  non_operational: null
})

export const getMappings = () => mappings
export const getRequired = () => required

export const getContainerMappings = getGenericMappedValue(getMappings())
export const getContainerRequired = getGenericRequired(getRequired())
export const getContainerRequiredMark = getGenericRequiredMark(getRequired())

export const isContainerDataComplete = isGenericDataComplete(getRequired())

export const generateContainerDataFromTheBox = _.curry(async (extraction: TheBoxExtractionData, index: number) => {
  const data = createDefaultContainerData()
  const thebox = extraction?.data
  if (_.isNil(thebox)) return data

  const contValue = _.get([index, 'value'], thebox.containers)
  if (_.isNil(contValue)) return data

  const containerToValue = (field: keyof typeof contValue) => _.get(field, contValue)

  data.cont_id = await getContainerMappings('cont_id')(containerToValue('cont_id'));
  data.cont_type = await getContainerMappings('cont_type')(containerToValue('cont_type'));
  data.seal_no = await getContainerMappings('seal_no')(containerToValue('seal_no'));
  data.packages = await getContainerMappings('packages')(containerToValue('packages'));
  data.weight = await getContainerMappings('weight')(containerToValue('weight'));
  data.volume = await getContainerMappings('volume')(containerToValue('volume'));
  return data
})

export const generateContainerBoxesFromTheBox = _.curry((extraction: TheBoxExtractionData, index: number) => {
  const boxes = createDefaultContainerBoxes()
  if (_.isNil(extraction)) return boxes

  const canvas = extraction?.canvas;
  const cont = _.get(index, extraction.data.containers)

  if (_.isNil(cont)) return boxes

  const box = _.merge(scaleFrom(cont as Rect, canvas), { page: cont.page, fileIndex: 0 })

  boxes.cont_id = _.clone(box)
  boxes.cont_type = _.clone(box)
  boxes.seal_no = _.clone(box)
  boxes.packages = _.clone(box)
  boxes.weight = _.clone(box)
  boxes.volume = _.clone(box)

  return boxes
})

export const generateContainerFromTheBox = _.curry(async (extraction: TheBoxExtractionData, index: number): Promise<ContainerState> => ({
  data: await generateContainerDataFromTheBox(extraction, index),
  boxes: generateContainerBoxesFromTheBox(extraction, index),
}))

export const createDefaultContainer = (): ContainerState => ({
  data: createDefaultContainerData(),
  boxes: createDefaultContainerBoxes()
})

export const createDefaultContainerList = ():ContainerState[] => []

export const generateContainerListFromTheBox = async (extraction: TheBoxExtractionData) => {
  const containers = extraction?.data.containers;
  if (_.isNil(containers)) return createDefaultContainerList();

  return Promise.all(containers.map((_, index) => generateContainerFromTheBox(extraction, index)))
}

export const maybeAlertContainerISO = (container: ContainerState['data']): boolean => {

  if (isUnknown(_.toString(container.cont_id))) return true

  if (isISO6346(_.toString(container.cont_id))) return true;

  showErrorAlert(`El contenedor ${container.cont_id} no corresponde con el ISO6346`)
  return false;
};

export const maybeAlertContainerNumber = (mbl: MBLData, containers: ContainerState['data'][]) => {
  if (_.equals(mbl.num_containers, _.size(containers))) return true;

  showErrorAlert(`No coincide la cantidad de contenedores (${_.toInteger(mbl.num_containers)}) con los creados (${_.size(containers)})`)
  return false;
};

export const generateTheBoxFromContainers = _.curry((extraction: TheBoxExtractionData, containers: ContainerState[]) => {
  if (_.isNil(extraction)) return

  const thebox = _.cloneDeep(extraction)
  const theboxData = thebox.data
  const scaledBoxesFn = scaleAndRoundAllGenericBoxes(thebox.canvas)

  theboxData.containers = _.map((container) => {
    const boxes = _.pipe(scaledBoxesFn, _.values, _.compact)(container.boxes)
    const page = _.pipe(_.map('page'), _.min, _.toInteger)(boxes)

    const theboxContainer = createDefaultTheBoxContainer()
    theboxContainer.bbox_status = 'MODIFIED'
    theboxContainer.text_status = 'MODIFIED'
    theboxContainer.page = page
    theboxContainer.value = {
      cont_id: container.data.cont_id,
      cont_type: container.data.cont_type?.label,
      volume: _.toString(container.data.volume),
      weight: _.toString(container.data.weight),
      seal_no: container.data.seal_no,
      packages: _.toString(container.data.packages)
    }

    return _.isEmpty(boxes) ? theboxContainer : _.merge(theboxContainer, getBoundingRect(boxes))
  }, containers)

  thebox.data = theboxData
  return thebox
})

export const containerToSelect = (container: ContainerState['data']): SelectValue => ({
  id: _.toString(container.cont_id),
  label: _.toString(container.cont_id),
});

export const containersToSelectList = (data: ContainerState['data'][]) => _.map(containerToSelect, data)

export const isContType40REEFERCONTAINER = (cont_type: SelectValue) => cont_type?.id === '42RT'
