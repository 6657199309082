import _ from 'lodash/fp';
import numeral from 'numeral';
import { NumberValue, StringValue } from '../types';

const splitNumber = (s: StringValue) => {
  const parts = s?.split(/[,.]/);
  if (_.size(parts) > 1) {
    return {
      decimal: _.last(parts),
      integer: _.pipe(_.initial, _.join(''))(parts)
    }
  }
  return {
    decimal: "0",
    integer: _.join('', parts)
  }

}

export const stringToNumber = (s: StringValue) => {
  const { decimal, integer } = splitNumber(s)
  return numeral(_.join('.', [integer, decimal])).value()
};

export const stringToInteger = (s: StringValue) => {
  const { integer } = splitNumber(s)
  return numeral(integer).value()
};

export const numberToString = (n: NumberValue) => _.toString(n);

export const formatNumberWithDecimals = _.curry((decimals: NumberValue, n: NumberValue) => {
  if (_.isNil(decimals)) return numberToString(n).replace('.', ',')

  return n?.toFixed(decimals!).replace('.', ',')
})

export const formatNumberWithCurrency = _.curry((c: StringValue, n: NumberValue) => `${formatNumberWithDecimals(2)(n)} ${_.toString(c)}`)
