import _ from 'lodash/fp';
import { useCallback, useMemo, useState } from 'react';
import { MapActions, MapState } from '../store/types/map';
import { Model } from '../store/types/mbl';
import { BoxValue, StringValue } from '../types';
import { onChangeGenericData } from '../utils/data';
import { getMBLMappings } from '../utils/mbl';
import { useStoreActions, useStoreState } from './store';

export const useMBLActions: MapActions<Model> = (mapActions) => useStoreActions((actions) => mapActions(actions.expediente.state.mbl));
export const useMBLState: MapState<Model> = (mapState) => useStoreState((state) => mapState(state.expediente.state.mbl));

export const useMBLController = (idx: number, isSimple: boolean = false) => {
  const data = useMBLState(state => state.data)
  const boxes = useMBLState(state => state.boxes)
  const setData = useMBLActions(actions => actions.setData)
  const setBoxes = useMBLActions(actions => actions.setBoxes)
  const [loading, setLoading] = useState(false)
  const [focus, setFocus] = useState<keyof typeof data>(isSimple ? 'carrier' : 'bl_num')

  const mapFocusBoxes = useCallback((value: BoxValue) => {
    if(_.isNil(value)) return

    setBoxes(_.set(focus, _.set('fileIndex', idx, value), boxes))
  }, [focus, boxes, setBoxes, idx])

  const mapFocusData = useCallback(async (value: StringValue) => {
    const text = _.trim(_.toString(value))
    if (_.isEmpty(text)) return

    const updateFn = getMBLMappings(focus)
    const newValue = await updateFn(value)
    if (_.isNil(newValue)) return

    onChangeGenericData(data, setData)(focus)(newValue)
  }, [focus, data, setData])

  const updateFocusState = useCallback(async (text: string, box: BoxValue) => {
    setLoading(true)
    await mapFocusData(text)
    mapFocusBoxes(box)
    setLoading(false)
  }, [mapFocusData, mapFocusBoxes])

  const boxesList = useMemo(() => {
    const box = _.get(focus, boxes)
    if(box?.fileIndex !== idx) return []

    return [box]
  }, [focus, boxes, idx])

  return {
    loading,
    focus: {
      value: focus,
      set: setFocus,
      updateState: updateFocusState
    },
    boxes: {
      state: boxes,
      current: boxesList
    },
    data: {
      state: data,
      set: setData
    }
  }
}
