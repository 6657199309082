import _ from 'lodash/fp';
import React from 'react';
import { useExpedienteState } from '../../hooks/expediente';
import { dateToString } from '../../utils/date';
import Card from '../Card';
import ScrollableList from '../ScrollableList';
import SummaryBox from './SummaryBox';
import SummaryField from './SummaryField';
import SummaryFileList from './SummaryFileList';
import SummaryHeader from './SummaryHeader';
import SummarySubHeader from './SummarySubHeader';
import { SummaryContainersTable } from './SummaryTable';

type Props = {
  onClickFile?: (file: File) => void
}

const SummaryExpediente: React.FunctionComponent<Props> = ({ onClickFile }) => {
  const mbl = useExpedienteState(state => state.state.mbl.data)
  const hasMbl = useExpedienteState(state => state.hasMBL)
  const containers = useExpedienteState(state => state.state.containers.data)
  const files = useExpedienteState(state => state.files)
  return (
    <div className="tw-h-full tw-flex tw-flex-col">
      <SummaryHeader text='Expediente' />
      <ScrollableList>
        <div className="tw-p-4 tw-flex-1">
          <SummaryBox>
            <SummarySubHeader text="Ficheros" />
            <SummaryFileList files={files} onClick={onClickFile} />
          </SummaryBox>
          <SummaryBox>
            <SummarySubHeader text="MBL" />
            {hasMbl ? (
              <>
                <SummaryField label={`Nº de MBL`} value={_.toString(mbl.bl_num)} />
                <SummaryField label={`Naviera`} value={_.toString(mbl.carrier?.label)} />
                <SummaryField label={`Shipper`} value={_.toString(mbl.shipper?.label)} />
                <SummaryField label={`Puerto de carga`} value={_.toString(mbl.port_loading?.label)} />
                <SummaryField label={`Puerto de descarga`} value={_.toString(mbl.port_discharge?.label)} />
                <SummaryField label={`Fecha de emisión`} value={dateToString(mbl.onboard)} />
                <SummaryField label={`BL original o express`} value={_.toString(mbl.seawaybill?.label)} />
                <SummaryField label={`Service contract number`} value={_.toString(mbl.service_num)} />
                <SummaryField label={`Prepaid o collect`} value={_.toString(mbl.freight)} />
                <SummaryField label={`Barco`} value={_.toString(mbl.vessel)} />
                <SummaryField label={`Viaje`} value={_.toString(mbl.voyage_no)} />
                <SummaryField label={`Notas file`} value={_.toString(mbl.file_notes)} />
                <SummaryField label={`Número de contenedores`} value={_.toString(mbl.num_containers)} />
              </>
            ) : (
              <>
                <SummaryField label={`Naviera`} value={_.toString(mbl.carrier?.label)} />
                <SummaryField label={`Shipper`} value={_.toString(mbl.shipper?.label)} />
                <SummaryField label={`Puerto de carga`} value={_.toString(mbl.port_loading?.label)} />
                <SummaryField label={`Puerto de descarga`} value={_.toString(mbl.port_discharge?.label)} />
                <SummaryField label={`Tipo de contenedor`} value={`${_.toString(mbl.cont_type?.label)} ${mbl.non_operational ? "(No conectado)" : ""}`} />
              </>
            )}
          </SummaryBox>
          {hasMbl && (
            <SummaryBox>
              <SummarySubHeader text="Contenedores" />
              <Card>
                <SummaryContainersTable containers={containers}/>
              </Card>
            </SummaryBox>
          )}
        </div>
      </ScrollableList >
    </div>
  )
}


export default SummaryExpediente
