import _ from 'lodash/fp';

import errorList from './constants/errors';
import { showErrorAlert } from './alert';

export const showError = (error: unknown): void => {
  const currentError = error as Error;
  const errorTxt = _.get([currentError.message], errorList);
  if (_.isEmpty(errorTxt)) {
    showErrorAlert(`${errorList.UNKNOWN_ERROR}: ${currentError.message}`);
  } else {
    showErrorAlert(errorTxt);
  }
};

export const handleError = <T>(callback: (...args: T[]) => Promise<void> | void) => (
  async (...args: T[]) => {
    try {
      await callback(...args)
    } catch (err) {
      console.error(err)
      showError(err)
    }
  }
)
