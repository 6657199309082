import _ from "lodash/fp"
import { useArray } from "./utils"

export const useUploadFiles = () => {
  const [files, { set: setFiles, remove }] = useArray<File>()

  const handleSetFiles = (uploadedFiles: File[]) => (
    setFiles(current => _.pipe(_.concat(current), _.uniqBy('name'))(uploadedFiles))
  )

  return [files, handleSetFiles, remove] as [typeof files, typeof handleSetFiles, typeof remove] 
}
