import React from 'react';

import Images from '../images';

type Props = {
  className?: string
}

const MainLogo: React.FunctionComponent<Props> = ({ className = '' }) => (
  <img src={Images.Logo} className={className} alt="CLOPY BL logo" />
);

export default MainLogo;
