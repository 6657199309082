import React from 'react'
import { Intent, Spinner, SpinnerSize } from '@blueprintjs/core'

type Props = {
  text?: string
}

const Loading: React.FunctionComponent<Props> = ({text = 'Cargando...'}) => {
  return (
    <div className="tw-w-full tw-h-full tw-flex tw-flex-col tw-space-y-4 tw-items-center tw-justify-center tw-bg-white">
      <Spinner size={SpinnerSize.STANDARD} intent={Intent.PRIMARY}/>
      <div className="tw-font-bold tw-text-lg tw-text-blue-600">{text}</div>
    </div>
  )
}


export default Loading
