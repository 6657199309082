import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { LoginData, SelectValue } from "../types";
import { getTenantsAsSelectValueList } from "../utils/tenant";
import { useEffectOnce } from "./utils";

export const useLoginForm = (onSubmit: (data: LoginData) => Promise<void>) => {
  const { handleSubmit, control, formState: { errors } } = useForm<LoginData>();
  const [loading, setLoading] = useState(false)
  const [tenants, setTenants] = useState<SelectValue[]>([])

  const loadingWrapper = async (cb: () => Promise<void>) => {
    setLoading(true)
    await cb()
    setLoading(false)
  }
  const submit = useCallback(async (data: LoginData) => {
    await loadingWrapper(() => onSubmit(data))
  }, [onSubmit])

  useEffectOnce(() => {
    (async () => {
      await loadingWrapper(async () => setTenants(await getTenantsAsSelectValueList()))
    })()
  })

  return {
    submit: handleSubmit(submit),
    control,
    errors,
    loading,
    state: {
      tenants
    }
  }
}
