import _ from 'lodash/fp'
import { StringValue, ValueOf } from '../types'
import { extensions, icons, types } from './constants/file'
import { Buffer } from 'buffer'

const getIcon = (k: StringValue) => {
  if (_.isNil(k)) {
    return
  }
  return _.get(k, icons) as ValueOf<typeof icons>
}

const getType = (k: StringValue) => {
  if (_.isNil(k)) {
    return
  }
  return _.get(k, types) as ValueOf<typeof types>
}

const getExtension = (k: StringValue) => {
  if (_.isNil(k)) {
    return
  }
  return _.get(k, extensions) as ValueOf<typeof extensions>
}

export const typeToIcon = (t: string) => (
  _.pipe(_.findKey(_.isEqual(t)), getIcon)(types)
)

export const extensionToIcon = (e: string) => (
  _.pipe(_.findKey(_.isEqual(e)), getIcon)(extensions)
)

export const extensionToType = (e: string) => (
  _.pipe(_.findKey(_.isEqual(e)), getType)(extensions)
)

export const typeToExtension = (t: string) => (
  _.pipe(_.findKey(_.isEqual(t)), getExtension)(types)
)

export const getFileExtension = (file: File) => (
  _.pipe(
    _.split('.'),
    _.last
  )(file.name) as string
)

export const getAllAcceptedExtensionsAsList = () => _.values(extensions)
export const getAllTypesByExtensions = (exts: string[]) => _.map(extensionToType, exts)
export const getAllAcceptedMap = (exts: string[]) => (
  _.reduce((acc, curr) => _.set(curr, [_.toString(typeToExtension(curr))], acc), {}, getAllTypesByExtensions(exts) as string[])
)

export const pdfBase64ToFile = (pdf: string, name: string) => {
  const buffer = Buffer.from(pdf, 'base64')

  return new File([buffer], `${name}${extensions.PDF}`, { type: types.PDF });
}

export const fileToBase64 = (file: File): Promise<string> => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = () => resolve(_.toString(reader.result));
  reader.readAsDataURL(file);
  reader.onerror = error => reject(error);
});

export const download = (file: File) => {
  const url = window.URL.createObjectURL(file);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = file.name;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove()
}
