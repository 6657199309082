import React from 'react';
import _ from 'lodash/fp';
import AsyncSelect from 'react-select/async';
import { Intent, Spinner, SpinnerSize } from '@blueprintjs/core';
import { DEFAULT_PROPS, DEFAULT_MIN_CHARS, Props as SelectProps, customStyles } from './SelectInput';
import { SelectValue } from '../../types';

type Props = Omit<SelectProps, 'options'> & {
  debounce?: number
  onQueryOptions: (query: string) => Promise<SelectValue[]>;
};

const AsyncSelectInput: React.FunctionComponent<Props> = ({
  value,
  debounce = 300,
  minChars = DEFAULT_MIN_CHARS,
  onChange,
  onQueryOptions,
  onFocusInput = _.noop,
}: Props) => {

  const handleLoadingOptions = (query: string, fn: Function) => {
    if (query.trim().length < minChars) return fn([]);

    onQueryOptions(query).then(result => fn(result));
  }

  const loadingMessage = () => (
    <div className="tw-flex tw-flex-row tw-items-center tw-space-x-2">
      <Spinner size={SpinnerSize.SMALL} intent={Intent.PRIMARY} />
      <span>Buscando...</span>
    </div>
  )

  return (
    <AsyncSelect
      {...DEFAULT_PROPS}
      styles={customStyles}
      loadOptions={_.debounce(debounce, handleLoadingOptions)}
      value={value}
      onChange={onChange}
      placeholder={minChars > 0 ? `Buscar (min ${minChars} caracteres)` : 'Buscar...'}
      getOptionValue={(option) => _.toString(option?.id)}
      getOptionLabel={(option) => _.toString(option?.label)}
      loadingMessage={loadingMessage}
      onFocus={onFocusInput}
    />
  );
};

export default AsyncSelectInput;
